import React, { createContext, useContext, useState, useCallback } from 'react'

// Create the context
const ItemTotalsContext = createContext()

// Hook to use the context
export const useItemTotals = () => useContext(ItemTotalsContext)

// Provider component
export const ItemTotalsProvider = ({ children }) => {
    const [itemTotals, setItemTotals] = useState({})

    // Allow both direct updates and callback-based updates
    const updateItemTotals = useCallback((updater) => {
        setItemTotals((prev) => {
            if (typeof updater === 'function') {
                return updater(prev)
            }
            return { ...prev, ...updater }
        })
    }, [])

    return (
        <ItemTotalsContext.Provider value={{ itemTotals, updateItemTotals }}>
            {children}
        </ItemTotalsContext.Provider>
    )
}
