import React, { Fragment } from 'react'

import TourSearchLink from './TourSearchLink.jsx'
import TourShowInfoLink from './TourShowInfoLink.jsx'

const TourStatsSongList = ({
    currentData, updatePageType, baseSearchParams, who, cwho, global,
    setError, setSetlistText, userInfo, setResultsText,
}) => {
    console.info("Rendering TourStatsSongList, global is", global)

    const verb = global ? 'played' : 'seen'

    let songs = []
    let albums = []
    let lastAlbum = null
    /*
     * We make a copy of either "all songs" (in the TourStats case) or
     * "seen songs" (in the TourReport) case. As we go through the album
     * songs we will remove the songs from there, so when we're done we will
     * have a list of songs that have been played/seen that are not from
     * albums, making up the "other songs" list.
     */
    let songsSeenCopy = global ? {...currentData.songs} : {...currentData.songsSeen}
    /*
     * Medley map is a map of songs that have been played in a medley to
     * how many times they've been played in medleys. We will delete things
     * out of it as we go so we don't double-count a song played in different
     * medleys
     */
    let medleyMapCopy = {...currentData.medleyMapCopy}

    if (!baseSearchParams) {
        baseSearchParams = {}
    }


    const renderJoinedFragsWithSlash = (frags) => {
        const joinedFragments = React.Children.toArray(frags).flatMap((fragment, index, arr) => 
            index < arr.length - 1 ? [fragment, " / "] : [fragment]
        )
        return <>{joinedFragments}</>
    }

    const getSeenCounts = (lc_song, songs, medleyMap) => {
        let [realCount, medleyCount] = [0, 0]
        if (lc_song in songs) {
            realCount = songs[lc_song]['count']
        }
        if (lc_song in medleyMap) {
            medleyCount = medleyMap[lc_song]['count']
        }
        return [realCount, medleyCount]
    }

    /*
     * albumSongs is an array because order matters
     * but since we have to iterate through it, we'll
     * create a hash of the names for a quick lookup of
     * what is/isn't so we can use it when printing out
     * songsNotSeen
     */
    let albumSongLookup = {}
    let currentAlbumNumSongs = 0
    let currentAlbumSeenSongs = 0
    currentData.albumSongs.forEach(song => {
        if (!lastAlbum) {
            lastAlbum = song.album
        }
        albumSongLookup[song.song.toLowerCase()] = null
        if (song.album !== lastAlbum) {
            albums.push(<div key={lastAlbum}>
                <strong>{lastAlbum} ({currentAlbumSeenSongs}/{currentAlbumNumSongs})</strong>
                <ul>{songs}</ul>
            </div>)
            songs = []
            lastAlbum = song.album
            currentAlbumNumSongs = 0
            currentAlbumSeenSongs = 0
        }
        currentAlbumNumSongs++

        const subsongs = song.song.split('/')
        let numSubSongs = 0
        let seenSubSongs = 0
        let countStrings = []
        let songStrings = []
        subsongs.forEach(subsong => {
            numSubSongs++
            const lc_song = subsong.toLowerCase()
            const [realCount, medleyCount] = getSeenCounts(lc_song, songsSeenCopy, medleyMapCopy)
            const totalCount = realCount + medleyCount
            if (totalCount === 0) {
                songStrings.push(<span className="tour_notseen">{subsong}</span>)
                return true
            }
            seenSubSongs++
            delete songsSeenCopy[lc_song]
            delete medleyMapCopy[lc_song]
            let searchParams = {...baseSearchParams}
            searchParams['setlist'] = subsong
            let m = undefined
            if (medleyCount > 0) {
                m = ` (${medleyCount} from medleys)`
            }
            songStrings.push(<>{subsong}</>)
            let p = undefined
            if (global) {
                const songData = currentData.songs[lc_song]
                if (lc_song in currentData.songs) {
                    p = <>, premiered <TourShowInfoLink
                        id={songData.premiere_show_id}
                        {...{
                            updatePageType, setError, setSetlistText, userInfo,
                            setResultsText,
                        }}
                    >{songData.premiere}</TourShowInfoLink>
                    </>
                }
            }

            countStrings.push(
                <Fragment key={subsong}>{verb} <TourSearchLink
                    {...{searchParams, updatePageType}}
                >{totalCount} time{totalCount > 1 && 's'}</TourSearchLink>{m}{p}
                </Fragment>
            )
        })
        currentAlbumSeenSongs += (seenSubSongs / numSubSongs)
        songs.push(<li key={song.song}>
            {renderJoinedFragsWithSlash(songStrings)} &nbsp; &nbsp;
            {countStrings.length > 0 &&
                <span className="smallnote tour_notseen">[{renderJoinedFragsWithSlash(countStrings)}]</span>}
        </li>)
    })

    if (songs.length > 0) {
        albums.push(<div key={lastAlbum}>
            <strong>{lastAlbum} ({currentAlbumSeenSongs}/{currentAlbumNumSongs})</strong>
            <ul>{songs}</ul>
        </div>)
    }

    let otherSongs = []
    Object.keys(songsSeenCopy).sort().forEach(song => {
        const info = songsSeenCopy[song]
        const lc_song = song.toLowerCase()
        /*
         * don't print the counts for medleys that are simply a few songs
         * (i.e. 'Master Of Puppets/Welcome Home (Sanitarium)' becuase
         * those have already been pulled out as each song and printed
         * with counts. We'll include a "Medleys" section at the end
         * to show how many times they were played as a medley. We will,
         * however include stuff like "mtvICON medley" here, since the
         * components won't have been counted. 'medleys' contains the former
         * so that's what this is for.
         */
        if (info['name'] in currentData.medleys) {
            return
        }
        const [realCount, medleyCount] = getSeenCounts(
            lc_song, songsSeenCopy, medleyMapCopy
        )
        const totalCount = realCount + medleyCount

        let p = undefined
        if (global) {
            const songData = currentData.songs[lc_song]
            p = <>, premiered <TourShowInfoLink
                id={songData.premiere_show_id} {...{
                        updatePageType, setError, setSetlistText, userInfo,
                        setResultsText,
                }}>{songData.premiere}</TourShowInfoLink>
            </>
        }
        let searchParams = {...baseSearchParams}
        searchParams['setlist'] = info.name
        otherSongs.push(<li key={song}>
             {info.name} &nbsp; &nbsp;
             <span className="smallnote tour_songstats">[{verb}&nbsp;
                 <TourSearchLink
                    {...{searchParams, updatePageType}}
                 >{totalCount} time{totalCount > 1 && 's'}</TourSearchLink>{p}]</span>
        </li>)
        /*
         * Since we've accounted for this song's medley counts here,
         * remove it from the medleyMapCopy so we don't count it again.
         *
         * This SHOULDN'T actually change things, I don't think... as 'other
         * songs' shouldn't have the same song repeated, but we
         * did this in the old backend, so we'll just keep it.
         */
        if (lc_song in medleyMapCopy) {
            delete medleyMapCopy[lc_song]
        }
    })

    let medleys = []
    Object.keys(currentData.medleys).sort().forEach(song => {
        let count = currentData.medleys[song]
        let searchParams = {...baseSearchParams}
        searchParams['setlist'] = song
        medleys.push(<li key={song}>
            {song} &nbsp; &nbsp;
            <span className="smallnote tour_songstats">[{verb}&nbsp;
                <TourSearchLink
                    {...{searchParams, updatePageType}}
                >{count} time{count > 1 && 's'}</TourSearchLink>]</span>
        </li>)
    })

    let unseenSongs = []
    if (!global) {
        currentData.songsNotSeen.sort().every(song => {
            const lc_song = song.toLowerCase()
            if (lc_song in albumSongLookup ||
                lc_song in currentData.medleys) {
                return true
            }
            unseenSongs.push(<li key={song}>
                <span className="tour_notseen">{song}</span>
            </li>)
            return true
        })
    }

    const numSongs = global ? undefined : Object.keys(currentData.songsSeen).length
    const otherSongsLength = otherSongs.length
    const medleysLength = medleys.length
    const unseenLength = unseenSongs.length

    const tipNote = global
        ? 'Click a number to see all places a song has been played'
        : `Click a number to see all shows where ${who} seen each song`

    return <>
        <h3 id="songs">Songs{global || <> ({numSongs})</>}</h3>
        <p className="smallnote tour_notseen">{tipNote}</p>
        {albums}
        {otherSongsLength > 0 && <>
            <strong>Other Songs ({otherSongsLength})</strong>
            <ul>{otherSongs}</ul>
        </>}
        {medleysLength > 0 && <>
            <strong>Medleys ({medleysLength})</strong>
            {global && <p className="smallnote tour_notseen">Songs in the following medleys are already accounted for above and are listed here only for reference.</p>}
            <ul>{medleys}</ul>
        </>}
        {global || <>
            <strong>Other Songs Not Seen ({unseenLength})</strong>
            <ul>{unseenSongs}</ul>
        </>}
    </>
}

export default TourStatsSongList
