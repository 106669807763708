import React, { useCallback } from 'react'

import * as c from './tourConstants.jsx'

import ShowInfo from './ShowInfo.jsx'

const baseurl = `/app/tour?pagetype=${c.PageType.SHOWINFO}`

const TourShowInfoLink = ({
    userInfo, updatePageType, setError, id, setSetlistText, setResultsText,
    ...props
}) => {
    const handleDateInfoClick = useCallback((e, id) => {
        e.preventDefault()
        setSetlistText(<ShowInfo showId={id} {...{
            userInfo, setError, updatePageType, setSetlistText,
            setResultsText,
        }} />)
    }, [setSetlistText, setError, updatePageType, userInfo, setResultsText])

    return <a
        href={`${baseurl}&data=${id}`}
        onClick={e => handleDateInfoClick(e, id)}
    >{props.children}</a>
}

export default TourShowInfoLink
