import React, { Fragment } from 'react'

/*
* Given a list of {items} generates JSX like:
*     [ one | two | three | .. n ]
*
* Properties:
*     items - items to generate
*     edgeChar - defaults to ['[', ']']
*     seperator - defaults to |
*     itemToJsX - function to convert item to display
*         - takes item and index
*/
const PipeLinks = ({seperator, edgeChar, items, itemToJsx}) => {
    console.info("Rendering PipeLinks")

    let sep = '|'
    if (seperator) {
        sep = seperator
    }

    let front = <Fragment>[&nbsp;</Fragment>
    let back = <Fragment>&nbsp;]</Fragment>

    if (edgeChar) {
        front = edgeChar[0]
        back = edgeChar[1]
    }

    let links = items.map((item, idx) => {
        return <Fragment key={item}>{idx > 0 && ` ${sep} `}{itemToJsx(item, idx)}</Fragment>
    })

    return <Fragment>{front}{links}{back}</Fragment>
}

export default PipeLinks
