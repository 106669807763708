import React, { Fragment } from 'react'

import * as utils from './tourUtils.jsx'
import TourDateIcon from './TourDateIcon.jsx'
import TourShowInfoLink from './TourShowInfoLink.jsx'
import TourSearchLink from './TourSearchLink.jsx'

const TourTableRow = ({
    userInfo, dateInfo, seen, handleSeenChange, pageType, updatePageType,
    skipLinks, onlyFor, counter, setSetlistText, setError, setResultsText,
}) => {
    console.debug('Rendering TourTableRow');

    const baseSearchParams = onlyFor
        ? (onlyFor === 'SELF' ? {onlymyshows: true} : {onlyusershows_id: onlyFor})
        : {}

    const localeLink = (dateInfo, skipLinks) => {
        const locale = utils.locationString(dateInfo)
        if (skipLinks) {
            return locale
        }

        let searchParams = {...baseSearchParams}
        searchParams['country'] = dateInfo.country
        searchParams['city'] = dateInfo.city

        if (dateInfo.state) {
            searchParams['state'] = dateInfo.state
        }
        return <TourSearchLink
            {...{searchParams, updatePageType}}>{locale}</TourSearchLink>
    }

    const venueLink = (venue, skipLinks) => {
        if (skipLinks) {
            return venue
        }
        let searchParams = {...baseSearchParams}
        searchParams['venue'] = venue
        return <TourSearchLink
            {...{searchParams, updatePageType}}>{venue}</TourSearchLink>
    }

    const {
        id,
        fdate,
        venue,
        comments,
        s,
        r,
        canceled,
        media_event,
    } = dateInfo

    let seenfield, ifield

    if (!skipLinks) {
        const count = (canceled !== 'y' && media_event !== 'y') ?
            <> #{counter}</> :
            undefined
        if (userInfo['logged_in'] === 1 && (!onlyFor || onlyFor === 'SELF')) {
            seenfield = <>
                <input
                    type="checkbox" name="id" value={id}
                    checked={id in seen}
                    onChange={e => handleSeenChange(e)}
                />
                {onlyFor && count}
            </>
        }

        ifield = [
            <Fragment key='info'>
                <TourShowInfoLink {...{
                    setError, updatePageType, setSetlistText, id, userInfo,
                    setResultsText,
                }}
                >Info</TourShowInfoLink>
                &nbsp;&nbsp;&nbsp;
            </Fragment>
        ]
        if (s > 0) {
            ifield.push(<Fragment key='setlist'>
                <TourDateIcon type='setlist' />
                &nbsp;&nbsp;&nbsp;
            </Fragment>)
        }
        if (r > 0) {
            ifield.push(<Fragment key='review'>
                <TourDateIcon type='review' />
            </Fragment>)
        }
    }
    let classes = []
    if (canceled === 'y') {
        classes.push('canceled')
    }
    const today = new Date()
    if (onlyFor && (media_event === 'y' || new Date(fdate) > today)) {
        classes.push('future')
    }

    let cn = classes.length === 0 ? undefined : classes.join(' ')
    return (<tr className={cn}>
        {utils.includeSeenCol({skipLinks, userInfo, pageType}) &&
            <td>{seenfield}</td>}
        <td>{fdate}</td>
        <td>{localeLink(dateInfo, skipLinks)}</td>
        <td>{venueLink(venue, skipLinks)}</td>
        <td>{comments || '-'}</td>
        {skipLinks ? undefined : <td>{ifield}</td>}
    </tr>)
}

export default TourTableRow
