import React, { Fragment } from 'react'

import * as utils from './collectionUtils.jsx'
import PipeLinks from '../PipeLinks.jsx'

import './ItemColor.css'

const AmountLinks = ({amounts, typeDisplay, handleSetDisplay}) => {
    console.info("Rendering AmountLinks")

    // callback for PipeLinks
    function display(amount, _index) {
        return (
            <Fragment>
                <input tabIndex="1" className="display_only" type="checkbox" name={amount} onChange={handleSetDisplay} checked={typeDisplay[amount]} />&nbsp;
                <span className={amount}>{utils.varietyToHeading(amount)}</span>
            </Fragment>
        )
    }
    // reverse() reverse the actual array, so make a copy first
    let ramounts = Array.from(amounts)

    ramounts.reverse()
    return (
        <p className="center amount_links">
            <strong>Display</strong>:&nbsp;
            <PipeLinks
                itemToJsx={display}
                items={ramounts}
                edgeChar={['', '']}
            />
        </p>
    )
}

export default AmountLinks
