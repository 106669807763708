import React, { useEffect, useCallback, useState, Fragment } from 'react'
import Image from 'react-image-enlarger'
import { Link } from 'react-router-dom'

import Loading from '../Loading.jsx'
import Member from '../Member.jsx'

import * as utils from './tourUtils.jsx'
import * as c from './tourConstants.jsx'
import ShowReviewSubmit from './ShowReviewSubmit.jsx'

/*
 * This component is a bit complicated becasue it is both used in
 * the Tour section but also in the Profile section. Since the same
 * data is acquired and displayed in a similar way, it made sense to
 * use the same component, but there's enough subtle differences that it gets
 * a bit confusing.
 *
 * It's worth noting a few things:
 *   - userId is set when being called from Profile, and us set to _SELF_
 *     when we're looking at our own profile
 *   - showId is set when being called from Tour
 *   - printHeader is set when being called from Profile
 */
const ShowReviews = ({
    showId, setError, reviewData, setReviewData, reviewId, setReviewId, userInfo,
    setResultsText, userId, updatePageType, printHeader
}) => {
    const [zoomed, setZoomed] = useState()

    console.info(`Rendering ShowReviews, reviewId: ${reviewId}, userId: ${userId}, showId: ${showId}`)

    const getReviews = useCallback(() => {
        console.debug("TourReviews: getReviews()")
        let params = {}
        if (showId) {
            params['show_id'] = showId
        } else if (userId) {
            params['user_id'] = userId
        }

        fetch('/cgi-bin/tour_reviews_values.pl', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(params),
        })
            .then(response => response.json())
            .then(i => {
                if (i['error']) {
                    setError(i['error_message'])
                    return
                }
                setReviewData({
                    'reviews': i['results']['reviews'],
                    'otherMemberDisplayName':
                        i['results']['other_member_displayname'],
                    'otherMemberNumBadges':
                        i['results']['other_member_num_badges'],
                })
            })
    }, [showId, userId, setReviewData, setError])

    useEffect(() => {
        if (!showId && !userId) {
            console.debug("TourReviews: Skipping getReviews - no showID")
            return
        }
        if (reviewData) {
            console.debug("TourReviews: Skipping getReviews - have data")
            return
        }
        getReviews()
    }, [showId, userId, reviewData, getReviews])

    if (!reviewData) {
        return <Loading />
    }

    const genReviewOwnerLink = (review) => {
        if (review.member_id) {
            return <><Member badges={review.member_num_badges} memberInfo={{id: review.member_id, displayName: review.handle}} />&apos;s review:</>
        } else {
            return <><strong>Unclaimed</strong> review from {review.legacy_email}.<br/><span className="smallnote">If this is your review, please sign up for an account with that email and you will be given the option to claim it on your profile</span>.</>
        }
    }

    if (reviewId === -1) {
        return <>
            <p><button className="square-button" onClick={() => setReviewId(null)}>Back to reviews</button></p>
            <ShowReviewSubmit reviews={reviewData.reviews} {...{
                userInfo, showId, getReviews, setResultsText,
            }} />
        </>
    }

    const numReviews = Object.keys(reviewData.reviews).length
    let body
    if (reviewId) {
        const review = reviewData.reviews[reviewId]
        body = <>
            <button className='square-button' onClick={() => setReviewId(null)} >Back to list</button><br/><br/>
            {genReviewOwnerLink(review)}
            {review.link ?
                <blockquote>
                    This show is reviewed as part of Phil&apos;s <a href={`/static_tour_reviews/${review.link}`}>larger review</a> of the tour.
                </blockquote> :
                <blockquote>
                    {review.body.split('\n').map((line, idx) => (
                        <Fragment key={idx}>
                            {line}
                            <br />
                        </Fragment>
                    ))}
                    <br/>
                    {review.images.length > 0 && <><strong>Photos</strong><br/></>}
                    {review.images.map((id, idx) => {
                        const text = `user-submitted shot ${idx+1} from show`
                        return <Fragment key={idx}><Image
                            border="0"
                            alt={text}
                            title={text}
                            src={`/cgi-bin/tour_review_image.pl?id=${id}&thumb=1`}
                            enlargedSrc={`/cgi-bin/tour_review_image.pl?id=${id}`}
                            zoomed={zoomed}
                            onClick={() => setZoomed(true)}
                            onRequestClose={() => setZoomed(false)}
                        />&nbsp;</Fragment>
                    })}
                </blockquote>}
        </>
    } else if (numReviews === 0 && userId) {
        body = <em>This user has no tour reviews...</em>
    } else {
        const items = Object.keys(reviewData.reviews).map(rid => {
            const review = reviewData.reviews[rid]
            let body
            if (showId) {
                body = review.member_id ? review.handle : review.legacy_handle
                return <li key={rid}><button className='link-like-button' onClick={e => {setReviewId(rid)}}>{body}</button></li>
            } else {
                body = `${review.date} - ${utils.locationString(review)}`
                return <li key={rid}><Link to={`/app/tour?pagetype=${c.PageType.SHOWINFO}&data=${review.show_id}`}>{body}</Link></li>
            }
        })

        body = <><ul>{items}</ul></>
    }


    let header = null
    if (printHeader) {
        let intro
        if (userInfo['logged_in'] === 1 && userId === '_SELF_') {
            intro = <p>{userInfo['display_name']}, these are your tour reviews!</p>
        } else if (userId !== '_SELF_') {
            console.debug('PHIL', reviewData)
            intro = <p>These are <Member badges={reviewData.otherMemberNumBadges} memberInfo={{id: userId, displayName: reviewData.otherMemberDisplayName}} />&apos;s tour reviews.</p>
        }
        header = <>
            <h3>Tour Reviews ({numReviews})</h3>
            {intro}
        </>
    } else if (showId) {
        header= <p><button className="square-button" onClick={() => setReviewId(-1)}>Submit/Edit your review</button></p>
    }

    return <>
        {header}
        {body}
    </>
}

export default ShowReviews
