import React from 'react'
import './Loading.css'

const Loading = () => {
    return (
    <div className="loading_container">
        <img className="loading" src="/images/star_high_bg.jpg" alt="Loading" />
        <h3>Loading...</h3>
    </div>
    )
}

export default Loading
