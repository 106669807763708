import React, { useState } from 'react'

import ShowReviews from './ShowReviews'
/*
 * thin wrapper around ShowReview to provide reviews and reviewId
 * which normally the individual showInfo page would do
 */
const TourMemberReviews = ({
    userInfo, userId, setError, setResultsText, updatePageType, profileData,
}) => {
    const [reviewData, setReviewData] = useState(null)
    const [reviewId, setReviewId] = useState(null)

    if (!userId) {
        userId = '_SELF_'
    }
    return <ShowReviews printHeader={true}
        {...{userId, reviewData, setReviewData, reviewId, setReviewId, userInfo,
            setResultsText, updatePageType }} />
}

export default TourMemberReviews
