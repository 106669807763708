import { useRef, useCallback, useEffect, useState } from 'react'

import * as c from './collectionConstants'

function useCollectionData({
    userInfo,
    allItems,
    memberItems,
    multiPageType,
    setLoaded,
    setError,
    setAllItems,
    setMemberItems,
    setSortedIds,
    setItemTotals,
    setAmounts,
    setRareness,
    setItemSetCounts,
    setItemSetImages,
    setOtherInfo,
    setVarieties,
    setPrefs,
}) {
    const allItemsRef = useRef({})
    const memberItemsRef = useRef({})

    const [currentMemberKey, setCurrentMemberKey] = useState(null)

    // Keep refs updated with the latest state
    useEffect(() => {
        allItemsRef.current = allItems
    }, [allItems])

    useEffect(() => {
        memberItemsRef.current = memberItems
    }, [memberItems])

    const fetchItems = useCallback(
        async ({ itemType, view, pageType, otherEmail, otherId }) => {
            console.debug('fetchItems: entered')
            setLoaded(false)

            if (!userInfo) return

            const newOtherMemberKey = otherEmail || otherId
            const newKey = view === c.View.SELF ? c.View.SELF : newOtherMemberKey

            // Avoid fetching if data already exists
            if (
                itemType in allItemsRef.current &&
                pageType in allItemsRef.current[itemType] &&
                (
                    userInfo.logged_in === 0 ||
                    (
                        itemType in memberItemsRef.current &&
                        newKey in memberItemsRef.current[itemType]
                    )
                )
            ) {
                console.debug("fetchItems: Already have data, skipping fetchItems")
                setLoaded(true)
                return
            }

            try {
                const formData = new URLSearchParams()
                formData.append('item_type', itemType)
                if (multiPageType) {
                    formData.append('type', pageType)
                }
                if (otherId) formData.append('id', otherId)
                if (otherEmail) formData.append('email', otherEmail)

                const response = await fetch(`/cgi-bin/collections_list_values.pl`, {
                    method: 'POST',
                    body: formData,
                })

                const data = await response.json()
                if (data.error) {
                    setError(data.error_message)
                    return
                }

                setCurrentMemberKey(newKey)
                if (view === c.View.OTHER || userInfo.logged_in) {
                    setMemberItems(prev => ({
                        ...prev,
                        [itemType]: {
                            ...(prev[itemType] || {}),
                            [newKey]: data.results.member_items,
                        },
                    }))
                }

                setAllItems(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: data.results.all_items,
                    },
                }))
                setSortedIds(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: data.results.sorted_ids,
                    },
                }))
                setItemTotals(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: {
                            ...(prev[itemType]?.[pageType] || {}),
                            [newKey]: data.results.totals,
                        },
                    },
                }))
                setAmounts(data.results.amounts)
                setRareness(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: data.results.rareness,
                    },
                }))
                setItemSetCounts(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: data.results.set_counts,
                    },
                }))
                setItemSetImages(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: data.results.set_images,
                    },
                }))
                setVarieties(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [pageType]: data.results.varieties,
                    },
                }))

                const prefs = { ...data.results.prefs }
                for (const key in prefs) {
                    if (key.includes('include_')) {
                        prefs[key] = prefs[key] === 'yes'
                    }
                }

                setPrefs(prev => ({
                    ...prev,
                    [itemType]: {
                        ...(prev[itemType] || {}),
                        [newKey]: prefs,
                    },
                }))

                if (view === c.View.OTHER) {
                    setOtherInfo({
                        displayName: data.results.other_member.display_name,
                        id: data.results.other_member.id,
                        numBadges: data.results.other_member.num_badges,
                    })
                }
                setLoaded(true)
            } catch (err) {
                console.error(`Failed to fetch ${itemType}s`, err)
                setError('Failed to load data.')
            }
        },
        [
            userInfo,
            multiPageType,
            setLoaded,
            setError,
            setAllItems,
            setMemberItems,
            setSortedIds,
            setItemTotals,
            setAmounts,
            setRareness,
            setOtherInfo,
            setItemSetCounts,
            setItemSetImages,
            setVarieties,
            setPrefs,
        ]
    )

    return { fetchItems, currentMemberKey }
}

export default useCollectionData
