import * as c from './collectionConstants.jsx'

export const userListUrl = (itemType, id, pageType) => {
    if (!pageType) {
        pageType = c.PageType.OFFICIAL
    }
    let idArg = ""
    if (id) {
        idArg = `&id=${id}`
    }
    return `/app/${itemType}s?pagetype=${pageType}${idArg}`
}

export const userTradeUrl = (itemType, id) => {
    return userListUrl(itemType, id, c.PageType.TRADE_SUGGESTIONS)
}

export const varietyToPref = (variety, itemType) => {
    return `${itemType}s_include_${variety.replace(' ', '_')}`
}

// translate picks_include_foo => foo
export const prefToDisplay = (itemType, pref) => {
    if (pref.includes('include')) {
        let variety
        const bits = pref.split('_')
        if (bits.length > 2) {
            // everything except the first two...
            variety = bits.splice(2).join(' ')
        } else {
            variety = bits[1]
        }
        return `Collect ${varietyToHeading(variety)} ${itemType.capitalize()}s`
    } else if (pref === `${itemType}s_num_desired`) {
        return `Number of each ${itemType} desired`
    } else if (pref.startsWith(`${itemType}s_`)) {
        return pref.slice(itemType.length + 2).capitalize()
    }
    return pref.capitalize()
}

// Given a variety, gives a string capitalized for a heading
export const varietyToHeading = variety => {
    switch (variety) {
        case 'official event':
            return 'Official Event'
        case 'picktin':
            return 'Purchasable'
        case 'unofficial':
            return 'Fan-made'
        default:
            return variety.capitalize()
    }
}

// Given a pageType, gives a string captilized for a heading
export const pageTypeToHeading = type => {
    if (type === c.PageType.SEMIOFFICIAL) {
        return 'Semi-official'
    }
    return type.slice(1, -1).capitalize()
}

export const qtyToHaveAndNeed = (id, qty, desired) => {
    let display_qty = qty
    let need
    if (qty === 'Extra') {
        need = 0
    } else {
        need = desired - qty
        /*
         * we should never hit this code, since the backend should
         * not provide detailed qty numbers above the desired amount
         * and instead should just return 'Extra' - but in case there
         * is ever a bug in the backend, we check here just in case.
         */
        if (qty > desired) {
            console.error(`Backend sent unexpected data, handling, ${id} desired ${desired}, qty ${qty}`)
            need = 0
            display_qty = 'Extra'
        }
    }
    return [display_qty, need]
}

export const rarenessNumberToName = (num) => {
    switch (num) {
        case 1:
            return 'Very common'
        case 2:
            return 'Common'
        case 3:
            return 'Uncommon'
        case 4:
            return 'Rare'
        case 5:
            return 'Very rare'
        default:
            return 'unknown'
    }
}

export const formatMessage = (template, replacements) => {
    return template.replace(/{(\w+)}/g, (_, key) => replacements[key] || `{${key}}`);
}
