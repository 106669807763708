import React from 'react'

import { PageType } from './adminConstants'

import AdminLink from './AdminLink'
import AdminPending from './AdminPending'

const AdminIndex = ({updatePageType, setError}) => {
    return <>
<p>Choose your administrative task below!</p>

<AdminPending {...{setError}} />
<br/>

<strong>News</strong>
<ul>
<li><a href="/admin-bin/news_modify.pl?id=0">Add new News Story</a></li>
<li><a href="/admin-bin/news_modify.pl">Modify existing News Story</a></li>
</ul>

<strong>Tour</strong>
<ul>
<li><a href="/admin-bin/tour_modify.pl?type=show&id=0">Add new Tour Dates</a></li>
<li><a href="/admin-bin/tour_modify.pl?type=show">Modify existing Tour Dates</a></li>
<li><a href="/admin-bin/tour_modify.pl?type=venue&id=0">Add new Venues</a></li>
<li><a href="/admin-bin/tour_modify.pl?type=venue">Modify existing Venues</a></li>
    <li><AdminLink s={PageType.REVIEW_PHOTOS} {...{updatePageType}}>
       Review photos
    </AdminLink></li>
</ul>

<strong>Collections</strong>
<ul>
<li><a href="/admin-bin/collections_modify.pl?item_type=pick&id=0">Add new Picks</a></li>
<li><a href="/admin-bin/collections_modify.pl?item_type=pick">Modify existing Picks</a></li>
<li><a href="/admin-bin/collections_modify.pl?item_type=coin&id=0">Add new Coin</a></li>
<li><a href="/admin-bin/collections_modify.pl?item_type=coin">Modify existing Coin</a></li>
</ul>

<strong>Members</strong>
<ul>
<li><a href="/admin-bin/members_index.pl">Members</a></li>
</ul>

<strong>Calendar</strong>
<ul>
<li><a href="/admin-bin/calendar_modify.pl?id=0">Add new Calendar Event</a></li>
<li><a href="/admin-bin/calendar_modify.pl">Modify existing Calendar Event</a></li>
</ul>

<strong>Boards</strong>
<ul>
<li><a href="/admin-bin/boards_admin_entry.pl">Delete a post / Ban a user</a></li>
<li><a href="/admin-bin/boards_admin_entry.pl?action=whitelist">Whitelist IP</a></li>
</ul>

<strong>Discography</strong>
<ul>
<li><a href="/admin-bin/disco_modify.pl?id=0">Add new entry</a></li>
<li><a href="/admin-bin/disco_modify.pl">Modify existing entry</a></li>
</ul>

<strong>Metallica Mentions</strong>
<ul>
<li><a href="/admin-bin/metmentions_modify.pl?id=0">Add new entry</a></li>
<li><a href="/admin-bin/metmentions_modify.pl">Modify existing entry</a></li>
</ul>

<strong>Where Are They Now?</strong>
<ul>
<li><a href="/admin-bin/wherearetheynow_modify.pl?id=0">Add new entry</a></li>
<li><a href="/admin-bin/wherearetheynow_modify.pl">Modify existing entry</a></li>
</ul>

<strong>Monitoring</strong>
<ul>
    <li><a href="/awstats/awstats.pl?config=ipom.com">AWStats</a></li>
    <li><a href="http://grafana.ipom.com/">Grafana</a></li>
    <li><a href="https://uptime.betterstack.com/team/292491/monitors">Better Uptime</a> - Chef and Web</li>
    <li><a href="https://dashboard.uptimerobot.com/monitors">Uptime Robot</a> - Non-web ports</li>
    <li><a href="https://app.redsift.cloud/">Red Sift</a> - Certificate discover and monitoring</li>
</ul>

<p>
<strong>NOTE</strong>: Only authorized IPOM Staff are allowed in this area. Any unauthorized used is logged and will and will be prosecuted to the fullest extent of the law.
</p>
        </>
}
    
export default AdminIndex
