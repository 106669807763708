import React from 'react'

const BADGE_SIZE = 75

const ProfileBadges = ({userInfo, profileData}) => {
    const items = profileData.badges.map((item, idx) => (
        <img
            key={idx}
            src={`/images/badges/${item.name}.png`}
            title={item.description}
            alt={item.description}
            height={BADGE_SIZE}
            width={BADGE_SIZE}
            style={{
                border: '1px solid white',
                /*
                 * right - to spread them out
                 * bottom - for when there's enough badges to wrap around
                 */
                margin: '0px 5px 5px 0px'
            }}
        />
    ))
    return <>
        <h3 style={{marginTop: '0px', marginBottom: '7px'}}>Badges</h3>
        {items.length > 0 ? items : <em>None yet...</em>}
   </>
}

export default ProfileBadges
