import React from 'react'
import { Link } from 'react-router-dom'

import * as c from './profileConstants'

const ProfileLinks = ({userInfo, id, profileData}) => {
    console.info("Rendering ProfileLinks")

    // Links to our various data
    let items = profileData.links.sort((a, b) => (
        a.name.localeCompare(b.name)
    )).map((item, idx) => {
        const extra = (id === c.SELF && item.name !== 'Show Reviews') ?
            <> ({profileData.perms[item.name]})</> : null
        return <li key={idx}><Link to={item.url}>{item.name}</Link>{extra}</li>
    })
    if (id === c.SELF) {
        items.push(
            <li key='memberinfo'><a href="/cgi-bin/member_logged_in_form_gen.pl?form=update_info">Update Account Info</a></li>
        )
        items.push(<li key='logout'><a href="/cgi-bin/member_logout.pl">Logout</a></li>)
    }
    return <>
        <h3>Links</h3>
        {items.length === 0 ? <em>none</em> : <ul>{items}</ul>}
    </>
}

export default ProfileLinks
