import React from 'react'

import { userTradeUrl } from './collectionUtils.jsx'
import * as c from './collectionConstants.jsx'

// Component for the trade suggestions
const TradeSuggestionsLink = ({
    itemType, view, userInfo, otherId, otherInfo, switchToSuggest,
}) => {
    console.info("Rendering TradeSuggestions")
    if (view === c.View.OTHER && userInfo['logged_in']) {
        return (
            <p>
                <span className="headline">NEW!</span> See what <a href={userTradeUrl(itemType, otherId)} onClick={(e) => switchToSuggest(e, otherId)}><strong>you</strong> can trade with { otherInfo.displayName }</a>!
            </p>
        )
    }
    return null
}

export default TradeSuggestionsLink
