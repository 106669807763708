import React from 'react'
import { Link } from 'react-router-dom'

import './Member.css'

/*
 * If you pass in memberInfo, we'll linke to their profile,
 * otherwise the body will be whatever the children are
 */
const Member = ({badges, memberInfo, children}) => (
    <span className="member-container">
        {memberInfo ?
            <Link to={`/app/profile?id=${memberInfo.id}`}>
                {memberInfo.displayName}
            </Link>:
            children
        }
        {badges > 0 && (
            <span className="badge" title={`This user has earned ${badges} badges`}>
                {badges}
            </span>
        )}
    </span>
)

export default Member
