import React from 'react'

import TourSearchLink from './TourSearchLink.jsx'

const ShowSetlist = ({
    songs, songStats, md, updatePageType
}) => {
    const genSongInfoString = (song, stats, md) => {
        let prefix = (stats['premiere'] === md['fdate'])
            ? <span style={{color: 'white'}}>
                <strong>LIVE PREMIERE</strong>,&nbsp;
              </span>
            : <></>
        const searchParams = {
            setlist: song,
        }

        let count_link = <TourSearchLink
                {...{searchParams, updatePageType}}
            >{stats['count']} times</TourSearchLink>

        return <>[{prefix}played {count_link}]</>
    }

    return <>
        <ul>
            {songs.map((item, idx) => {
                const songname = item[0]
                const notes = item[1]
                const stats = songStats[songname.toLowerCase()]
                /* while special chars are HTML-encoded */
                let song = <>
                    <span dangerouslySetInnerHTML={{
                        __html: songname
                    }} />
                </>
                let comment = notes !== null ? <> ({notes})</> : undefined
                return (
                    <li key={idx}>{song}{comment} &nbsp; &nbsp;
                        <span className="smallnote tour_songstats">
                            {genSongInfoString(songname, stats, md)}
                        </span>
                    </li>
                )
            })}
        </ul>
    </>
}

export default ShowSetlist
