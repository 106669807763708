import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import debounce from 'lodash.debounce'

import ConditionalElement from '../ConditionalElement.jsx'
import ConditionalForm from '../ConditionalForm.jsx'
import Loading from '../Loading.jsx'
import Error from '../Error.jsx'
import useDirtyPageWarning from '../useDirtyPageWarning.jsx'

import * as c from './collectionConstants.jsx'
import Preamble from './Preamble.jsx'
import CollectionStats from './CollectionStats.jsx'
import VarietyLinks from './VarietyLinks.jsx'
import AmountLinks from './AmountLinks.jsx'
import Preferences from './Preferences.jsx'
import CollectionTables from './CollectionTables.jsx'
import CollectionSaveResults from './CollectionSaveResults'
import useCollectionData from './useCollectionData.jsx'
// this is pulled out into a context so that we can update
// it without refreshing the entire page
import { useItemTotals } from './ItemTotalsContext'

import './CollectionForm.css'

// The real body of the page that creates the form and the tables
const CollectionForm = ({
    itemType, view, updateView, otherId, setOtherId, otherEmail,
    userInfo, otherInfo, setOtherInfo, setResultsText,
    setStatusText, loaded, setLoaded, pageType, setPageType,
    setStartTour, multiPageType, updatePageType, dirty, setDirty,
    solicitHelp, switchToSuggest, switchToGuide,
}) => {
    const [allItems, setAllItems] = useState({})
    const [sortedIds, setSortedIds] = useState([])
    const [rareness, setRareness] = useState([])
    const [memberItems, setMemberItems] = useState({})
    const { updateItemTotals } = useItemTotals()
    const [amounts, setAmounts] = useState()
    const [prefs, setPrefs] = useState({})
    // sets are tins or other "groups" of items
    const [itemSetCounts, setItemSetCounts] = useState({})
    const [itemSetImages, setItemSetImages] = useState({})
    const [varieties, setVarieties] = useState({})
    const [error, setError] = useState(false)
    const [typeDisplay, setTypeDisplay] = useState(
        {missing: true, incomplete: true, complete: true, extra: true}
    )
    const [search, setSearch] = useState('')
    console.info(`Rendering CollectionForm, view is ${view}, mt is ${multiPageType}`)

    // sets useEffect for dirty listeners
    useDirtyPageWarning(dirty, setDirty)

    const { fetchItems, currentMemberKey } = useCollectionData({
        userInfo,
        allItems,
        memberItems,
        multiPageType,
        setLoaded,
        setAllItems,
        setMemberItems,
        setSortedIds,
        setItemTotals: (callback) => updateItemTotals(callback),
        setAmounts,
        setRareness,
        setItemSetCounts,
        setItemSetImages,
        setOtherInfo,
        setVarieties,
        setPrefs,
        setError,
    })

    useHotkeys(['shift+s', 'shift+k'], (e) => {
        e.preventDefault()
        switch (e.key) {
            case 'S':
                handleSubmit(e)
                break
            case 'K':
                document.getElementById('live_search').focus()
                break
            default:
                return
        }
        // allItems needs to exist when the HotKey is defined
        // otherwise handleSubmit is called with no access to it
    }, [allItems])

    const handleSubmit = useCallback(e => {
        e.preventDefault()

        const displayError = err => {
            setResultsText(
                `Failed to save your collection${err ? ` (${err})`: ''}. Please try again.`
            )
        }

        // Grab all inputs from our form except for the submit
        // buttons and live search and then make an object of name: val
        let x = Array.from(e.target.getElementsByTagName("input"))
            .filter(item => (item.type !== 'submit' && item.name !== 'live_search'))
            .reduce((obj, item) => {
                if (item.type === 'radio') {
                    if (item.checked) {
                        obj[item.name] = item.value
                    }
                } else if (item.type === 'checkbox') {
                    obj[item.name] = item.checked ? 'on' : 'off'
                } else {
                    obj[item.name] = item.value
                }
                return obj
            }, {})
        x['item_type'] = itemType
        x['type'] = pageType

        // Submit as formData
        fetch(`/cgi-bin/collections_save.pl`, {
            method: 'POST',
            body: new URLSearchParams(x),
        })
            .then((response) => response.json())
            .then((response) => {
                console.debug("save error:", response.error)
                console.debug("save changed:", response.results['changed'])
                console.debug(
                    "save changed_prefs:",
                    response.results['changed_prefs'],
                )
                if (response.error) {
                    displayError(response.error)
                } else {
                    console.log("Marking page not dirty")
                    setDirty(false)
                    updateItemTotals(prev => ({
                        ...prev,
                        [itemType]: {
                            ...(prev[itemType] || {}),
                            [pageType]: {
                                ...(prev[itemType]?.[pageType] || {}),
                                [c.View.SELF]: response.results.totals,
                            },
                        }
                    }))
                    setResultsText(
                        <CollectionSaveResults
                            data={response.results}
                            has_sets={itemSetCounts[pageType] !== undefined}
                            allItems={allItems[itemType]}
                            {...{pageType, itemType}} />
                    )
                }
            })
            .catch(err => {
                displayError()
                console.error(`Failed to save ${itemType}s`, err)
            })
    }, [allItems, itemSetCounts, itemType, pageType, setDirty, setResultsText,
    updateItemTotals])

    useEffect(() => {
        console.debug("Running effect to fetchItems")
        /*
         * We trigger on 'dirty' because we want to reload the data
         * when we save - and at the end of 'save', we set dirty to
         * false. So if dirty is true, don't do it.
         */
        if (dirty === true) {
            return
        }

        if (!dirty) {
            fetchItems({
                itemType, view, pageType, otherEmail, otherId,
            })
        }
    }, [dirty, itemType, view, pageType, otherEmail, otherId, fetchItems])

    const debouncedSearch = useMemo(() => debounce(setSearch, 500), [setSearch])
    const handleSearchChange = (e) => {
        e.persist()
        debouncedSearch(e.target.value.toLowerCase())
    }

    const debouncedPrefs = useMemo(() => debounce(setPrefs, 400), [setPrefs])
    const handleWantChange = (e) => {
        e.persist()
        debouncedPrefs(prev => ({
            ...prev,
            [itemType]: {
                ...prev[itemType],
                [currentMemberKey]: {
                    ...prev[itemType][currentMemberKey],
                    [`${itemType}s_num_desired`]: e.target.value,
                },
            },
        }))
    }

    const handlePrivacyChange = (e) => {
        e.persist()
        setPrefs(prev => ({
            ...prev,
            [itemType]: {
                ...prev[itemType],
                [currentMemberKey]: {
                    ...prev[itemType][currentMemberKey],
                    [`${itemType}s_privacy`]: e.target.value,
                },
            },
        }))
    }

    const updateCollectedVarieties = useCallback((e) => {
        if (varieties.length <= 1) {
            return
        }
        setPrefs(prev => ({
            ...prev,
            [itemType]: {
                ...prev[itemType],
                [currentMemberKey]: {
                    ...prev[itemType][currentMemberKey],
                    [e.target.name]: e.target.checked,
                }
            },
        }))
    }, [varieties, setPrefs, currentMemberKey, itemType])

    const handleSetDisplay = useCallback((e) => {
        setTypeDisplay(prev => ({
            ...prev, 
            [e.target.name]: e.target.checked,
        }))
    }, [setTypeDisplay])

    /*
     * we have to check for `pageType in allItems` as well
     * because we COULD have been on Trade, and then the menu
     * did a Link to us, causing `loaded` to be `true` already,
     * but we haven't loaded data yet.
     */
    const really_loaded = itemType in allItems && pageType in allItems[itemType]
    if (error) {
        return <Error {...{error}} />
    } else if (!loaded || !really_loaded) {
        return <Loading />
    }

    /*
     * if we're logged in OR looking at someone else's page,
     * then there's amounts, and thus amounts to filter on.
     */
    let showAmountLinks = (view === c.View.OTHER || userInfo['logged_in'] === 1)

    /*
     * We show the form if we're logged in and looking at our own list
     */
    let showForm = (view === c.View.SELF && userInfo['logged_in'] === 1)

    const currentAllItems = allItems[itemType] 
    const currentMemberItems = memberItems[itemType]
    const currentVarieties = varieties[itemType]
    const currentPrefs = prefs[itemType]
    const currentSortedIds = sortedIds[itemType]
    const currentRareness = rareness[itemType]
    const currentItemSetCounts = itemSetCounts[itemType]
    const currentItemSetImages = itemSetImages[itemType]

    return (
        <div>
            <Preamble
                {...{itemType, userInfo, multiPageType, pageType, updatePageType,
                        view, updateView, otherId, otherInfo, switchToSuggest,
                }}
            />
            <CollectionStats
                varieties={currentVarieties}
                prefs={currentPrefs}
                allItems={currentAllItems}
                memberItems={currentMemberItems}
                {...{itemType, amounts, view, typeDisplay,
                        userInfo, pageType, currentMemberKey}}
            />
            <ConditionalElement condition={showAmountLinks} alternative={<br />}>
                <AmountLinks {...{amounts, typeDisplay, handleSetDisplay}} />
            </ConditionalElement>
            <ConditionalForm
                wantForm={showForm}
                {...{handleSubmit}}
            >
                <ConditionalElement condition={showForm}>
                    <Preferences key={itemType} prefs={currentPrefs} {...{
                        itemType, handleWantChange, currentMemberKey,
                        handlePrivacyChange,
                    }} />
                </ConditionalElement>
                <div className="center">
                    {/*
                      * This is explicitly an uncontrolled component because
                      * the performance of keeping it controlled is terrible
                      *
                      * We also do the onKeyPress to prevent 'enter' on live
                      * search from causing a save, which we don't want since
                      * it's not saveable data.
                      */}
                    <input type="text" name="live_search" id="live_search" className="display_only livesearch center" placeholder=" Live search! " onChange={handleSearchChange} onKeyPress={ e => { e.key === 'Enter' && e.preventDefault() }} defaultValue={search} />
                </div>
                <VarietyLinks varieties={currentVarieties[pageType]} />
                <CollectionTables
                    allItems={currentAllItems}
                    memberItems={currentMemberItems}
                    varieties={currentVarieties}
                    prefs={currentPrefs}
                    itemSetCounts={currentItemSetCounts}
                    itemSetImages={currentItemSetImages}
                    rareness={currentRareness}
                    sortedIds={currentSortedIds}
                    {...{itemType, typeDisplay, search, view,
                            updateCollectedVarieties, setStatusText, userInfo,
                            pageType, solicitHelp, switchToGuide,
                            currentMemberKey,
                    }}
                />
                <ConditionalElement condition={showForm}>
                    <input tabIndex="3" className='submit' type="submit" value={`Save ${itemType}s and preferences`} />
                </ConditionalElement>
            </ConditionalForm>
        </div>
    )
}

export default CollectionForm
