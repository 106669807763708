import React from 'react'

import * as utils from './tourUtils.jsx'

const TourTableHeader = ({userInfo, skipLinks, pageType}) => {
    let seen = utils.includeSeenCol({userInfo, skipLinks, pageType})
        ? <th>Seen?</th>
        : undefined
    return (
        <tr>
            {seen}
            <th>Date</th>
            <th>Place</th>
            <th>Venue</th>
            <th>Comment</th>
            {skipLinks ? undefined : <th>Info</th>}
        </tr>
    )
}

export default TourTableHeader
