import React from 'react'

import * as c from './collectionConstants.jsx'

const CollectionTableHeaderCell = (
    {field, sortby = null, img = null, nosort = false, requestSort, sortConfig,
    style}
) => {
    const getClassNamesFor = (name) => {
        if (!sortConfig) {
            return "sortheader neither";
        }
        let cls = sortConfig.key === name ? sortConfig.direction : 'neither';
        return `sortheader ${cls}`
    };

    let sort = sortby
    if (sort === null) {
        sort = field
    }
    let contents = field.toUpperCase()
    if (img !== null) {
        contents = <img src={img} height={c.SCALE_HEIGHT} alt={field} />
    }

    if (nosort) {
        return <th>{contents}</th>
    }

    return (
        <th style={style}><span className={getClassNamesFor(sort)} onClick={(e) => {requestSort(sort)}}>{contents}</span></th>
    )
}

export default CollectionTableHeaderCell
