import React, { useState, useEffect } from 'react'

import Loading from '../Loading'

const AdminPending = ({setError}) => {
    const [pending, setPending] = useState(null)

    useEffect(() => {
        const getPending = () => {
            fetch('/admin-bin/pending_tasks.pl')
                .then(response => response.json())
                .then(i => {
                    if (i.error) {
                        setError(i.error_message)
                        return
                    }
                    setPending(i.results)
                })
                .catch(err => {
                    setError('Failed to get pending tasks')
                    console.error('Failed to get pending tasks', err)
                })
        }

        getPending()
    }, [setError])

    if (!pending) {
        return <Loading />
    }

    let body = []
    if (pending) {
        Object.keys(pending).forEach(t => {
            const numItems = pending[t]
            if (numItems) {
                body.push(<li key={t}>{t}: {numItems}</li>)
            }
        })
    }

    return <div style={{border: '1px solid white', padding: '5px', width: '25%'}}>
       <h3 style={{marginTop: '0'}}>Pending Work</h3>
       {body.length === 0 ? <em>none</em> : <ul>{body}</ul>}
   </div>
}

export default AdminPending
