import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const PapaHetTinGuide = ({itemType, updateGuide}) => {
    return (
    <>
<h2>Papa Het Picktins</h2>

<p>
There are three versions of the Papa Het picktin. The original two both came out in 2010 and two differences: (1) the black Papa Het Wheel pick was rounded in v1 and pointed in v2 (2) the back of the tin has a rectangle in v1 that&lsquo;s not there on v2. Here&lsquo;s the two picks (v1 pick coming soon).</p>

<p className="center">
<SimpleItemImage full="/images/picks/tins/339.png" thumb="/images/picks/tins/339--thumb.png" />
<SimpleItemImage full="/images/picks/tins/1350.png" thumb="/images/picks/tins/1350--thumb.png" />
</p>

<p>
In 2023, a new version came out with a different tin, but the same picks as v2. Here you can compare all 3 covers.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/papahettin/front_compare_1.png" thumb="/images/pickinfo/papahettin/front_compare_1--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/papahettin/back_compare_1.png" thumb="/images/pickinfo/papahettin/back_compare_1--thumb.png" />
</p>
    </>
    )
}

export default PapaHetTinGuide
