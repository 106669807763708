import React, { Fragment } from 'react'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils.jsx'
import ItemInput from './ItemInput.jsx'

// component that wraps ImageInput and returns alternative thing
// if we're not looking at our own report
const ItemQty = ({
    id, qty, incoming, view, prefs, handleHaveChange, handleIncomingChange,
    userInfo, itemType,
}) => {
    console.info("Rendering ItemQty")
    if (view === c.View.SELF) {
        if (userInfo['logged_in'] === 0) {
            return null;
        }
        return <ItemInput
            {...{id, qty, incoming, handleHaveChange, handleIncomingChange, itemType}}
        />
    } else if (view === c.View.OTHER) {
        let [display_qty, need] = utils.qtyToHaveAndNeed(
          id, qty, prefs[`${itemType}s_num_desired`]
        )
        return (
            <Fragment>
                <td className="colored center">{display_qty}{incoming > 0 && <Fragment><br/><span className="tooltip incoming_label">+{incoming} incoming<span className="left">{utils.formatMessage(c.INCOMING_TOOLTIP_TMPL, {item: itemType})}</span></span></Fragment>}</td>
            <td className="colored center">{need}</td>
            </Fragment>
        )
    } else {
        console.error(`ItemQty: WTF is this?! view is ${view}`)
    }
}

export default ItemQty
