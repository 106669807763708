import React, { useCallback } from 'react'

import { Menu, Item } from 'react-contexify'
import 'react-contexify/ReactContexify.css'

/*
 * Wrapper around react-contextify to handle the standard menu optoins
 * for collection tables.
 *
 * The "id" is required for react-contextify, and would theoretically
 * be just hard-coded here, since this menu is unique, but the parent
 * needs it to call `useContextMenu` to get the `show` function, so
 * it's passed in.
 */
const CollectionContextMenu = ({id, itemType, setStatusText}) => {
    const getItemNumberFromRow = (element) => {
        let tds = element.children
        for (let i in tds) {
            let td = tds[i]
            if (td.classList.contains("year")) {
                let id
                // loop over the children and look for an anchor tag
                // whose name is NOT empty (i.e. it's the ID)!
                for (let tdchild in td.children) {
                    let child = td.children[tdchild]
                    if (child.tagName === 'A' && child.name !== "") {
                        id = child.name
                        return id
                    }
                }
            }
        }
    }

    /*
     * TODO: Probably not generic enough. Caller should
     * pass in "description fields" rather than these be
     * hard-coded
     */
    const getItemDescriptionFromRow = (element) => {
        // then walk all it's direct children (the td's)
        let tds = element.children
        let t = ""
        for (let i in tds) {
            let td = tds[i]
            if (!td.classList) {
                continue
            }
            if (
                td.classList.contains("year") ||
                td.classList.contains("ptype") ||
                td.classList.contains("color") ||
                td.classList.contains("front") ||
                td.classList.contains("back") ||
                td.classList.contains("note")
            ) {
                let text = td.innerText.trim()
                if (text !== "") {
                    // year is the one where we also have to go get the id
                    // number
                    if (td.className === "year") {
                        let id
                        // loop over the children and look for an anchor tag
                        // whose name is NOT empty (i.e. it's the ID)!
                        for (let tdchild in td.children) {
                            let child = td.children[tdchild]
                            if (child.tagName === 'A' && child.name !== "") {
                                id = child.name
                                break
                            }
                        }
                        // For year the text is actually "year\nID: <ID>"
                        // so we only want the part before the newline
                        text = text.split("\n")[0]
                        // then join the ID and the year as two fields...
                        text = id + " | " + text
                    }
                    if (t !== "") {
                        t += " | "
                    }
                    t += text
                }
            }
        }
        return t
    }

    /* Make sure itemType is available before this is defined */
    const handleItemClick = useCallback(({ event, triggerEvent, id }) => {
        console.debug("CollectionContextMenu: event: ", event)
        console.debug("CollectionContextMenu: triggerEvent: ", triggerEvent)
        console.debug("CollectionContextMenu: id: ", id)
        console.debug("CollectionContextMenu: itemType: ", itemType)
        let element = triggerEvent.target
       
        // Go up until we get to the tr tag...
        while (element.tagName !== "TR") {
            element = element.parentElement
        }

        let t
        switch (id) {
            case 'copyitemnumber': {
                t = getItemNumberFromRow(element)
                break
            }
            case 'copyiteminfo': {
                t = getItemDescriptionFromRow(element)
                break
            }
            case 'copyitemlink': {
                let itemId = getItemNumberFromRow(element)
                t = window.location.href.replace(/#.*/, '')
                t += `#${itemType}${itemId}`
                break
            }
            default: {
                console.error(`Unknown right click menu item ${id}`)
                break
            }
        }

        console.debug("copying to clipboard: " + t)
        navigator.clipboard.writeText(t)
        let message = <>Placed the following in your clipboard: <em>{t}</em></>
        setStatusText(message)
    }, [itemType, setStatusText])

    return (
            <Menu id={id}>
                <Item id='copyitemnumber' onClick={handleItemClick}>Copy {itemType} ID number</Item>
                <Item id='copyiteminfo' onClick={handleItemClick}>Copy {itemType} info</Item>
                <Item id='copyitemlink' onClick={handleItemClick}>Copy link to {itemType}</Item>
            </Menu>
    )
}

export default CollectionContextMenu
