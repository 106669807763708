import React, { useState } from 'react'
import Select from 'react-select'

import * as c from './tourConstants.jsx'
import * as utils from './tourUtils.jsx'

import TourKbShortcuts from './TourKbShortcuts.jsx'
import './TourMenu.css'

const TourMenu = ({
    includeIndex, includeKbTt, pageType, updatePageType, menuData
}) => {
    const [submitted, setSubmitted] = useState(false)

    const dropDownValue = (dropdown, pageType) => {
        // If the page type is applicable to either of our dropdowns...
        if ([c.PageType.YEAR, c.PageType.TOUR].includes(pageType['type'])) {
            /*
             * If the value is for us, set it, otherwise revert
             * to default.
             *
             * Note that react-select doesn't just want 'value', it
             * needs the OBJECT of the option which is:
             *   { value: <value>, label: <label> }
             *
             * Also note that for the default, it wants `null`, not
             * such an object.
             */
            return dropdown === pageType['type'] ? {
                value: pageType['data'], label: pageType['data']
            } : null
        } else {
            // If it's not not one of the dropdowns, revert the dropdowns
            // to their default
            return null
        }
    }

    const genDropdown = (type) => {
        // generate all the options for this dropdown
        let options = menuData[type].map(item => ({ value: item, label: item }))

        // push the 'default' placeholder onto the beginning
        const def = utils.pageTypeToHeading(type).toUpperCase() + 'S'
        options.unshift({
            value: null,
            label: def,
            isDisabled: true,
        })

        // a bunch of styles to make this look like a normal select
        const selectStyle = {
            container: provided => ({
                ...provided,
                // make the selects inline themselves
                display: 'inline-block',
                zIndex: 1,
            }),
            control: provided => ({
                ...provided,
                // Fix height and remove padding
                // so react-select isn't super tall
                minHeight: 'unset',
                height: '25px',
                padding: '0',
                // make fonts match
                fontFamily: 'inherit',
                lineHeight: '1',
                zIndex: 'inherit',
            }),
            input: provided => ({
                ...provided,
                margin: '0',
                padding: '0',
                // override bold/caps inheritance
                fontWeight: 'normal',
                textTransform: 'none',
            }),
            dropdownIndicator: provided => ({
                ...provided,
                // keeps the text from overflowing the box
                padding: '0 2px',
            }),
            menu: provided => ({
                ...provided,
                backgroundColor: 'white',
                color: 'black',
            }),
            option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isFocused ? '#f0f0f0' : 'white',
                color: 'black',
                fontSize: '1em',
                fontFamily: 'inherit',
                lineHeight: '1',
                fontWeight: 'normal',
                textTransform: 'none',
                // drop the pading between options in the dropdown
                padding: '4px 8px',
            }),
            singleValue: provided => ({
                ...provided,
                color: 'black',
                fontSize: '1em',
                fontWeight: 'normal',
                textTransform: 'none',
            }),
        }
               
        return <Select
                style={{width: '5%'}}
                options={options}
                onChange={(val) => {updatePageType(null, type, val.value)}}
                placeholder={def}
                styles={selectStyle}
                value={dropDownValue(type, pageType)}
            />
    }

    /*
     * debounce search so that we don't search on enter AND on
     * focus-out
     */
    const handleSearch = e => {
        if (e.target.value === "") {
            return
        }
        updatePageType(e, c.PageType.SEARCH)
        setSubmitted(true)
    }

    const yearDropdown = genDropdown(c.PageType.YEAR)
    const tourDropdown = genDropdown(c.PageType.TOUR)
    const sep = <> &nbsp; | &nbsp; </>

    return (
        <div className="quick_menu">
            <>[ &nbsp;
                {includeIndex &&
                    <><a href="/app/tour"
                        onClick={e => updatePageType(e, c.PageType.INDEX)}
                    >Main</a>{sep}</>}
                {yearDropdown}
                {sep}
                {tourDropdown}
                {sep}
                <input
                    className="display_only"
                    type="text"
                    name="search"
                    placeholder="search"
                    /*
                     * submit the search if the user hits enter
                     * OR if they leave the search bar
                     */
                    onKeyPress={e => {
                        e.key === 'Enter' && handleSearch(e)
                    }}
                    onBlur={e => {
                        if (!submitted) {
                            handleSearch(e)
                        }
                        setSubmitted(false)
                    }}
                    size="15"
                    maxLength="25"
                />
                {sep}
                <a href={`/app/tour?pagetype=${c.PageType.ADVANCED_SEARCH}`}
                    onClick={e => {
                        updatePageType(e, c.PageType.ADVANCED_SEARCH)
                    }}
                >Advanced Search</a>
                {sep}
                <a href={`/app/tour?pagetype=${c.PageType.REPORT}`}
                    onClick={e => {
                        updatePageType(e, c.PageType.REPORT)
                    }}
                >Personal Tour Report</a>
                {sep}
                 <a href={`/app/tour?pagetype=${c.PageType.STATS}`}
                    onClick={e => {
                        updatePageType(e, c.PageType.STATS)
                    }}
                 >Tour Stats</a> &nbsp; ] {includeKbTt && <TourKbShortcuts/>}
            </>
        </div>
    )
}

export default TourMenu
