import React  from 'react'
import { Link } from 'react-router-dom'

import './NewsSnippets.css'

const NewsSnippets = () => {
    const snippets = [
        <div key='tour' className='news-snippet'>
            Completely revamped <Link to="/app/tour">Tour</Link>
        </div>,
        <div key='profile' className='news-snippet'>
            <Link to="/app/profile">Profiles</Link> with badges!
        </div>
    ]

    if (!snippets.length) {
        return null
    }

    return <div className='news-snippet'>
        <span className="headline">NEW!</span><br/>
        {snippets}
    </div>
}

export default NewsSnippets
