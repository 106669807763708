import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const WrathGuide = ({itemType, updateGuide}) => {

    /*
     * Rather than use thumbs, we use the normal pick image,
     * and specify a size much larger than our normal thumbs
     */
    const imageList = (name, num, side, link, width, height) => {
        let arr = []
        let style = {}
        if (width && height) {
            style = {width: width, height: height}
        }
        let sides = []
        if (side === 'both') {
            sides = ['front', 'back']
        } else {
            sides = [side]
        }
        for (let i = 1; i <= num; i++) {
            for (let side of sides) {
                let path = `/images/pickinfo/wrath/wrath_${side}_${name}_${i}`
                if (link) {
                    arr.push(
                        <SimpleItemImage
                            key={i + side}
                            full={`${path}.png`}
                            thumb={`${path}.png`}
                            style={style}
                        />
                    )
                } else {
                    arr.push(
                        <img src={`${path}.png`} alt={`${name}-${side}-${i}`} />
                    )
                }
            }
        }
        return arr
    }

    return (
    <>

<h2>Wrath of Man picks</h2>

<p>
Some folks have wondered if there are two versions of the Wrath of Man picks. St&eacute;phane Troy kindly donated several of these picks to help us do a detailed analysis.</p>

<p>
The short version is that while several picks do in fact seem to be very different, they are just shifted noticably left-right or up-down. We&lsquo;ll show the analysis below. There&lsquo;s also a lot of variances in the deepness of the purples, but we believe that is natural fluctuations in the ink of a given run.</p>

<p>
First, lets just look at the 3 different picks next to each other.</p>

<p className="center">
{imageList('compare', 1, 'front', true, 655, 183)}<br/>
{imageList('compare', 1, 'back', true, 655, 183)}
</p>

<p>
The easiest place to see how these lines up is the right edge of the front, so let&lsquo;s start there. First, we&lsquo;ll cut out the purple edge from the two most different picks, then we&lsquo;ll make one semi-transparent, and then move it over the other to show they line up.</p>

<p className="center">
{imageList('right_edge', 3, 'front', false)}
</p>

<p>
The same is true for the bottom:</p>

<p className="center">
{imageList('bottom', 3, 'front', false)}
</p>

<p>
And finally, lets look at the back, at the top. You can see it&lsquo;s just down-shifted slightly on some picks. There&lsquo;s a slight rotation to the picks when we scanned them, but you can see the art itself is the same.</p>

<p className="center">
{imageList('top', 3, 'back', false)}
</p>

<p>
Based on this analysis, it&lsquo;s clear that this is just slight shifts in where the pick is on the conveyer belt when it gets stamped.</p>

    </>
    )
}

export default WrathGuide
