import React, { Fragment } from 'react'

import * as ipom from '../common.jsx'
import Share from '../Share.jsx'

import * as c from './collectionConstants.jsx'

// Small Component to generate the share info at the top of the page
const CollectionShare = ({itemType, userInfo, pageType, view}) => {
    console.info("Rendering Share")
    if (pageType === c.PageType.UNOFFICIAL || view !== c.View.SELF ||
        userInfo['logged_in'] !== 1) {
        return null;
    }

    function shortUrl() {
        let u = `/app/${itemType}s?id=${userInfo['id']}`
        if (pageType && pageType !== c.PageType.OFFICIAL) {
            u += `&type=${pageType}`
        }
        return u
    }

    function fullUrl() {
        return `${ipom.API_ENDPOINT}${shortUrl()}`
    }

    return (
        <Fragment>
            <p key="4">
                If you&lsquo;ve enabled public viewing of our collection (we mask any number of {itemType}s above your desired amount as &quot;Extra&quot;, see what others will see <a href={shortUrl()}>here</a>), you can share it using the following URL or buttons!
            </p>
            <blockquote>
                <Share relUrl={shortUrl()} fullUrl={fullUrl()} />
            </blockquote>
        </Fragment>
    )
}

export default CollectionShare
