import React from 'react'
import Joyride, { STATUS } from 'react-joyride';

import * as c from './collectionConstants.jsx'

const CollectionTour = ({
    itemType, pageType, startTour, setStartTour, multiPageType
}) => {

    let tourIntroStep = {
        disableBeacon: true,
        target: "h1",
        content: `Welcome to the all-new redesigned ${itemType}s section! There's a lot of cool new features, let's take a quick tour!`,
    }

    let tourSuggestIntroStep = {
        disableBeacon: true,
        target: "h1",
        content: `Welcome to the Trade Suggestions! There's a lot of cool new features, let's take a quick tour!`,
    }

    let tourQuickMenuStep = {
            target: ".quick_menu",
            content: `Only the most official ${itemType}s are on this page, be sure to checkout Semi-official and Unofficial ${itemType}s too! You can even track your unofficial ${itemType}s now!`,
    }

    let tourWantlistStep = {
        target: ".wantlist_link",
        content: "You can generate an image with your wantlist on it for easy sharing on social media."
    }

    let tourItemListSteps = [
        {
            target: "#kbshortcuts",
            content: "We have keyboard shortcuts! Click here to see how to quickly save and do other things!",
        },
        {
            target: ".amount_links",
            content: `You can use these check-boxes to hide ${itemType}s that you're missing, or ${itemType}s you have extra of, or any other combination to get just the list of ${itemType}s you want right now`,
        },
        {
            target: ".preferences",
            content: `Don't forget to set your list to public if you want to show up in trade suggestions. And set the number of each ${itemType} you want, too!`,
        },
        {
            target: ".livesearch",
            content: `You can type stuff in here to search through all ${itemType}s instantly.`
        },
    ]

    let tourTradeSuggestionsSteps = [
        {
            target: 'input#all_needed',
            content: `If someone is selling their collection, click here to see all ${itemType}s they have you need, not just their extras.`
        },
    ]

    let tourItemRowSteps = [
        {
            target: `tr.${itemType}row`,
            content: `If you right-click on a row in a table you can copy a ${itemType} ID, whole ${itemType} description, or link to the ${itemType}.`
        },
        {
            target: "td.rareness",
            content: "We help you make fair trades with our rareness meter! The more red, the more rare."
        },
    ] 

    let tourSteps = []
    let storageKey
    if (pageType === c.PageType.TRADE_SUGGESTIONS) {
        storageKey = c.StorageKeys.TRADE_SUGGEST_TOUR_KEY
        tourSteps = [
            tourSuggestIntroStep,
            tourWantlistStep,
            ...tourTradeSuggestionsSteps,
        ]
    } else {
        storageKey = c.StorageKeys.COLLECTION_TOUR_KEY
        tourSteps = [tourIntroStep]
        if (multiPageType) {
            tourSteps.push(tourQuickMenuStep)
        }
        tourSteps = [...tourSteps, tourWantlistStep, ...tourItemListSteps]
    }

    tourSteps = [...tourSteps, ...tourItemRowSteps]

    const handleJoyrideCallback = data => {
        const status = data.status

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            console.debug("storing that tour is done/skipped")
            localStorage.setItem(storageKey, "1")
            setStartTour(false)
        }
    }
    return (
        <Joyride steps={tourSteps} continuous={true} run={startTour} callback={handleJoyrideCallback} scrollOffset={75} showProgress={true} showSkipButton={true}  />
    );
};

export default CollectionTour
