import React from 'react'

import GuideLink from './GuideLink.jsx'

const PickExtraGuides = ({updateGuide}) => {
    return (<>
<p>
Special Pick Guides:<br/>
<GuideLink guide='xxx' {...{updateGuide}}>XXX</GuideLink> | <GuideLink guide='esp' {...{updateGuide}}>ESP promo</GuideLink> | <GuideLink guide='sm2' {...{updateGuide}}>S&amp;M2</GuideLink> | <GuideLink guide='stanger' {...{updateGuide}}>St. Anger</GuideLink> | <GuideLink guide='wrath' {...{updateGuide}}>Wrath of Man</GuideLink> | <GuideLink guide='yellowjazziii' {...{updateGuide}}>Yellow Jazz III</GuideLink> | <GuideLink guide='papahettin' {...{updateGuide}}>Papa Het Tin</GuideLink>
</p>

<p>
<GuideLink guide='purchasable' {...{updateGuide}}>Spotting picktins/purchasables</GuideLink>
</p>

<p>
<GuideLink guide='fakes' {...{updateGuide}}>Spotting fakes</GuideLink>
</p>
    </>)
}

export default PickExtraGuides
