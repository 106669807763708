import React, { useEffect, useRef } from 'react'
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom'

import * as ipom from '../common.jsx'

import GuideLink from './GuideLink.jsx'
import PickExtraGuides from './PickExtraGuides.jsx'

import ESPGuide from './guides/ESPGuide.jsx'
import FakesGuide from './guides/FakesGuide.jsx'
import LineageGuide from './guides/LineageGuide.jsx'
import PapaHetTinGuide from './guides/PapaHetTinGuide.jsx'
import PurchasableGuide from './guides/PurchasableGuide.jsx'
import SM2Guide from './guides/SM2Guide.jsx'
import StAngerGuide from './guides/StAngerGuide.jsx'
import TradingGuide from './guides/TradingGuide.jsx'
import WrappingGuide from './guides/WrappingGuide.jsx'
import WrathGuide from './guides/WrathGuide.jsx'
import XXXGuide from './guides/XXXGuide.jsx'
import YellowJazzIIIGuide from './guides/YellowJazzIIIGuide.jsx'

const specificItemTypeGuides = {
    'pick': PickExtraGuides,
}

const components = {
    'esp': ESPGuide,
    'fakes': FakesGuide,
    'lineage': LineageGuide,
    'papahettin': PapaHetTinGuide,
    'purchasable': PurchasableGuide,
    'sm2': SM2Guide,
    'stanger': StAngerGuide,
    'trading': TradingGuide,
    'wrapping': WrappingGuide,
    'wrath': WrathGuide,
    'xxx': XXXGuide,
    'yellowjazziii': YellowJazzIIIGuide,
}

const CollectionGuides = ({
    itemType, updatePageType, guide, setGuide,
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const navigationType = useNavigationType()
    // ref to Guide so we can use it in the navigation useEffect without
    // having to depend on guide directly
    const guideRef = useRef(guide)

    const updateGuide = (e, guide) => {
        e.preventDefault()
        let urlParams = ipom.getUrlSearchParams(location)
        if (guide === null || guide === "") {
            urlParams.delete('guide')
        } else {
            urlParams.set('guide', guide)
        }
        setGuide(guide)
        guideRef.current = guide
        ipom.urlPushSearch(urlParams, navigate)
    }

    // handle 'back' button, set the guide from the URL
    useEffect(() => {
        if (navigationType !== 'POP') {
            console.debug(
                'GuideHistoryListener: Ignoring nav buttons used, location: ' +
                `${JSON.stringify(location)}, action: ${navigationType}`
            )
            return
        }

        console.debug(
            'GuideHistoryListener: Processing navigation, location: ' +
            `${JSON.stringify(location)}, action: ${navigationType}`
        );

        const urlParams = ipom.getUrlSearchParams(location)
        let urlGuide = urlParams.get('guide');
        console.debug('GuideHistoryListener, guide, urlGuide', guideRef.current, urlGuide)

        if (guideRef.current !== urlGuide) {
            console.debug("GuideHistoryListener: Setting guide to", urlGuide);
            // not updateGuide...
            setGuide(urlGuide);
        }
        guideRef.current = urlGuide
    }, [location, setGuide, navigationType]);

    const specificGuides = () => {
        let Component = specificItemTypeGuides[itemType]
        if (Component) {
            return <Component {...{updateGuide}} />
        }
        return null
    }

    if (guide) {
        let Component = components[guide]
        if (!Component) {
            return <>No such Guide</>
        }
        return (
            <>
            <p className="center">
                <GuideLink guide="" {...{updateGuide}}>Back To Guide List</GuideLink>
            </p>
            <Component {...{itemType, updateGuide}} />
            </>
        )
    }

    return (<>
<p>
Welcome to the most thorough guides to Pick trading on the &lsquo;net! Whether you want to learn how to trade, know how best to pack picks, or know what to do when picks get lost in the mail, our <GuideLink guide='trading' {...{updateGuide}}>Trading Guide</GuideLink> is for you! We have a detailed guide to tracking pick lineage, guides on specific picks and more!</p>

<div className="center">
<p>
<GuideLink guide='trading' {...{updateGuide}}>Trading Guide</GuideLink><br/>
Packing, shipping, etiquette, organizing and more!
</p>

<p>
<GuideLink guide='lineage' {...{updateGuide}}>Lineage Guide</GuideLink><br/>
The best way to protect yourself and the community from fakes!
</p>

{specificGuides()}
</div>
    </>)
}

export default CollectionGuides
