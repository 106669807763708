import React, { useState } from 'react'

import * as c from './tourConstants.jsx'
import './TourSearchForm.css'

const TourSearchForm = ({userInfo, updatePageType}) => {
    const [date, setDate] = useState()
    const [city, setCity] = useState()
    const [state, setState] = useState()
    const [country, setCountry] = useState()
    const [venue, setVenue] = useState()
    const [comments, setComments] = useState()
    const [setlist, setSetlist] = useState()
    const [onlyMyShows, setOnlyMyShows] = useState()

    const handleSubmit = (e) => {
        e.preventDefault()
        const params = Object.fromEntries(
            Object.entries({
                date: date,
                city: city,
                state: state,
                country: country,
                venue: venue,
                comments: comments,
                setlist: setlist,
                onlymyshows: onlyMyShows,
            }).filter(([key, value]) => value !== undefined)
        )
        console.debug('search params:', params)
        updatePageType(e, c.PageType.SEARCH, params)
    }

    return (<>
        <p>This is the greatest part of our tour section: you can find shows based on any criteria you can possibly imagine! You can find all shows in your home town, all shows that had a certain song played, or any combination!</p>

        <p>Here are some searching hints:</p>

        <ul>
            <li>You can search partial years. For example &quot;20&quot; will match 2000-2099, &quot;200&quot; will match 2000-2009, &quot;2001-0&quot; will match 2001-01 - 2001-09, and so on.</li>
            <li>All searches are &quot;partial-match&quot;, so if you search for &quot;seek&quot; in setlist it&lsquo;ll match all shows where they played &quot;Seek and Destroy&quot;, or if you search for &quot;Los&quot; in city, it&lsquo;ll match any city with &quot;Los&quot; in it&lsquo;s name.</li>
            <li>All searches are case-insensitive</li>
        </ul>

        <form className="tour_search_container" onSubmit={handleSubmit}>
            <table className="tour_search">
                <thead>
                    <tr>
                        <th>POSSIBLE SEARCH TERMS</th>
                        <th>YOUR SPACE</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Date</td>
                        <td>
                            <input
                                type="text" name="date" maxLength="10"
                                value={date}
                                onChange={e => setDate(e.target.value)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Venue</td>
                        <td>
                            <input
                                type="text" name="venue" maxLength="45"
                                value={venue}
                                onChange={e => setVenue(e.target.value)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>City</td>
                        <td>
                            <input
                                type="text" name="city" maxLength="30"
                                value={city}
                                onChange={e => setCity(e.target.value)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>State/Province</td>
                        <td>
                            <input
                                type="text" name="state" maxLength="30"
                                value={state}
                                onChange={e => setState(e.target.value)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Country</td>
                        <td>
                            <input
                                type="text" name="country" maxLength="30"
                                value={country}
                                onChange={e => setCountry(e.target.value)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Comments</td>
                        <td>
                            <input
                                type="text" name="comments" maxLength="75"
                                value={comments}
                                onChange={e => setComments(e.target.value)}
                            />
                        </td>
                    </tr>

                    <tr>
                        <td>Setlist</td>
                        <td>
                            <input
                                type="text" name="setlist" maxLength="30"
                                value={setlist}
                                onChange={e => setSetlist(e.target.value)}
                            />
                        </td>
                    </tr>

                    {userInfo['logged_in'] === 1 &&
                        <tr>
                            <td>Only include shows I&lsquo;ve seen</td>
                            <td>
                                <input
                                    type="checkbox" name="onlymyshows"
                                    checked={onlyMyShows}
                                    onChange={e => setOnlyMyShows(e.target.checked)}
                                />
                            </td>
                        </tr>
                    }

                    <tr>
                        <td><input type="reset" value="     CLEAR       " /></td>
                        <td><input type="submit" value="     SEARCH      " /></td>
                    </tr>
                </tbody>
            </table>
        </form>
    </>
    )
}

export default TourSearchForm
