import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import './Menu.css'

const Menu = () => {
    const [openSubmenu, setOpenSubmenu] = useState(null) // Tracks which submenu is open
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false) // Tracks if mobile menu is open

    const toggleMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen)
    }

    const toggleSubmenuHandler = (submenu) => {
        // Toggle the submenu visibility (for mobile)
        setOpenSubmenu(openSubmenu === submenu ? null : submenu)
    }

    const openSubmenuHandler = (submenu) => {
        // Open a submenu (for desktop hover)
        setOpenSubmenu(submenu)
    }

    const closeSubmenuHandler = () => {
        // Close the submenu (for desktop hover)
        setOpenSubmenu(null)
    }

    const closeMenuHandler = () => {
        // Close the submenu and the entire menu
        setOpenSubmenu(null)
        setMobileMenuOpen(false)
    }

    return (
        <>
            <div className="mobile-menu-container" onClick={toggleMenu}>
                <span>Menu</span>
            </div>
            <div className={`header ${mobileMenuOpen ? 'open' : ''}`} id="menu">
                <div className="logo">
                    <img src="/images/header_logo.png" alt="IPOM Logo" />
                </div>
                <div className="castle" id="castle">
                    <img src="/images/ipom_castle.png" alt="IPOM Castle" />
                </div>
                <nav>
                    <ul className="menu">
                        <li><Link to="/app/" onClick={closeMenuHandler}>Home</Link></li>
                        <li
                            className="dropdown"
                            onMouseEnter={() => openSubmenuHandler('about')}
                            onMouseLeave={closeSubmenuHandler}
                        >
                            <span
                                onClick={() => toggleSubmenuHandler('about')} // Mobile toggle
                                className={openSubmenu === 'about' ? 'active' : ''}
                            >
                                About
                            </span>
                            <ul className={`dropdown-content submenu ${openSubmenu === 'about' ? 'open' : ''}`}>
                                <li><a href="/about/what_is_ipom.shtml" onClick={closeMenuHandler}>What</a></li>
                                <li><a href="/about/who_is_ipom.shtml" onClick={closeMenuHandler}>Who</a></li>
                                <li><a href="/about/contribute.shtml" onClick={closeMenuHandler}>Contribute</a></li>
                                <li><a href="/mailman3/postorius/lists/ipomnl.ipom.com/" onClick={closeMenuHandler}>Newsletter</a></li>
                                <li><a href="/about/thanks.shtml" onClick={closeMenuHandler}>Thanks</a></li>
                                <li><a href="/about/tech.shtml" onClick={closeMenuHandler}>Tech Info</a></li>
                                <li><a href="/archives/" onClick={closeMenuHandler}>Archives</a></li>
                                <li><a href="/copyright.shtml" onClick={closeMenuHandler}>Copyright</a></li>
                            </ul>
                        </li>
                        <li
                            className="dropdown"
                            onMouseEnter={() => openSubmenuHandler('tour')}
                            onMouseLeave={closeSubmenuHandler}
                        >
                            <span
                                onClick={() => toggleSubmenuHandler('tour')} // Mobile toggle
                                className={openSubmenu === 'tour' ? 'active' : ''}
                            >
                                Tour
                            </span>
                            <ul className={`dropdown-content submenu ${openSubmenu === 'tour' ? 'open' : ''}`}>
                                <li><Link to="/app/tour" onClick={closeMenuHandler}>Tours</Link></li>
                                <li><Link to="/app/tour?pagetype=_adv_search_" onClick={closeMenuHandler}>Search</Link></li>
                                <li><Link to="/app/tour?pagetype=_report_" onClick={closeMenuHandler}>Personal Report</Link></li>
                                <li><Link to="/app/tour?pagetype=_stats_" onClick={closeMenuHandler}>Stats</Link></li>
                            </ul>
                        </li>
                        <li
                            className="dropdown"
                            onMouseEnter={() => openSubmenuHandler('picks')}
                            onMouseLeave={closeSubmenuHandler}
                        >
                            <span
                                onClick={() => toggleSubmenuHandler('picks')} // Mobile toggle
                                className={openSubmenu === 'picks' ? 'active' : ''}
                            >
                                Picks
                            </span>
                            <ul className={`dropdown-content submenu ${openSubmenu === 'picks' ? 'open' : ''}`}>
                                <li><Link to="/app/picks" onClick={closeMenuHandler}>List</Link></li>
                                <li><Link to="/app/picks?pagetype=_trade_" onClick={closeMenuHandler}>Trading Center</Link></li>
                                <li><Link to="/app/picks?pagetype=_wantlist_" onClick={closeMenuHandler}>Wantlist</Link></li>
                                <li><Link to="/app/picks?pagetype=_guides_" onClick={closeMenuHandler}>Guides</Link></li>
                            </ul>
                        </li>
                        <li
                            className="dropdown"
                            onMouseEnter={() => openSubmenuHandler('coins')}
                            onMouseLeave={closeSubmenuHandler}
                        >
                            <span
                                onClick={() => toggleSubmenuHandler('coins')} // Mobile toggle
                                className={openSubmenu === 'coins' ? 'active' : ''}
                            >
                                Coins
                            </span>
                            <ul className={`dropdown-content submenu ${openSubmenu === 'coins' ? 'open' : ''}`}>
                                <li><Link to="/app/coins" onClick={closeMenuHandler}>List</Link></li>
                                <li><Link to="/app/coins?pagetype=_trade_" onClick={closeMenuHandler}>Trading Center</Link></li>
                                <li><Link to="/app/coins?pagetype=_wantlist_" onClick={closeMenuHandler}>Wantlist</Link></li>
                                <li><Link to="/app/coins?pagetype=_guides_" onClick={closeMenuHandler}>Guides</Link></li>
                            </ul>
                        </li>
                        <li><a href="/cgi-bin/lt_index.pl" onClick={closeMenuHandler}>Lyric Theories</a></li>
                        <li
                            className="dropdown"
                            onMouseEnter={() => openSubmenuHandler('other')}
                            onMouseLeave={closeSubmenuHandler}
                        >
                            <span
                                onClick={() => toggleSubmenuHandler('other')} // Mobile toggle
                                className={openSubmenu === 'other' ? 'active' : ''}
                            >
                                Other
                            </span>
                            <ul className={`dropdown-content submenu ${openSubmenu === 'other' ? 'open' : ''}`}>
                                <li><a href="/lyrics/" onClick={closeMenuHandler}>Lyrics</a></li>
                                <li><a href="/cgi-bin/calendar_index.pl" onClick={closeMenuHandler}>Calendar</a></li>
                                <li><a href="/faq.shtml" onClick={closeMenuHandler}>FAQ</a></li>
                                <li><a href="/cgi-bin/quotables_index.pl" onClick={closeMenuHandler}>Quotables</a></li>
                                <li><a href="/blackened.shtml" onClick={closeMenuHandler}>Blackened</a></li>
                                <li><a href="/mailman/listinfo/ipomnl" onClick={closeMenuHandler}>Newsletter</a></li>
                                <li><a href="/contest/" onClick={closeMenuHandler}>Contests</a></li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Menu

