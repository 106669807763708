import React, { Fragment } from 'react'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils'

const Preferences = ({
    pageType, prefs, handleWantChange, handlePrivacyChange,
    itemType, currentMemberKey,
}) => {
    console.info(`Rendering Preferences for ${itemType}`)

    const currentPrefs = prefs[currentMemberKey]

    if (!currentPrefs) return

    const privacy = currentPrefs[`${itemType}s_privacy`]
    const num = currentPrefs[`${itemType}s_num_desired`]

    function qtyInputs() {
        if (pageType === c.PageType.UNOFFICIAL) {
            return null
        }
        return (
            <Fragment>
                <label htmlFor="num_items_desired">
                    Number of each {itemType} desired
                </label>:&nbsp;
                <input tabIndex="1" type="number"
                    // will force a remount of this comp
                    // when switching between itemTypes
                    key={`${itemType}_number`}
                    name='num_items_desired' defaultValue={num}
                    size="2" onChange={handleWantChange} />
            </Fragment>
        )
    }

    return (
        <p className="center preferences">
            <strong>Preferences</strong>: [ Privacy:&nbsp;
                <span className="tooltip">
                    <img src="/images/info_icon.png" height={c.ICON_HEIGHT} width={c.ICON_WIDTH} alt="info" />
                    <span className="right">{utils.formatMessage(c.PRIVACY_TOOLTIP_TMPL, {item: itemType})}</span>
                </span>
            <input tabIndex="1" type="radio"
                name='privacy' value='public' defaultChecked={privacy === 'public'}
                onChange={handlePrivacyChange} /> Public
            <input tabIndex="1" type="radio"
                name='privacy' value='private' defaultChecked={privacy !== 'public'} 
                onChange={handlePrivacyChange} /> Private |&nbsp;
            {qtyInputs()} ]&nbsp;
            <input tabIndex="1" type="submit" value={`Save ${itemType}s and preferences`} />
        </p>
    )
}

export default Preferences
