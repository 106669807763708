import React, { Fragment } from 'react'

import * as c from './collectionConstants.jsx'

// Component for the warnings
const UnofficialWarning = ({pageType}) => {
    console.info("Rendering UnofficialWarning")
    if (pageType === c.PageType.SEMIOFFICIAL) {
        return (<Fragment key="warning">
    <p>
    <strong>WARNING! READ!</strong> The <em>semi-official picks</em> page is <strong>beta</strong> and <strong>best effort</strong>. Unlike the <em>official pick</em> collection, this list is, by definition, <strong>incomplete</strong>. Here&lsquo;s some guidelines we are starting with.</p>
    <ul>
    <li><em>Prototypes</em> - We&lsquo;re going to be exceptionally picky/careful to only list ones we know for sure (e.g. green St. Anger, brown pre-White Fang).</li>
    <li><em>Misprints</em> - We are limiting it to <strong>common</strong> misprints - ones that have been seen by multiple people (e.g. the blank-back Dirty Donny cartoon picks)</li>
    <li><em>Official Event</em> - For official picks for events that involve Metallica members but are not Metallica events (e.g. Fear FestEvil, It&lsquo;s Alive, etc.). We will try to be as complete as possible.</li>
    </ul>
    </Fragment>
        )
    } else if (pageType === c.PageType.UNOFFICIAL) {
        return (
            <Fragment key="warning">
            <p>
            <strong>WARNING! READ!</strong> The <em>unofficial picks</em> page is <strong>primarily to prevent people from getting tricked by unofficial picks</strong>. Picks listed here are <strong>created by fans</strong>. There is nothing wrong with unofficial picks (our own pick is listed here!). At the same time, many unofficial picks are created by people looking to cash in or trick people. We want collectors to be able to quickly identify picks with no official affiliation so they are not fooled into spending money or trades for items made by fans. We feel strongly that fans creating picks shouldn&lsquo;t be using them to gain things, but to share with their fellow fans. It&lsquo;s why our IPOM picks are always given away for free.</p>
            <p>
            We love chapters! Therefore we have a special section just for chapter picks. if you are a chapter head, send us 1 - or preferrably 2 - of your official chapter picks, and we will list them. Only picks with the chapter name or logo will appear here, otherwise they will appear in unofficial picks.
            </p>
            <p>
            In order to not help those people, your public list will not show your unofficial picks, we do not list rareness, and Trade Suggestions will not suggest trades for unofficial picks. That doesn&lsquo;t mean you shouldn&lsquo;t keep picks you think are cool! Therefore, we do still let you track your collection of them here.</p>
            </Fragment>
        )
    }
    return null
}

export default UnofficialWarning
