import React from 'react'

import './KbShortcuts.css'

const KbShortcuts = () => {
    return (
        <>
            <div id="kbshortcuts">
                <span className="tooltip">
                    <img id="kbshortcut_icon" src="/images/keyboard_icon.png" alt="Keyboard Shortcuts" />
                    <span className="left">
                        <strong>Keyboard Shortcuts</strong>
                        <ul className="kbshortcuts">
                            <li>shift+s = Save</li>
                            <li>shift+k = Jump to Live Search</li>
                        </ul>
                    </span>
                </span>
            </div>
        </>
    )
}

export default KbShortcuts
