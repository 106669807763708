import React from 'react'

import * as c from './collectionConstants.jsx'

const ItemRarenessCell = ({rareness, max}) => {
    if (rareness === 'new') {
        return (
            <td className="rareness">
                <img
                    src={`/images/pickinfo/rarescale-of-${max}/new.png`}
                    alt='Too new to calculate'
                    title='Too new to calculate. Must be older than 6 months.'
                    height='60'
                />
            </td>
        )
    }

    return (
        <td className="rareness">
            <img
                src={`/images/pickinfo/rarescale-of-${max}/rarescale-${rareness}.png`}
                alt={`Rareness is ${rareness} out of ${max}`}
                title={`Rareness is ${rareness} out of ${max}\n${max} is the most rare`}
                height={c.SCALE_HEIGHT}
            />
        </td>
    )
}

export default ItemRarenessCell
