import React, {useState, useEffect} from 'react'

const TourShirtInfo = ({userInfo, setError}) => {
    console.info('Rendering TourShirtInfo')
    const [email, setEmail] = useState()
    const [info, setInfo] = useState()

    const renderForm = () => {
        return <>Email: <input onBlur={(e) => {setInfo(null); setEmail(e.target.value);}} /></>
    }

    const header = () => {
        return <h2>Shirt Info</h2>
    }

    useEffect(() => {
        if (!email) {
            return
        }
        console.debug("Running effect to get shirt data")

        const payload = {email: email}
        fetch('/cgi-bin/tour_shirt_info.pl', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setError(i.error_message)
                }
                setInfo(i['results'])
            })
    }, [email, setError])

    if (!userInfo.logged_in || (!userInfo.admin && userInfo.id !== 5805)) {
        return <h1>Access Denied</h1>
    }

    if (!info) {
        return <>
            {header()}
            {renderForm()}
        </>
    }


    return <>
        {header()}
        {renderForm()}
        <h2>ID: {info.id} / EMAIL: {email}</h2>
        {info.show_threshold ? <>
            <p>
                <strong>Threshold</strong>: <strong>{info.show_threshold}</strong>
            </p>
            <p>
                <strong>QR Code</strong>:<br/><img
                    src={`/cgi-bin/tour_gen_qrcode.pl?id=${info.id}`}
                    title={`QR code for ${email}`}
                    alt={`QR code for ${email}`}
                />
            </p>
        </> : <p><strong>USER DOES NOT QUALIFY FOR ANY SHIRTS</strong></p>}
    </>
}

export default TourShirtInfo
