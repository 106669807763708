import React, { useCallback, useMemo } from 'react'

import Loading from '../Loading.jsx'

import * as c from './tourConstants.jsx'
import * as utils from './tourUtils.jsx'
import TourTable from './TourTable.jsx'
import TourTableRow from './TourTableRow.jsx'

/*
 * This generates the rows for all tables since we determine when to
 * open/close tables based on when the 'tour' changes (for year-based view),
 * and if we passed the data to another element, it would have to iterate
 * the list again.
 */
const TourTables = ({
    userInfo, pageType, tourDates, seen, setSeen, updatePageType,
    dirty, setSetlistText, setResultsText, setError,
    searchResults, loaded,
}) => {
    console.info("Rendering TourTables", pageType)

    /*
     * Before we do anything conditional, define all our use* constants
     * which must be done always
     */
    let currentTourDates
    if (pageType.type === c.PageType.SEARCH) {
        currentTourDates = searchResults?.dates
    } else if (pageType.type === c.PageType.REPORT) {
        let currentData = tourDates[pageType.type]
        if (pageType.data) {
            currentTourDates = currentData[pageType.data]['dates']
        } else {
            currentTourDates = currentData['SELF']['dates']
        }
    } else if ([c.PageType.YEAR, c.PageType.TOUR].includes(pageType.type)) {
        currentTourDates = tourDates[pageType.type][pageType.data]
    } else {
        console.error("TourTables: Unknown pagetype", pageType.type)
    }

    const visibleIds = useMemo(() => {
        if (!currentTourDates) return []
        return Object.values(currentTourDates).map((item) => item.id)
    }, [currentTourDates])

    const allSelected = useMemo(
        () => visibleIds.length > 0 && visibleIds.every((id) => id in seen),
        [visibleIds, seen]
    )

    const handleSelectAllChange = useCallback((isChecked) => {
        setSeen(prevSeen => {
            const newSeen = { ...prevSeen };
            visibleIds.forEach(id => {
                if (isChecked) {
                    newSeen[id] = null
                } else {
                    delete newSeen[id]
                }
            })
            return newSeen
        })
    }, [visibleIds, setSeen])

    const handleSeenChange = useCallback(e => {
        const id = e.target.value
        const checked = e.target.checked
        let s = {...seen}
        if (checked) {
            s[id] = null
        } else {
            delete s[id]
        }
        setSeen(s)
    }, [seen, setSeen])

    /*
     * Now, if we haven't loaded our data yet, return a loading icon
     *
     * loaded ensures things like we have SOME search results, but not
     * OUR search results still clear the tables
     */
    if (!currentTourDates || !loaded) {
        console.log('TourTables: no data yet for', pageType['data'])
        return <Loading />
    }

    if (pageType.type === c.PageType.SEARCH &&
            Object.keys(currentTourDates).length === 0) {
        return <p><em>No matching tour dates found</em></p>
    }

    /*
     * We take in a seprate key instead of using 'tour', because in some
     * cases a tour can appear twice (when there was a few shows in the middle
     * we gave a different tour name). So we pass in a key, usually the show
     * date we're on, which is gauranteed to be unique
     */
    const renderTable = (key, tour, rows) => {
        return <TourTable
            key={key}
            {...{userInfo, pageType, tour, updatePageType}}
        >{rows}</TourTable>
    }

    const renderSelectAllRow = () => {
        return (<tr key='selectall'>
            <td><input
                type="checkbox"
                name="selectall"
                checked={allSelected}
                onChange={e => handleSelectAllChange(e.target.checked)} />
            </td>
            <td><strong>Select All</strong></td>
        </tr>)
    }

    let lastTour = null
    let tables = []
    let tableRows = []
    const tourDateKeys = Object.keys(currentTourDates).sort((a, b) => {
        const x = currentTourDates[a].date
        const y = currentTourDates[b].date
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
    })
    console.debug(tourDateKeys)
    let onlyFor = false
    if (pageType.type === c.PageType.REPORT) {
        onlyFor = pageType.data || 'SELF'
    }
    let counter = 0
    tourDateKeys.forEach((key, idx) => {
        let dateInfo = currentTourDates[key]
        if (lastTour !== dateInfo['tour']) {
            // if we have a new tour, and we have some existing rows,
            // we have a complete table we need to add to the tables array
            if (tableRows.length > 0) {
                tables.push(renderTable(key, lastTour, tableRows))
                // and clear the tableRows for the next table
                tableRows = []
            }
            lastTour = dateInfo['tour']
        }
        if (dateInfo.canceled !== 'y' && dateInfo.media_event !== 'y') {
            counter++
        }
        tableRows.push(
            <TourTableRow key={idx} {...{
                    userInfo, dateInfo, seen, handleSeenChange, updatePageType,
                    onlyFor, counter, pageType, setSetlistText, setError,
                    setResultsText,
            }} />
        )
    })

    // after the loop we'll have a table in tableRows we still need to add
    if (tableRows.length > 0) {

        /*
         * For "years" and "tour" which is the only places we add the
         * 'selectAll' button, we'll only have one table, so we can just do
         * this here on the 'last' table rather than here and also in the `if`
         * above when we cross tables
         */

        if (
            utils.includeSeenCol({skipLinks: false, userInfo, pageType}) &&
            [c.PageType.YEAR, c.PageType.TOUR].includes(pageType.type)
        ) {
            tableRows.push(renderSelectAllRow())
        }
        tables.push(renderTable('last', lastTour, tableRows))
    }

    return <>{tables}</>
}

export default TourTables
