import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const PurchasableGuide = ({itemType, updateGuide}) => {
    return (
    <>
<h2>Original vs Purchasable Picks</h2>

<p>
A variety of live picks have been reproduced in either picktins or other packs of picks for sale. This page is a guide on how to tell them apart. <strong>Unless otherwise stated, the original pick is on the RIGHT in all photos!</strong></p>

<p className="center">
<a href="#awmh">AWMH</a> | <a href="#whitefangs">White Fangs</a> | <a href="#bingeandpurge">Binge &amp; Purge</a> | <a href="#blackvulturus">Black Vulturus</a> | <a href="#whitevulturus">White Vulturus</a> | <a href="#metsux">Metallica Sux</a></p>

<h3 id="awmh">AWMH</h3>

<p>
The AWMH tin includes a variety of All Within My Hands picks. We&lsquo;ve been able to find several subtle differences between the originals and the tin variety. We&lsquo;ll start with the 2018 pick. You can click to enlarge.
</p>

<table align="center">
<tr>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-18-front-compare-1.png" thumb="/images/pickinfo/picktins/awmh-18-front-compare-1--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-18-back-compare-1.png" thumb="/images/pickinfo/picktins/awmh-18-back-compare-1--thumb.png" />
</td>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-18-front-compare-2.png" thumb="/images/pickinfo/picktins/awmh-18-front-compare-2--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-18-back-compare-2.png" thumb="/images/pickinfo/picktins/awmh-18-back-compare-2--thumb.png" />
</td>
</tr>
</table>

<p>
You can see right away the colors are much richer on the picks from the tin. In addition, if you look closely you will see the writing is also crisper and cleaner on the tin version. Here&lsquo;s a close-up of &quot;within&quot; and &quot;hands&quot; on the above picks.</p>

<table align="center">
<tr>
<td>
    <img src="/images/pickinfo/picktins/awmh-18-front-compare-text-within-1.png" alt="front comparing text: within" /><br/>
    <img src="/images/pickinfo/picktins/awmh-18-front-compare-text-hands-1.png" alt="front comparing text: hands" />
</td>
<td>
    <img src="/images/pickinfo/picktins/awmh-18-front-compare-text-within-2.png" alt="front comparing text: within (2)"/><br/>
    <img src="/images/pickinfo/picktins/awmh-18-front-compare-text-hands-2.png" alt="front comparing text: hands (2)"/>
</td>
</tr>
</table>

<p>
It&lsquo;s harder to find differences on the back, but you can see the same effect with the &quot;SF&quot; on the bottom.</p>

<table align="center">
<tr>
<td>
    <img src="/images/pickinfo/picktins/awmh-18-back-compare-text-sf-1.png" alt="back comparing text: sf" /><br/>
    <img src="/images/pickinfo/picktins/awmh-18-back-compare-text-sf-2.png" alt="back comparing text: sf (2)" />
</td>
</tr>
</table>

<p>
In both cases, you can see the edges of the &quot;S&quot; and &quot;F&quot; are cleaner on the tin version.</p>

<p>
The 2020 pick is far easier to find differences on. You can click to enlarge.</p>

<table align="center">
<tr>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-20-front-compare-1.png" thumb="/images/pickinfo/picktins/awmh-20-front-compare-1--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-20-back-compare-1.png" thumb="/images/pickinfo/picktins/awmh-20-back-compare-1--thumb.png" />
</td>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-20-front-compare-2.png" thumb="/images/pickinfo/picktins/awmh-20-front-compare-2--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-20-back-compare-2.png" thumb="/images/pickinfo/picktins/awmh-20-back-compare-2--thumb.png" />
</td>
</tr>
</table>

<p>
You can see right away that the picks from the tin have a more golden ink where as the tour picks have a more silver ink.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/awmh-20-front-compare-text-foundation-1.png" alt="front comparing text: foundation" /><br />
<img src="/images/pickinfo/picktins/awmh-20-front-compare-text-foundation-2.png" alt="front comparing text: foundation (2)" />
</td>
</tr>
</table>

<p>
This is especially prounounced on the back.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/awmh-20-back-compare-text-date-1.png" alt="back comparing text: date" /><br/>
<img src="/images/pickinfo/picktins/awmh-20-back-compare-text-date-2.png" alt="back comparing text: date (2)" />
</td>
</tr>
</table>

<p>
Moving onto the 2022 pick which is not as obvious. Also, I didn&lsquo;t two different tour picks to compare with, so 2 tins are on the outside with the tour pick in the middle. As always, click to enlarge.</p>

<table align="center">
<tr>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-22-front-compare.png" thumb="/images/pickinfo/picktins/awmh-22-front-compare--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-22-back-compare.png" thumb="/images/pickinfo/picktins/awmh-22-back-compare--thumb.png" />
</td>
</tr>
</table>

<p>
The image is higher on the tour pick, but since we only have one, it&lsquo;s hard to know if that&lsquo;s consistent or not. There are two things that seem to stand out as different. The first is that the blue color in the hand is noticeably darker. This is the hand on the front and back respectively.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/awmh-22-front-compare-color-hand.png" alt="front comparing color: hand" /><br/>
<img src="/images/pickinfo/picktins/awmh-22-back-compare-color-hand.png" alt="back comparing color: hand" />
</td>
</tr>
</table>

<p>
The other is that the text on the purchased picks, once again, is cleaner. You can see this on the edge of the &quot;M&quot; on the front - it&lsquo;s bleeding much more on the tour pick than the tin picks, and same for the date on the back.</p>

<table align="center">
<tr>
<td align="center">
<img src="/images/pickinfo/picktins/awmh-22-front-compare-text-m.png" alt="front comparing text: m" /><br/>
<img src="/images/pickinfo/picktins/awmh-22-back-compare-text-date.png" alt="back comparing text: date" />
</td>
</tr>
</table>

<p>
Finally we have the AWMH promo pick. These are very easy to tell apart.</p>

<table align="center">
<tr>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-promo-front-compare-1.png" thumb="/images/pickinfo/picktins/awmh-promo-front-compare-1--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-promo-back-compare-1.png" thumb="/images/pickinfo/picktins/awmh-promo-back-compare-1--thumb.png" />
</td>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-promo-front-compare-2.png" thumb="/images/pickinfo/picktins/awmh-promo-front-compare-2--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/awmh-promo-back-compare-2.png" thumb="/images/pickinfo/picktins/awmh-promo-back-compare-2--thumb.png" />
</td>
</tr>
</table>

<p>
There&lsquo;s almost no reason to do any zoom in on these picks as almost every single part of these picks is obviously different. The original promo picks are just much less clean lines: they have significant bleed to the point where the image appear to be blurry. We&lsquo;ll take an example anyway. On the front, look at &quot;THE&quot; as an example.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/awmh-promo-front-compare-text-the-1.png" alt="front comparing text: 1" /><br/>
<img src="/images/pickinfo/picktins/awmh-promo-front-compare-text-the-2.png" alt="front comparing text: 2" />
</td>
</tr>
</table>

<p>
Or on the back, &quot;allwithinmyhands&quot; is a great example.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/awmh-promo-back-compare-text-allwithin-1.png" alt="back comparing text: all within" /><br/>
<img src="/images/pickinfo/picktins/awmh-promo-back-compare-text-allwithin-2.png" alt="back comparing text: all within (2)" />
</td>
</tr>
</table>

<p>
As you can see, the picks from the picktin are noticeably different from their originals. We hope this will help you to collect safely!</p>


<h3 id="whitefangs">White Fangs</h3>

<p>
White Fangs are now being mass produced for sale in both the 1.0 and 1.14 varieties. They are denoted as different from the tour versions by an apotrophe between &quot;Hetfield&quot; and the &quot;s&quot; as you can see below. However, people sometimes scratch the apostrophe off, so how can you tell if a white fang is purchased or from tour? Here we&lsquo;ll have a look at what other differences there are. First, lets just see them next to each other - click to zoom in.</p>

<table align="center">
<tr>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/white_fang_1.14_front_compare.png" thumb="/images/pickinfo/picktins/white_fang_1.14_front_compare--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/white_fang_1.14_back_compare.png" thumb="/images/pickinfo/picktins/white_fang_1.14_back_compare--thumb.png" />
</td>
<td>
<SimpleItemImage full="/images/pickinfo/picktins/white_fang_1.0_front_compare.png" thumb="/images/pickinfo/picktins/white_fang_1.0_front_compare--thumb.png" /><br/>
<SimpleItemImage full="/images/pickinfo/picktins/white_fang_1.0_back_compare.png" thumb="/images/pickinfo/picktins/white_fang_1.0_back_compare--thumb.png" />
</td>
</tr>
</table>

<p>
I spent a lot of time looking at these, and the first thing you notice is that the font on the numbers is thinner on tour picks.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/white_fang_1.14_number_compare.png" alt="number comparison" /><br/>
</td>
<td>
<img src="/images/pickinfo/picktins/white_fang_1.0_number_compare.png" alt="front comparing color: hand" />
</td>
</tr>
</table>

<p>
Another thing that you can see here is the tour picks are a slightly darker color in both cases. It also turns out the font for &quot;Metallica&quot; is a bit cleaner in the tour version as well.</p>

<table align="center">
<tr>
<td>
<img src="/images/pickinfo/picktins/white_fang_1.14_m_compare.png" alt="M comparison" /><br/>
</td>
<td>
<img src="/images/pickinfo/picktins/white_fang_1.0_m_compare.png" alt="M comparison" />
</td>
</tr>
</table>

<p>
It&lsquo;s impossible to say if tour ones will continue to be like this or converge on the purchased ones, but for now, these are the differences I can find.</p>

<h3 id="bingeandpurge">Binge &amp; Purge Pick Tin</h3>

<p>
This pick tin has several reproductions of old 90s picks. All the picks appear to be the same thickness as their original counterparts, but there are other differences.</p>

<p>
The two bass picks (blue and purple) have the same characteristics... the &quot;Metallica&quot; logo is further down the pick, much cleaner, and much shinier than the originals.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225428.jpg" thumb="/images/pickinfo/picktins/20150911_225428--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20151009_000613.jpg" thumb="/images/pickinfo/picktins/20151009_000613--thumb.jpg" />
</p>

<p>
Similarly the yellow snake pick has its Metallica logo lower, cleaner, and shinier than the original. In addition the pick tin snake is smaller and uses thinner lines.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225806.jpg" thumb="/images/pickinfo/picktins/20150911_225806--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225841.jpg" thumb="/images/pickinfo/picktins/20150911_225841--thumb.jpg" />
</p>

<p>
For the green scary guy pick the logos are much more similar, and the only difference we&lsquo;ve been able to determine is that the ink is shinier. Note: since we don&lsquo;t have an original scary guy pick, we&lsquo;re comparing the logo to other original green picks of the same timeframe, but cannot compare the scary guy side.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225614.jpg" thumb="/images/pickinfo/picktins/20150911_225614--thumb.jpg" />
</p>

<p>
The Gimme Fuel Flags pick is in a simialr boat, it&lsquo;s very difficult to tell except for slightly shinier ink.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225939.jpg" thumb="/images/pickinfo/picktins/20150911_225939--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225952.jpg" thumb="/images/pickinfo/picktins/20150911_225952--thumb.jpg" />
</p>

<p>
And finally the XXX is a somewhat easier to tell because the shiny ink in the Xs make all the &quot;scratches&quot; look like a large big line.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_230118.jpg" thumb="/images/pickinfo/picktins/20150911_230118--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_230132.jpg" thumb="/images/pickinfo/picktins/20150911_230132--thumb.jpg" />
</p>

<h3 id="blackvulturus">Black Vulturus Pick Tin</h3>

<p>
This pick tin contains 2 general types of picks: The Squindo Cartoon Head picks (James, Lars, Kirk, and Rob), and the 2 of the &quot;30 years&quot; picks (white &quot;The First 30 Years&quot; and black &quot;30 Years Strong&quot;) - all with &quot;Metallica XXX&quot; on the back).</p>

<p>
The Squindo Cartoon heads and the white &quot;The First 30 Years&quot; are all thinner than their live counterparts. You can see that here - there are two originals on the right and the pick tin version on the left in these photos.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_215010.jpg" thumb="/images/pickinfo/picktins/20150911_215010--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_215114.jpg" thumb="/images/pickinfo/picktins/20150911_215114--thumb.jpg" />
</p>

<p>
Further, on you see they are a slightly different shape (pick tin is on top in the first photo).</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_214231.jpg" thumb="/images/pickinfo/picktins/20150911_214231--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_214340.jpg" thumb="/images/pickinfo/picktins/20150911_214340--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_214513.jpg" thumb="/images/pickinfo/picktins/20150911_214513--thumb.jpg" />
</p>

<p>
The black &quot;30 Years Strong&quot; pick is absolutely identical as far as we can tell.</p>


<h3 id="whitevulturus">White Vulturus Pick Tin</h3>

<p>
This tin has the Squindo Cartoon Skull Head picks (the skull version of the picks in the Black Vulturus Pick Tin) as well as the same white &quot;The First 30 Years&quot; and black &quot;30 Years Strong&quot; picks that the Black Vulturus Pick Tin has. Since those 2 &quot;30 years&quot; picks are the same across both tins, we&lsquo;ll only cover the Skull Head picks here.</p>

<p>
These picks are absolutely identical in all ways except that they are thinner.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225155.jpg" thumb="/images/pickinfo/picktins/20150911_225155--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225122.jpg" thumb="/images/pickinfo/picktins/20150911_225122--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_225037.jpg" thumb="/images/pickinfo/picktins/20150911_225037--thumb.jpg" />
</p>


<h3 id="metsux">Metallica Sux Pick Tin</h3>

<p>
This pick tin has the USA and Europe &ltquo;09 &lsquo;Tallica Sux picks and also a set of Dirty Donny Voodoo cartoon picks.</p>

<p>
The &lsquo;Tallica Sux picks are fairly easy to spot as the ink is much, much lighter, the picks are thinner, and a slightly different shape than their live counterparts. Again, picktin version is on top in the stacked photos.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_220036.jpg" thumb="/images/pickinfo/picktins/20150911_220036--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_220054.jpg" thumb="/images/pickinfo/picktins/20150911_220054--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_220158.jpg" thumb="/images/pickinfo/picktins/20150911_220158--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_215841.jpg" thumb="/images/pickinfo/picktins/20150911_215841--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_220454.jpg" thumb="/images/pickinfo/picktins/20150911_220454--thumb.jpg" />
</p>

<p>
The Dirty Donny Voodoo Cartoon ones are a bit harder. They are the same thickness as the ones above, and like others, they are a slightly different shape.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224003.jpg" thumb="/images/pickinfo/picktins/20150911_224003--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224147.jpg" thumb="/images/pickinfo/picktins/20150911_224147--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224336.jpg" thumb="/images/pickinfo/picktins/20150911_224336--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224501.jpg" thumb="/images/pickinfo/picktins/20150911_224501--thumb.jpg" />
</p>

<p>
In addition the ink is a slightly lighter shade. Except Kirk who is an entirely different color!</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224218.jpg" thumb="/images/pickinfo/picktins/20150911_224218--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224224.jpg" thumb="/images/pickinfo/picktins/20150911_224224--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224318.jpg" thumb="/images/pickinfo/picktins/20150911_224318--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/picktins/20150911_224435.jpg" thumb="/images/pickinfo/picktins/20150911_224435--thumb.jpg" />
</p>
    </>
    )
}

export default PurchasableGuide
