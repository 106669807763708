import React, { Fragment } from 'react'

import * as utils from './collectionUtils.jsx'

import ItemImage from './ItemImage.jsx'

const CollectionSaveResults = ({
    data, allItems, has_sets, pageType, itemType,
}) => {
    /* First let's see if we have preferences differences */
    let msg = <p><strong>Save complete!</strong></p>

    let arr = []
    for (var id in data.changed) {
        let item = allItems[pageType][id]
        let info = data.changed[id]

        let first_field = item.year
        if (has_sets) {
            first_field = item.tin || item.year
        }
        arr.push(<Fragment key={`result${id}`}>
            <tr>
                <td>{first_field}</td>
                <td>{item.type}</td>
                <td>{item.color}</td>
                <td>{item.front}</td>
                <td>{item.back}</td>
                <td>{item.note}</td>
                <ItemImage
                    haveImage={ item.have_image }
                    id={ id }
                    linkify={ false }
                    itemType={itemType}
                    variety={item.variety}
                />
                <td>{info['have']['old']}<br/><span className="incoming_label">+ {info['incoming']['old']} incoming</span></td>
                <td>{info['have']['new']}<br/><span className="incoming_label">+ {info['incoming']['new']}</span></td>
            </tr>
        </Fragment>)
    }

    if (arr.length > 0) {
        msg = <>{msg}<p>Your collection has been updated as follows:</p></>
        let first_header = "YEAR"
        if (has_sets) {
            first_header = "YEAR/SET"
        }
        msg = <Fragment>
            {msg}
            <div id='saveresults' className="table-hscroll-container">
                <table width="100%">
                    <tr>
                    <th>{first_header}</th>
                    <th>TYPE</th>
                    <th>COLOR</th>
                    <th>FRONT</th>
                    <th>BACK</th>
                    <th>DETAIL</th>
                    <th>PICTURE</th>
                    <th>OLD</th>
                    <th>NEW</th>
                    </tr>
                    {arr}
                </table>
            </div>
        </Fragment>
    }
    if (Object.keys(data.changed_prefs).length > 0) {
        console.debug("We have changed preferences...")
        let arr = Object.keys(data.changed_prefs).map((pref) => {
            console.debug(` -> ${pref}`)
            return <li key={`pref_status ${pref}`}>{utils.prefToDisplay(itemType, pref)}: {data.changed_prefs[pref]['old']} -&gt; {data.changed_prefs[pref]['new']}</li>
        })
        msg = (
                <Fragment>
                    {msg}
                    <p>Your preferences have been updated as follows:</p>
                    <ul>{arr}</ul>
                </Fragment>
        )
    }
    return msg
}

export default CollectionSaveResults
