import React from 'react'

import * as c from './collectionConstants.jsx'

const GuideLink = ({guide, updateGuide, ...props}) => {
    let url = `/app/picks?pagetype=${c.PageType.GUIDES}&guide=${guide}`
    return (
        <a href={url} onClick={e => updateGuide(e, guide)}>{props.children}</a>
    )
}

export default GuideLink
