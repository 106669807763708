import React, { useState, useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import * as ipom from '../common.jsx'
import Loading from '../Loading.jsx'
import Error from '../Error.jsx'
import Member from '../Member'

import * as c from './collectionConstants.jsx'
import TradeSuggestionsTable from './TradeSuggestionsTable.jsx'

const TradeSuggestions = ({
    itemType, userInfo, multiPageType, loaded, setLoaded, pageType,
    updatePageType, updateView, otherId, setOtherId, id2, setId2,
    otherEmail, setOtherEmail, otherInfo, setOtherInfo,
    setStatusText, switchToList, solicitHelp,
}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const [error, setError] = useState(false)
    const [allItems, setAllItems] = useState({})
    const [rareness, setRareness] = useState([])
    const [toGive, setToGive] = useState([])
    const [toReceive, setToReceive] = useState([])
    const [memberExtra, setMemberExtra] = useState({})
    const [memberMissing, setMemberMissing] = useState({})
    const [minDisplay, setMinDisplay] = useState(window.innerWidth <= 800)
    const [showAllNeeded, setShowAllNeeded] = useState(false)

    console.info("Rendering TradeSuggestions", otherId, id2)

    const getTradeSuggestions = useCallback(({itemType, userInfo}) => {
        console.debug('getTradeSuggestions: entered')
        if (!userInfo) {
            console.debug("getTradeSuggestions: bailing out, no userInfo yet")
            return
        }
        if (!otherId) {
            console.debug("getTradeSuggestions: bailing out, no otherId yet")
            return
        }
        console.debug("getTradeSuggestions: continuing...")
        // first, determine which ID is not ours, keeping in mind,
        // there might not be an id2
        let compareId
        if (otherId !== userInfo.id) {
            compareId = otherId
        } else if (id2 && id2 !== userInfo.id) {
            compareId = id2
        }
        let script = `collections_trade_suggestions_values.pl`
        let args = `item_type=${itemType}&id=${compareId}&`
        args = args + `all_needed=${showAllNeeded}`
        let url = `${ipom.API_ENDPOINT}/cgi-bin/${script}?${args}`
        fetch(url)
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setError(i.error_message)
                    return
                }
                setAllItems(i.results.all_items)
                setToGive(i.results.to_give)
                setToReceive(i.results.to_receive)
                setMemberExtra(i.results.member_extra)
                setMemberMissing(i.results.member_missing)
                setOtherId(i.results.other_id)
                setOtherInfo({
                    displayName: i.results.other_member.display_name,
                    numBadges: i.results.other_member.num_badges,
                })
                setOtherEmail(i.results.other_member.email)
                setRareness(i.results.rareness)
                setLoaded(true)
            })
            .catch(err => console.error(`Failed to get ${itemType} trades:`, err))
    }, [setLoaded, id2, otherId, setOtherId, setOtherInfo, setOtherEmail, showAllNeeded])

    useEffect(() => {
        /*
         * we will intelligently figure out which ID to pull the information
         * for, but we want 'otherId' to be ... well, the OTHER id,
         * so if it's backwards, swap it.
         */
        console.debug("Running effect to set URL properly")
        if (!userInfo || !otherId) {
            return
        }
        let urlParams = new URLSearchParams(location.search)
        let urlId = urlParams.get('id')
        let urlId2 = urlParams.get('id2')
        console.debug("PHIL, id, id2, otherid, ourId",
            urlId,
            urlId2,
            otherId,
            userInfo.id
        )
        if ((urlId === otherId && urlId2 === userInfo.id) ||
            (urlId === userInfo.id && urlId2 === otherId)) {
            console.debug("URL has correct IDs...")
            return
        }
        console.debug("URL does not have correct IDs...")
        urlParams.set('id', otherId)
        urlParams.set('id2', userInfo['id'])
        ipom.urlReplaceSearch(urlParams, navigate)
    }, [otherId, userInfo, navigate, location.search])

    useEffect(() => {
        console.debug("Running effect to get trade info")
        getTradeSuggestions({...{itemType, userInfo}})
    }, [itemType, userInfo, getTradeSuggestions])

    const toggleMinDisplay = (e) => {
        setMinDisplay(e.target.checked)
    }

    const toggleShowAllNeeded = (e) => {
        setShowAllNeeded(e.target.checked)
    }

    if (error) {
        return <Error {...{error}} />
    }

    if (!userInfo || !otherId || !loaded) {
        return <Loading />
    }

    return (
        <div>
            <p>Welcome to IPOM&lsquo;s Suggested Pick Trades! These are all the possible trades you and <Member badges={otherInfo.numBadges} memberInfo={{id: otherId, displayName: otherInfo.displayName}} /> can make. If you want to setup a trade, feel free to <a href={`mailto:${otherEmail}?subject=Metallica Pick Trade&body=Hi ${otherInfo.displayName},%0A%0AAccording to your IPOM list, we have some picks we can trade. Here's the list, let me know if you're interested in a trade!%0A%0Ahttps://www.ipom.com/app/picks?pagetype=${c.PageType.TRADE_SUGGESTIONS}%26id=${userInfo['id']}%26id2=${ otherId }%0A%0A- ${userInfo['display_name']}`}>send them a message</a>. You can also <a href={`/app/picks?id=${otherId}`} onClick={(e) => switchToList(e, otherId)}>checkout their full list</a>.</p>
            <p>We hope this helps facilitate more trades and better collections. Enjoy!</p>
            <p className="center">
                <strong>Options</strong>:&nbsp;
                <input id="min_display" type="checkbox" onChange={toggleMinDisplay} checked={minDisplay} />&nbsp;
                <span>Minimize table (for mobile viewing)</span>&nbsp;|&nbsp;
                <input id="all_needed" type="checkbox" onChange={toggleShowAllNeeded} checked={showAllNeeded} />&nbsp;
                <span>Do not limit to {otherInfo.displayName}&lsquo;s extras (e.g. user is selling their collection)</span>
            </p>
            <TradeSuggestionsTable
                {...{itemType, allItems, toGive, toReceive, memberExtra,
                    memberMissing, otherInfo, setStatusText, pageType,
                    rareness, minDisplay, switchToList, solicitHelp}} />
        </div>
    )
}

export default TradeSuggestions
