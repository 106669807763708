import React from 'react'
import { Link } from 'react-router-dom'

const AdminLink = ({s, updatePageType, children}) => {
    return <Link
        to={`/app/admin?pagetype=${s}`}
        onClick={() => updatePageType(s)}
    >{children}</Link>
}

export default AdminLink
