import React from 'react'

import Loading from '../Loading.jsx'
import Member from '../Member.jsx'

import { userListUrl, userTradeUrl } from './collectionUtils.jsx'

const TradeCenterTable = ({
    itemType, userInfo, memberInfo, updatePageType, updateView, setOtherId,
    setId2, switchToList, switchToSuggest,
}) => {
    console.info("Rendering TraceCenterTable")

    if (!memberInfo) {
        return <Loading />
    }

    let rows = memberInfo.map(member => {
        let last_row = (
            <td>
				{ member['trades'] > 0 ?  `Yes (${member['trades']})` : "No"}
				&nbsp;| <a href={userTradeUrl(itemType, member['id'])} onClick={(e) => switchToSuggest(e, member['id'])}>Trades</a>
				&nbsp;| <a href={userListUrl(itemType, member['id'])} onClick={(e) => switchToList(e, member['id'])}>List</a>
            </td>
        )
        return (
            <tr key={member['id']}>
                <td>
                    <Member badges={member.num_badges}
                        memberInfo={{id: member.id, displayName: member.handle}} />
                </td>
                <td>{member['needs']}</td>
                <td>{member['spares']}</td>
                {last_row}
            </tr>
        )
    })

    return (
        <>
            <table className="items" style={{width: "75%"}}>
                <thead>
                    <tr>
                        <th>Member</th>
                        <th>Needs</th>
                        <th>Spares</th>
                        <th>Possible Trades</th>
                    </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
            {rows.length === 0 && <p className="center">Sorry, no public users, yet.</p>}
        </>
    )
}

export default TradeCenterTable
