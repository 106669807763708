import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const StAngerGuide = ({itemType, updateGuide}) => {

    return (
    <>
<h1>St. Anger Pick Breakdown</h1>

<p>
The black St. Anger picks have some subtle variants. Like their white counterparts, there are ones where the Metallica logo has orange text / red outlines and others with red text / orange outlines. On the white ones, the other side matches.</p>

<div className="center">
<SimpleItemImage full="/images/picks/66.png" thumb="/images/picks/66--thumb.png" />
</div>

<p>
On the black ones, however, the story is a bit different. For the picks where the logo has orange text and a red outline, there are three varients of the &quot;St. Anger&quot; text: light orange, dark orange, and orange with a red outline.</p>

<div className="center">
<SimpleItemImage full="/images/picks/1039.png" thumb="/images/picks/1039--thumb.png" />
<SimpleItemImage full="/images/picks/67.png" thumb="/images/picks/67--thumb.png" />
<SimpleItemImage full="/images/picks/1040.png" thumb="/images/picks/1040--thumb.png" />
</div>

<p>
At first, I thought that light and dark orange varieties were just worn-out versions of each other. So I started with a scan of all of them grouped together.</p>

<div className="center">
<SimpleItemImage full="/images/pickinfo/stanger/all-stanger.png" thumb="/images/pickinfo/stanger/all-stanger--thumb.png" />
</div>

<p>
Then I adjusted the gamma, brightness, and contrast on my scanner to try to get a better view.</p>

<div className="center">
<SimpleItemImage full="/images/pickinfo/stanger/all-stanger-enhanced.png" thumb="/images/pickinfo/stanger/all-stanger-enhanced--thumb.png" />
</div>

<p>
At this point it seemed a bit like &quot;dark orange&quot; was just chipped away &quot;light orange&quot;, so I took a close-ups of the text and put them next to each other so I could compare the areas where the ink was still thick.</p>

<div className="center">
<img src="/images/pickinfo/stanger/stanger-text-closeup.png" alt="st. anger text closeup" />
</div>

<p>
This wasn&lsquo;t definitive. The ink looks <em>slightly</em> different, but not enough to be sure. However, where the ink was scratched off is <em>very</em> different, and tha twas enough to convince me. In the following two images, the left is the middle &quot;bar&quot; of the &quot;E&quot; (where there&lsquo;s lots of ink in all 3 images), and the right image is the top &quot;bar&quot; of the &quot;E&quot; (where at least for &quot;light&quot; and &quot;dark&quot; the ink is mostly scratched off).</p>

<div className="center">
<img src="/images/pickinfo/stanger/stanger-ink-closeup.png" alt="ink closeup" />
&nbsp;
<img src="/images/pickinfo/stanger/stanger-scratchoff-closeup.png" alt="scratch-off closeup" />
</div>

<p>
This showed me that there are in fact three different varieties.</p>
    </>
    )
}

export default StAngerGuide
