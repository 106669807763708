import React, { useState, Fragment, useRef, useEffect  } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import * as ipom from '../common.jsx'
import StatusBox from '../StatusBox.jsx'
import Loading from '../Loading.jsx'

import * as c from './collectionConstants.jsx'
import CollectionForm from './CollectionForm.jsx'
import CollectionTour from './CollectionTour.jsx'
import CollectionPageHeader from './CollectionPageHeader.jsx'
import TradeCenter from './TradeCenter.jsx'
import TradeSuggestions from './TradeSuggestions.jsx'
import CollectionGuides from './CollectionGuides.jsx'
import CollectionWantlist from './CollectionWantlist.jsx'
import PickThanks from './PickThanks.jsx'
import { ItemTotalsProvider } from './ItemTotalsContext'

import './CollectionPage.css'

/*
 * Top level of collections. Handles pageType and view switches
 * (including fwd/back button handling, scroll-to-link
 * adjustments, and the overall Collections common elements
 * like building the top menu and tour
 */
const CollectionPage = ({itemType, userInfo, multiPageType, solicitHelp}) => {
    const location = useLocation()
    const navigate = useNavigate()
    let urlParams = ipom.getUrlSearchParams(location)
    const [otherId, setOtherId] = useState(parseInt(urlParams.get('id')))
    const [otherInfo, setOtherInfo] = useState()
    const [id2, setId2] = useState(parseInt(urlParams.get('id2')))
    const [otherEmail, setOtherEmail] = useState(urlParams.get('email'))
    const [view, setView] = useState(
        c.View[urlParams.has('id') || urlParams.has('email') ? 'OTHER' : 'SELF']
    )
    const [statusText, setStatusText] = useState(null)
    const [resultsText, setResultsText] = useState(null)
    const [loaded, setLoaded] = useState(false)
    const [startTour, setStartTour] = useState(false)
    const [pageType, setPageType] = useState(
        urlParams.get('pagetype') || c.PageType.OFFICIAL
    )
    const [dirty, setDirty] = useState(false)
    const [guide, setGuide] = useState(urlParams.get('guide'))

    const scrolledRef = useRef(false)

    const pageTypeRef = useRef(pageType);
    const otherIdRef = useRef(otherId);
    const otherEmailRef = useRef(otherEmail);
    const viewRef = useRef(view);

    const hash = location.search.hash
    console.info(`Rendering CollectionPage: view is ${view}, multiPageType is ${multiPageType}, pageType is ${pageType}`)

    const updatePageType = (e, type, resetView = false) => {
        console.debug("updatePageType: resetView is", resetView)
        e.preventDefault()

        console.debug("updatePageType: checking if dirty", dirty)
        if (dirty) {
            console.log("updatePageType: page IS dirty")
            if (window.confirm("You have unsaved changes, are you sure you " +
                "want to navigate away from this page?") === false) {
                return
            }
            // Well, data is lost now, so don't prompt them again
            setDirty(false)
        }
        console.debug("updatePageType: page is NOT dirty")

        let urlParams = ipom.getUrlSearchParams(location)
        let view = c.View.SELF
        if (urlParams.has('id') || urlParams.has('email')) {
            view = c.View.OTHER
        }
        let expectedView = view
        if (resetView) {
            expectedView = c.View.SELF
        }

        /*
         * If you leave the Guides, a guide= arg can stick
         * around, and then going back to guides puts you in a specific
         * guide, which isn't what we want.
         */
        if (type !== c.PageType.GUIDES && urlParams.get('guide')) {
            urlParams.delete('guide')
            setGuide(null)
        }

        urlParams.set('pagetype', type)
        if (type === pageType && view === expectedView) {
            // setting the state will cause PickTable to re-render, even if it's
            // to the same value, so don't bother. Just set the URL
            // and return
            console.debug("updatePageType: type is correct, just setting URL")
            ipom.urlReplaceSearch(urlParams, navigate)
            return null
        }
        console.debug(`updatePageType: Changing pageType from ${pageType} to ${type}`)
        ipom.urlPushSearch(urlParams, navigate)

        if (resetView) {
            console.debug('updatePageType: Resetting view')
            // this updates the URL as well
            updateView(e, c.View.SELF)
        }

        // prevent page from half-loading
        setLoaded(false)
        setPageType(type)
    }

    /*
     * From TradeCenter or TradeSuggest to someone's list
     */
    const switchToList = (e, member_id) => {
        e.preventDefault()
        updateView(e, member_id)
        setId2(null)
        let urlParams = ipom.getUrlSearchParams(location)
        urlParams.delete('id2')
        ipom.urlReplaceSearch(urlParams, navigate)
        updatePageType(e, c.PageType.OFFICIAL)
    }

    const switchToSuggest = (e, member_id) => {
        e.preventDefault()
        console.debug(`Setting otherID to ${member_id}`)
        setOtherId(member_id)
        updatePageType(e, c.PageType.TRADE_SUGGESTIONS)
    }

    const switchToGuide = (e, guide) => {
        e.preventDefault()
        setGuide(guide)
        updatePageType(e, c.PageType.GUIDES)
    }

    /*
     * Scroll to the right item of we were linked to a specific one.
     * Normal #<foo> links don't work because the content isn't loaded yet
     * Lifted (with several modifications) from:
     * https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34
     */
    useEffect(() => {
        if (!loaded) {
            return () => {}
        }
        if (hash && !scrolledRef.current) {
            const id = hash.replace('#', '');
            let element = document.getElementById(id);
            if (!element) {
                /* Or we're linking to an a-name, try our best here */
                element = document.getElementsByName(id)[0]
            }
            if (element) {
                /*
                 * Let images and stuff all finish loading with an extra
                 * 750ms, otherwise we sometimes scroll to the wrong thing
                 */
                setTimeout(() => {
                    element.scrollIntoView(
                        { behavior: 'smooth', block: 'start', inline: 'start' }
                    )
                }, 750)
                scrolledRef.current = true;
            }
        }
    }, [loaded, hash])

    /* Start the tour just after everything's loaded */
    useEffect(() => {
        if (!loaded) {
            return () => {}
        }

        let key, legacyKey
        if (pageType === c.PageType.TRADE_SUGGESTIONS) {
            key = c.StorageKeys.TRADE_SUGGEST_TOUR_KEY
            legacyKey = null
        } else {
            key = c.StorageKeys.COLLECTION_TOUR_KEY
            legacyKey = c.StorageKeys.LEGACY_TOUR_KEY
        }

        let need_tour = true
        if (localStorage.getItem(key) === "1") {
            need_tour = false
        } else if (legacyKey && localStorage.getItem(legacyKey) === "1") {
            /* back-compat, move to new name */
            localStorage.setItem(key, "1")
            need_tour = false
        }

        if (need_tour) {
            setTimeout(() => {
                setStartTour(true)
            }, 750)
        }
    }, [loaded, pageType])

    useEffect(() => {
        console.debug("In History effect")
        const action = location.state?.action || 'POP'
        const logaction = action === 'POP' ? 'Processing' : 'Ignoring'
        console.debug(
            `HistoryListener: ${logaction} have buttons used, location: ` + 
            `${JSON.stringify(location)}, action: ${action}`
        )
        if (action !== 'POP') {
            return
        }
        let urlParams = ipom.getUrlSearchParams(location)
        let newType = urlParams.get('pagetype') || c.PageType.OFFICIAL
        let newView = (urlParams.has('id') || urlParams.has('email')) ?
            c.View.OTHER : c.View.SELF
        let newId = urlParams.get('id')
        let newEmail = urlParams.get('email')
        /*
         * For reasons I cannot quite fathom, if we go from
         * the TradeCenter list (PT=Trade) to someone's list,
         * and then come back, the id= sticks around on the URL,
         * but it makes no sense on trade center and makes the title
         * wrong. So, we clear it, and the display name
         */
        if (newType === c.PageType.TRADE) {
            newView = c.View.SELF
            newId = null
            newEmail = null
            urlParams.delete('id')
            urlParams.delete('email')
            setOtherInfo(null)
            /*
             * don't change the URL unless we have to, otherwise
             * we cause an infinite loop
             */
            const newSearch = '?' + urlParams.toString()
            if (location.search !== newSearch) {
                ipom.urlReplaceSearch(urlParams, navigate)
            }
        }

        /*
         * NOTE:
         * CANNOT USE updateView which updates the URL which triggers
         * us again!
         */
        if (newType !== pageTypeRef.current) {
            console.debug(
                `HistoryListener: Setting PT ${pageTypeRef.current} -> ${newType}`
            )
            pageTypeRef.current = newType
            setPageType(newType)

        }
        if (newId !== otherIdRef.current) {
            console.debug(`HistoryListener: Setting ID ${otherIdRef.current} -> ${newId}`)
            otherIdRef.current = newId
            setOtherId(newId)
        }
        if (newEmail !== otherEmailRef.current) {
            console.debug(
                `HistoryListener: Setting Email ${otherEmailRef.current} -> ${newEmail}`
            )
            otherEmailRef.current = newEmail
            setOtherEmail(newEmail)
        }
        if (newView !== viewRef.current) {
            console.debug(`HistoryListener: Setting View ${viewRef.current} -> ${newView}`)
            setLoaded(false)
            viewRef.current = newView
            setView(newView)
        }
    },
        [
            location, navigate/*, setLoaded, setView, setOtherEmail, setOtherId,*/
        ]
    )

    useEffect(() => {
            pageTypeRef.current = pageType
            otherIdRef.current = otherId
            otherEmailRef.current = otherEmail
            viewRef.current = view
    }, [pageType, otherId, otherEmail, view])

    /*
     * Update all state and URL params for a new view
     */
    function updateView(e, newview) {
        if (e) {
            e.preventDefault()
        }
        if (newview === view) {
            console.debug("updateView: nothing to do, returning")
            return null
        }
        urlParams = ipom.getUrlSearchParams(location)
        let v = newview
        if (newview === c.View.SELF) {
            // If moving to self, nuke any variables about 'other'
            urlParams.delete('id')
            setOtherId(null)
            urlParams.delete('email')
            setOtherEmail(null)
        } else if (!isNaN(parseFloat(newview))) {
            // if we're an ID, set the ID, remove any email that was there
            urlParams.set('id', newview)
            setOtherId(newview)
            urlParams.delete('email')
            setOtherEmail(null)
            v = c.View.OTHER
        } else {
            // if we're an email, set the email, remove any ID that was there
            urlParams.set('email', newview)
            setOtherEmail(newview)
            urlParams.delete('id')
            setOtherId(null)
            v = c.View.OTHER
        }
        ipom.urlPushSearch(urlParams, navigate)
        setView(v)
        /*
         * make sure we don't start loading the page until data is loaded
         *
         * we can't call getItems() from here, because the new state values
         * aren't actually set until after we exit this function, so we let
         * the useEffect do it.
         */
        setLoaded(false)
    }

    let mainElement
    if (pageType === c.PageType.TRADE) {
        mainElement = (
            <TradeCenter {...{itemType, userInfo, multiPageType,
                    loaded, setLoaded, pageType, updatePageType, updateView,
                    setOtherId, id2, setId2, switchToList,
                    switchToSuggest}} />
        )
    } else if (pageType === c.PageType.TRADE_SUGGESTIONS) {
        mainElement = (
            <TradeSuggestions {...{itemType, userInfo, multiPageType,
                loaded, setLoaded, pageType, updatePageType, updateView,
                otherId, setOtherId, otherEmail, setOtherEmail,
                otherInfo, setOtherInfo, id2, setId2, setStatusText,
                switchToList, solicitHelp}} />
        )
    } else if (pageType === c.PageType.GUIDES) {
        mainElement = (
            <CollectionGuides {...{itemType, updatePageType, guide,
                setGuide}} />
        )
    } else if (pageType === c.PageType.WANTLIST) {
        mainElement = (
            <CollectionWantlist {...{itemType, multiPageType, loaded,
                    setLoaded}} />
        )
    } else {
        mainElement = (
            <ItemTotalsProvider>
            <CollectionForm {...{itemType, view, updateView, userInfo,
                otherInfo, setOtherInfo, otherId,
                setOtherId, otherEmail, setStatusText,
                setResultsText, loaded, setLoaded,
                multiPageType, pageType, setPageType,
                setStartTour, updatePageType, dirty,
                setDirty, solicitHelp, switchToSuggest, switchToGuide,
            }} />
            {itemType === 'pick' && <PickThanks />}
            </ItemTotalsProvider>
        )
    }

    let wantTour = ![
        c.PageType.TRADE,
        c.PageType.GUIDES,
        c.PageType.WANTLIST,
    ].includes(pageType)

    return (
        <>
            <Helmet>
                <title>{`Insanity Palace of Metallica: ${itemType.capitalize()}s`}</title>
            </Helmet>
            {view === null && <Loading />}
            {view !== null && <Fragment>
                <StatusBox
                    content={resultsText}
                    setContent={setResultsText}
                    timeout={null}
                    type='bigstatus'
                    closeButton={true}
                />
                <StatusBox
                    content={statusText}
                    setContent={setStatusText}
                    timeout={3000}
                    type='lowstatus'
                    closeButton={false}
                />
                {wantTour && <CollectionTour
                    {...{itemType, pageType, multiPageType, startTour,
                            setStartTour}} />}
                <h1>{pageType !== c.PageType.TRADE_SUGGESTIONS && view === c.View.OTHER && otherInfo ? `${otherInfo.displayName}'s ` : null}{itemType} Collection{itemType === 'coin' && <> <span className='beta'>Beta</span></>}</h1>
                    <CollectionPageHeader
                        {...{itemType, multiPageType, pageType,
                            updatePageType, view, setStartTour, wantTour,
                            dirty, setDirty}} />
                    {mainElement}
                </Fragment>
            }
        </>
    )
}

export default CollectionPage
