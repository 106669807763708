import React from 'react'

import './Footer.css'

const Footer = () => {
    return (
        <div className="footer">
            <br />
            <br />
            <hr size="1" width="75%" />
            <p className="center" style={{fontSize: '0.85em'}}>
              &copy; 1997 - present Phil Dibowitz<br />
              Please read our <a href="/copyright.shtml">Copyright Info</a>
            </p>
            <hr size="1" width="75%" />
        </div>
    )
}

export default Footer
