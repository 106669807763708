import React from 'react'
import { Link } from 'react-router-dom'

const UserHeader = ({userInfo}) => {
    console.info("Rendering UserHeader")
    var prestr;
    var linkstr;
    var url;
    if (!userInfo) {
        console.debug("UserHeader: no data")
        return <div id='login'></div>;
    } else if (userInfo['logged_in']) {
        console.debug("UserHeader: logged in")
        prestr = 'Logged in as';
        linkstr = userInfo['display_name'];
        return <div id="login"><span>{prestr} <Link to="/app/profile?id=_SELF_">{linkstr}</Link>&nbsp;&nbsp;</span></div>;
    } else {
        console.debug("UserHeader: not logged in")
        prestr = 'Not';
        url = '/cgi-bin/member_login_form_gen.pl?redirect=' +
            window.location.pathname
        linkstr = 'logged in';
        return <div id='login'><span>{prestr} <a href={url}>{linkstr}</a>&nbsp;&nbsp;</span></div>;
    }
}

export default UserHeader
