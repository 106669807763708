import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const FakesGuide = ({itemType, updateGuide}) => {

    return (
    <>
<h2>Fake Pick Comparison</h2>

<p>
There&lsquo;s a lot of fake picks out there. Some traders will try to pass off picks from pick tins as originals (our guide to tell the difference is <a href="/cgi-bin/picks_index.pl?form=picktins">here</a>), and others distribute blatant fakes. This page aims to document the fakes.</p>

<h3>HQ Picks</h3>

<p>
The HQ picks from 2020-11-14 are incredibly rare and highly sought after. That means there are fakes out there. In particular a lighter color pick with cheaper paint and much lower resolution art has been making the rounds.</p>

<p className="center">

<SimpleItemImage full="/images/pickinfo/fakes/hq_2020_11_14_front.png" thumb="/images/pickinfo/fakes/hq_2020_11_14_front--thumb.png" />
<SimpleItemImage full="/images/pickinfo/fakes/hq_2020_11_14_back.png" thumb="/images/pickinfo/fakes/hq_2020_11_14_back--thumb.png" />
</p>

<p>
Thanks to Frank Tochez for the fake pick for analysis.
</p>

<h3>City Picks</h3>

<p>
The most commonly faked picks are city picks. These are usually easy to spot. Here&lsquo;s three picks <strong>the real one is on the right</strong> the other two are fakes.</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/fakes/20160225_001408.jpg" thumb="/images/pickinfo/fakes/20160225_001408--thumb.jpg" />
<SimpleItemImage full="/images/pickinfo/fakes/20160225_001441.jpg" thumb="/images/pickinfo/fakes/20160225_001441--thumb.jpg" />
</p>

<p>
On the front you can see the left most one has a smaller font. The middle one is a cheaper plastic that feels much smoother than a typical pick - it&lsquo;s very glossy.</p>

<p>
On the back you can see the left one has a different Metallica logo, one when the hooks of the M and the A didn&lsquo;t come out as wide. On the middle one its more clear the ink doesn&lsquo;t work so well on the cheap plastic, it&lsquo;s a bit purple.</p>

<h3>Invalid Variations</h3>

<p>
Sometimes people will make a &quot;variation&quot; of a pick that was not actually made by the band. Sometimes it&lsquo;ll be a different color or smaller font or something.</p>
    </>
    )
}

export default FakesGuide
