
import React, { Fragment } from 'react'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils.jsx'
import './QuickMenu.css'
import { userListUrl } from './collectionUtils.jsx'

const QuickMenu = ({
    itemType, multiPageType, pageType, updatePageType, view, setStartTour,
    wantTour,
}) => {
    console.info("Rendering QuickMenu")

    const quickMenuItem = (item, subItem) => {
        /* If this item is the pageType we're showing, don't make it a link */
        let klass = subItem ? "sub_option" : "option"
        if (pageType === item && view === c.View.SELF) {
            return <span className={klass}>{utils.pageTypeToHeading(item)}</span>
        } else {
            if (item === c.PageType.WANTLIST) {
                // for the interactive tour thing
                klass = `${klass} wantlist_link`
            }
            return <a href={userListUrl(itemType, null, item)} className={klass} onClick={(e) => updatePageType(e, item, true)}>{utils.pageTypeToHeading(item)}</a>
        }
    }

    const quickMenuSubMenu = () => {
        if (multiPageType) {
            return <>{itemType.capitalize()} lists: {
                [c.PageType.OFFICIAL, c.PageType.SEMIOFFICIAL, c.PageType.UNOFFICIAL].map((item, idx) => {
                    return <Fragment key={item}>{idx > 0 && " / "}{quickMenuItem(item, true)}</Fragment>
                })
            } |&nbsp;</>
        } else {
            return <>{itemType.capitalize()} lists: {
                [c.PageType.OFFICIAL].map((item, idx) => {
                    return <Fragment key={item}>{idx > 0 && " / "}{quickMenuItem(item, true)}</Fragment>
                })
            } |&nbsp;</>
        }
    }

    const tourStarter = (e) => {
        e.preventDefault()
        setStartTour(true)
    }

    /* eslint jsx-a11y/anchor-is-valid: "off" */
    return (
        <div className="quick_menu">[&nbsp;
            {quickMenuSubMenu()}
            {quickMenuItem(c.PageType.TRADE, false)} |&nbsp;
            {quickMenuItem(c.PageType.WANTLIST, false)} |&nbsp;
            {quickMenuItem(c.PageType.GUIDES, false)}
            {wantTour && <> |&nbsp;
                <a href="#" onClick={tourStarter}>Take A Tour</a></>} ]</div>
    )
}

export default QuickMenu
