import React, { Fragment } from 'react'

// A wrapper element that makes a form if we need one otherwise just
// renders the children components as they are.
const ConditionalForm = (
    {wantForm, handleSubmit, ...props}
) => {
    console.info("Rendering ConditionalForm")

    if (wantForm) {
        return <form onSubmit={handleSubmit}>{props.children}</form>
    }
    return <Fragment>{props.children}</Fragment>
}

export default ConditionalForm
