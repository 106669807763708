import React, { useCallback, useEffect, useState } from 'react'

import Loading from '../Loading.jsx'

import * as utils from './tourUtils.jsx'
import TourSearchLink from './TourSearchLink.jsx'
import TourStatsSongList from './TourStatsSongList.jsx'
import TourMap from './TourMap.jsx'

// for placing TourMap properly
import './TourReport.css'

const NUM_SONGS = 50

const TourStats = ({
    pageType, updatePageType, dirty, tourDates, setTourDates, setError,
    setSetlistText, userInfo, setResultsText,
}) => {
    const [maxCities, setMaxCities] = useState(10)
    console.info("Rendering TourStats")

    const getStatsData = useCallback(() => {
        fetch(
            '/cgi-bin/tour_stats_values.pl', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(response => response.json())
            .then(i => {
                if (i['error']) {
                    setError(i['error_message'])
                    return
                }
                setTourDates(prevDates => ({
                    ...prevDates,
                    tourStats: {
                        numShows: i['results']['num_shows'],
                        numSetlists: i['results']['num_setlists'],
                        countries: i['results']['countries'],
                        cities: i['results']['cities'],
                        albumSongs: i['results']['album_songs'],
                        medleys: i['results']['medleys'],
                        medleyMap: i['results']['medley_map'],
                        songs: i['results']['songs'],
                        geos: i['results']['geos'],
                    },
                }))
            })
            .catch(err => {
                setError('Failed to get data')
                console.error(`Failed to get data`, err)
            })
    }, [setError, setTourDates])

    useEffect(
        () => {
            console.debug("Running effect to getStatusData")
            getStatsData()
        },
        [getStatsData]
    )

    const currentData = tourDates['tourStats']
    if (!currentData) {
        return <Loading />
    }

    const numCities = Object.keys(currentData.cities).length
    console.debug("numCities:", numCities)

    const renderToc = () => {
        return <ul>
            <li><a href="#stats">Statistics</a></li>
            <li><a href="#cities">Top Cities</a></li>
            <li><a href="#countries">Country Count</a></li>
            <li><a href="#songs">Song Counts</a></li>
            <li><a href="#mostplayed">{NUM_SONGS} Most-played Songs</a></li>
            <li><a href="#leastplayed">Songs played once once</a></li>
        </ul>
    }
    
    const renderStats = () => {
        return <>
            <h3 id="stats">Statistics</h3>
            <ul>
                <li>Metallica has played {currentData.numShows} shows [we have setlists for {currentData.numSetlists} ({Math.round(currentData.numSetlists / currentData.numShows * 100)}%) of those].</li>
                <li>Metallica has played in {currentData.countries.length} countries</li>
            </ul>
        </>
    }

    const renderTopCities = () => {
        const listItems = Object.values(currentData.cities)
            .sort((a, b) => b['count'] - a['count'])
            .slice(0, maxCities)
            .map((city, idx) => {
                let searchParams = {
                    city: city['city'],
                    country: city['country'],
                }
                if (city['state']) {
                    searchParams['state'] = city['state']
                }
                return <li key={idx}>{utils.locationString(city)} (
                    <TourSearchLink
                        {...{searchParams, updatePageType}}>{city.count}
                    </TourSearchLink>)
                </li>
            })

        const handleNumCitiesChange = e => {
            let newMax = e.target.value
            if (newMax > numCities) {
                newMax = numCities
                e.target.value = newMax
            }
            setMaxCities(newMax)
        }

        return <>
            <h3 id="cities">Top {maxCities} Cities</h3>
            Number of cities to show: <input
                type='number' max={numCities} min='5'
                defaultValue={maxCities}
                onBlur={handleNumCitiesChange}
                onKeyPress={e => {
                    e.key === 'Enter' && handleNumCitiesChange(e)
                }}
            /><br/>
            <ul>{listItems}</ul>
        </>
    }

    const renderCountryList = () => {
        const listItems = Object.values(currentData.countries)
            .sort((a, b) => b['count'] - a['count'])
            .map((country, idx) => {
                const searchParams = {
                    country: country['country'],
                }

                return <li key={idx}>{country['country']} (
                    <TourSearchLink
                        {...{searchParams, updatePageType}}>{country.count}
                    </TourSearchLink>)
                </li>
            })

        return <>
            <h3 id="countries">Country Count</h3>
            <ul>{listItems}</ul>
        </>
    }

    const renderTopBottomSongs = () => {
        const genListItems = (songs) => {
            return songs.map((song, idx) => {
                const searchParams = {
                    setlist: song.name,
                }

                return <li key={idx}>{song.name} (
                    <TourSearchLink
                        {...{searchParams, updatePageType}}>{song.count}
                    </TourSearchLink>)
                </li>
            })
        }

        const sortedSongs = Object.values(currentData.songs)
            .sort((a, b) => b.count - a.count)

        const topSongs = sortedSongs.slice(0, NUM_SONGS)
        const bottomSongs = sortedSongs.filter(x => x.count === 1)

        const topItems = genListItems(topSongs)
        const bottomItems = genListItems(bottomSongs)

        return <>
            <h3 id="mostplayed">{NUM_SONGS} Most-played Songs</h3>
            <ol>{topItems}</ol>

            <h3 id="leastplayed">Songs only played once</h3>
            <ol>{bottomItems}</ol>
        </>
    }

    const mapCityList = currentData.cities.slice(0, maxCities)
        .reduce((acc, city) => {
            acc[utils.locationString(city)] = city
            return acc
        }, {})

    return <>
        <h2 className="category">Tour Statistics</h2>
        {renderToc()}
        {renderStats()}
        {/* Location based lists will have the map next to them */}
        <div className="profile-container">
            <div className="location-lists" style={{flex: '1', minWidth: '0'}}>
            {renderTopCities()}
            {renderCountryList()}
            </div>
            <div className="tour-map" style={{flex: '1', minWidth: '0'}}>
                <TourMap cities={mapCityList} {...{maxCities}} />
            </div>
        </div>
        <TourStatsSongList global={true}
            {...{currentData, updatePageType, setError, setSetlistText, userInfo}} />
        {renderTopBottomSongs()}
    </>
}

export default TourStats
