import React from 'react'

import SimpleItemImage from '../SimpleItemImage'
import './Guides.css'

const ESPGuide = ({itemType, updateGuide}) => {
    return (
    <>
<h2>ESP Promo Picks</h2>

<p>
There are a ton of ESP promo picks and the pictures on the internet can make it hard to actually tell them apart or know what exists. This page attempts to map out what colors and types exist to make collection them and talking about them easier.</p>

A few notes:
<ul>
  <li><em>James Dark Blue</em> and <em>Kirk Gold</em> some versions come in both a <em>translucent</em> and an <em>opaque</em> version. It&lsquo;s worth noting here that the translucence is <strong>only noticable with a light directly behind it</strong>.</li>
  <li>Any pick whose picture is not watermarked means that I have not seen it in person and am guessing it&lsquo;s information based on photos or scans from other collections</li>
  <li><strong>Small</strong> means the ESP is between 1.5cm and 1.6cm from the far left of the &quot;E&quot; to the far right of the &quot;P&quot;. <strong>Large</strong> means the ESP is 1.8cm.</li>
</ul>

<p>
Thanks to <a href="http://metacik.edguy-sk-cz.com/picks.php?cid=7" target="_blank" rel="noreferrer">Peter</a> for photos and help.</p>

<table className="guide">
  <tr>
    <th>Style</th>
    <th>ESP (small)</th>
    <th>ESP (large)</th>
    <th>ESP Electric Sound Products (small)</th>
    <th>ESP Electric Sound Products (large)</th>
    <th>ESP in box</th>
  </tr>
  <tr>
    <th>James Dark Blue (opaque)</th>
    <td colSpan="1" className="na">Does not exist.</td>
    <td className="flyout"><SimpleItemImage full="/images/picks/584.png" thumb="/images/picks/584--thumb.png" /></td>
    <td colSpan="3" className="na">Does not exist.</td>
  </tr>
  <tr>
    <th>James Dark Blue (translucent)</th>
    <td className="flyout"><SimpleItemImage full="/images/pickinfo/esp/james_dark_blue_small_esp.jpg" thumb="/images/pickinfo/esp/james_dark_blue_small_esp--thumb.jpg" /><br/>(sparkly)</td>
    <td className="flyout"><SimpleItemImage full="/images/picks/314.png" thumb="/images/picks/314--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/315.png" thumb="/images/picks/315--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/316.png" thumb="/images/picks/316--thumb.png" /></td>
    <td className="flyout"><img src="/images/picks/unavailable--thumb.png" alt="we do not have a scan of this" /></td>
  </tr>
  <tr>
    <th>James Light Pearl Blue</th>
    <td className="flyout"><SimpleItemImage full="/images/pickinfo/esp/james_light_pearl_blue_small_esp.jpg" thumb="/images/pickinfo/esp/james_light_pearl_blue_small_esp--thumb.jpg" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/308.png" thumb="/images/picks/308--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/309.png" thumb="/images/picks/309--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/310.png" thumb="/images/picks/310--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/311.png" thumb="/images/picks/311--thumb.png" /></td>
  </tr>
  <tr>
    <th>James Light Pearl Purple</th>
    <td colSpan="4" className="na">Does not exist.</td>
    <td className="flyout"><SimpleItemImage full="/images/pickinfo/esp/james_light_pearl_purple_esp_w_box.jpg" thumb="/images/pickinfo/esp/james_light_pearl_purple_esp_w_box--thumb.jpg" /></td>
  </tr>
  <tr>
    <th>James Flat Blue</th>
    <td className="na">Does not exist.</td>
    <td className="flyout"><SimpleItemImage full="/images/picks/318.png" thumb="/images/picks/318--thumb.png" /></td>
    <td colSpan="3" className="na">Does not exist.</td>
  </tr>
  <tr>
    <th>James Royal Blue</th>
    <td className="na">Does not exist.</td>
    <td className="flyout"><SimpleItemImage full="/images/picks/724.png" thumb="/images/picks/724--thumb.png" /></td>
    <td colSpan="3" className="na">Does not exist.</td>
  </tr>
  <tr>
    <th>James Purple</th>
    <td colSpan="4" className="na">Does not exist.</td>
    <td className="flyout"><SimpleItemImage full="/images/picks/319.png" thumb="/images/picks/319--thumb.png" /></td>
  </tr>
  <tr>
    <th>Kirk Orange</th>
    <td className="flyout"><SimpleItemImage full="/images/pickinfo/esp/kirk_orange_small_esp.jpg" thumb="/images/pickinfo/esp/kirk_orange_small_esp--thumb.jpg" maxWidth="200" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/294.png" thumb="/images/picks/294--thumb.png" /></td>
    <td className="flyout"><img src="/images/picks/unavailable--thumb.png" alt="we do not have a scan of this" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/296.png" thumb="/images/picks/296--thumb.png" /></td>
    <td className="na">Does not exist.</td>
  </tr>
  <tr>
    <th>Kirk Gold (opaque)</th>
    <td className="na">Does not exist.</td>
    <td className="flyout"><SimpleItemImage full="/images/picks/298.png" thumb="/images/picks/298--thumb.png" /></td>
    <td colSpan="2" className="na">Does not exist.</td>
    <td className="flyout"><img src="/images/picks/unavailable--thumb.png" alt="we do not have a scan of this" /></td>
  </tr>
 <tr>
    <th>Kirk Gold (translucent)</th>
    <td className="flyout"><SimpleItemImage full="/images/picks/297.png" thumb="/images/picks/297--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/495.png" thumb="/images/picks/495--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/pickinfo/esp/kirk_gold_small_esp_w_text.jpg" thumb="/images/pickinfo/esp/kirk_gold_small_esp_w_text--thumb.jpg" maxWidth="200" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/300.png" thumb="/images/picks/300--thumb.png" /></td>
    <td className="na">Does not exist.</td>
  </tr>
   <tr>
    <th>Kirk Pearl Green</th>
    <td className="flyout"><SimpleItemImage full="/images/picks/302.png" thumb="/images/picks/302--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/303.png" thumb="/images/picks/303--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/picks/304.png" thumb="/images/picks/304--thumb.png" /></td>
    <td className="flyout"><SimpleItemImage full="/images/pickinfo/esp/kirk_pearl_green_large_esp_w_text.jpg" thumb="/images/pickinfo/esp/kirk_pearl_green_large_esp_w_text--thumb.jpg" maxWidth="200" /></td>
    <td className="flyout"><img src="/images/picks/unavailable--thumb.png" alt="we do not have a scan of this" /></td>
  </tr>
</table>
    </>
    )
}

export default ESPGuide
