import React from 'react'

import * as c from './tourConstants.jsx'

import TourTableHeader from './TourTableHeader'
import './TourTable.css'

const TourTable = ({userInfo, pageType, tour, updatePageType, ...props}) => {
    const header = (![c.PageType.TOUR].includes(pageType.type))
        ? <a
            href={`/app/tour?pagetype=${c.PageType.TOUR}&data=${tour}`}
            onClick={e => updatePageType(e, c.PageType.TOUR, tour)}
          >{tour}</a>
        : ""
    return (<>
        <table className='tour_dates'>
            <caption>
            {header}
            </caption>
            <thead>
                <TourTableHeader {...{userInfo, pageType}} />
            </thead>
            <tbody>{props.children}</tbody>
        </table><br/><br/>
    </>)
}

export default TourTable
