import React from 'react'

const GetUserCollection = ({itemType, updateView}) => {
    console.info("Rendering GetUserCollection")

    const handleSubmit = e => {
        e.preventDefault()
        updateView(e,
            Array.from(e.target.getElementsByTagName('input'))
                .filter(item => item.name === 'email')[0].value
        )
    }

    return (
        <form style={{display: 'inline'}} onSubmit={handleSubmit}>
            <label htmlFor="email">Get {itemType} collection for</label> &nbsp;
            <input className="display_only" id="email" type="email" size="10" maxLength="35" name="email" placeholder="email address"/>
            <input type="submit" value="now" />!
        </form>
    )
}

export default GetUserCollection
