import React from 'react'
import CollectionTable from './CollectionTable.jsx'

// Loops over props.varieties and generates a CollectionTable for each
const CollectionTables = ({
    varieties, allItems, memberItems, itemType, search, view, prefs, typeDisplay,
    updateCollectedVarieties, itemSetCounts, setStatusText, userInfo, rareness,
    pageType, itemSetImages, solicitHelp, sortedIds, switchToGuide,
    currentMemberKey,
}) => {
    console.info("Rendering CollectionTables")

    if (varieties[pageType].length === 1) {
        return <CollectionTable
            {...{allItems, memberItems, itemType, typeDisplay,
                    search, view, prefs, updateCollectedVarieties, itemSetCounts,
                    setStatusText, userInfo, rareness, pageType, itemSetImages,
                    solicitHelp, sortedIds, currentMemberKey,
            }}
        />
    }

    const tables = varieties[pageType].map((variety) => {
        return <CollectionTable
            key={variety}
            {...{allItems, memberItems, itemType, variety, typeDisplay,
                    search, view, prefs, updateCollectedVarieties, itemSetCounts,
                    setStatusText, userInfo, rareness, pageType, itemSetImages,
                    solicitHelp, sortedIds, switchToGuide, currentMemberKey,
            }}
        />
    })
    return tables
}

export default CollectionTables
