import React, { useState, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import * as ipom from '../common'
import Error from '../Error'
import StatusBox from '../StatusBox'

import { PageType } from './adminConstants'
import AdminIndex from './AdminIndex'
import TourPhotoReview from './TourPhotoReview'

const AdminPage = ({userInfo}) => {
    console.info("Rendering AdminPage")
    const location = useLocation()
    const navigate = useNavigate()
    const urlParams = ipom.getUrlSearchParams(location)
    const [pageType, setPageType] = useState(
        urlParams.get('pagetype') || PageType.INDEX
    )
    const [error, setError] = useState()
    const [resultsText, setResultsText] = useState(null)

    const updatePageType = useCallback((pt) => {
        console.log(pt)
        const urlSearchParams = ipom.getUrlSearchParams(location)
        urlSearchParams.set('pagetype', pt)
        ipom.urlPushSearch(urlSearchParams, navigate)
        setPageType(pt)
    }, [setPageType, navigate, location])

    /*
     * DO NOT RENDER ANYTHING AVOVE THIS SECURITY CHECK
     */
    const canSee = userInfo.logged_in && userInfo.admin
    if (!canSee) {
        return <>Permission Denied</>
    }

    let body
    if (error) {
        body = <Error {...{error}} />
    } else if (pageType === PageType.REVIEW_PHOTOS) {
        body = <TourPhotoReview {...{updatePageType, setError, setResultsText}} />
    } else { /* index */
        body = <AdminIndex {...{updatePageType, setError}} />
    }

    return <>
        <Helmet>
            <title>Insanity Palace of Metallica: Admin</title>
        </Helmet>
        <h1>Admin Console</h1>
        <StatusBox
                content={resultsText}
                setContent={setResultsText}
                timeout={null}
                type='bigstatus'
                closeButton={true}
                zIndexOffset={5}
            />
        {body}
    </>
}

export default AdminPage
