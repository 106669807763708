/* eslint no-extend-native: "off" */

import React, { useState, useCallback, useEffect } from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import UserHeader from './UserHeader.jsx'
import Loading from './Loading.jsx'
import Error from './Error.jsx'
import Footer from './Footer.jsx'
import Menu from './Menu.jsx'

import './IPOMPage.css'

// Add a .capitalize() function to strings
String.prototype.capitalize = function() {
    return this.charAt(0).toUpperCase() + this.slice(1)
}

// A wrapper for pages that automatically does the user header
const IPOMPage = ({PageElement, ...props}) => {
    const [userInfo, setUserInfo] = useState()
    const [error, setError] = useState()

    const getUserInfo = useCallback(() => {
        fetch('/cgi-bin/userinfo.pl')
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setError(i.error_message)
                    return
                }
                setUserInfo(i.results)
            })
            .catch(err => {
                setError("Failed to get talk to backend")
                console.error(`Failed to get userInfo:`, err)
            })
    }, [])

    useEffect(() => {
        if (userInfo) {
            return
        }
        getUserInfo()
    }, [userInfo, getUserInfo])

    if (error) {
        return <Error {...{error}} />
    }

    if (!userInfo) {
        return <Loading />
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>Insanity Palace of Metallica</title>
                <meta property="og:site_name" content="IPOM" />
                <meta property="fb:app_id" content="459014924110524" />
                <meta property="og:url" content="https://www.ipom.com" />
                <meta property="og:image"
                    content="https://ipom.com/images/star_logo_large.jpg" />
            </Helmet>
            {/*
              * This div is the same width as the menu and black and sets
              * up the body
              */}
            <Menu />
            <div className="main">
                <UserHeader {...{userInfo}} />
                {/*
                  *
                  * This div has narrower margins so the content doesn't extend
                  * to the edges of the black area
                  */}
                <div className="content">
                    <PageElement {...{userInfo, ...props}} />
                    <Footer />
                </div>
            </div>
        </HelmetProvider>
    )
}

export default IPOMPage
