import React from 'react'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils'

import './ItemColor.css'
import './ItemInput.css'

// The table cell with the input. Assumes we're looking at our own
// report, the only time this is meaningful. Deciding that is the job
// of ItemQty
const ItemInput = ({
    id, qty, incoming, handleHaveChange, handleIncomingChange, itemType
}) => {
    console.info("Rendering ItemInput")

    return (
        <td className="colored center"
            // Note we do an onClick override here so that we don't
            // copy-to-clipboard when people are trying updating qtys
            onClick={(e) => {e.preventDefault(); e.stopPropagation();}}
        >
            <label htmlFor={id} className="have_label">Have:</label><br/>
            <input tabIndex="2" type="number" name={id}
                /* key ensures it's not cached between diff itemTypes */
                key={`${itemType}_${id}`}
                min="0" defaultValue={ qty }
                className="qty"
                onChange={(e) => handleHaveChange(e.target.value) }
            /><br/>
            <label htmlFor={`incoming_${id}` } className="incoming_label tooltip">Incoming: <span className="left">{utils.formatMessage(c.INCOMING_TOOLTIP_TMPL, {item: itemType})}</span>
            </label><br/>
            <input tabIndex="2" type="number" name={`incoming_${id}`}
                /* key ensures it's not cached between diff itemTypes */
                key={`${itemType}_${id}_incoming`}
                min="0" defaultValue={ incoming }
                className="incoming"
                onChange={(e) => handleIncomingChange(e.target.value) }
            />
        </td>
    )
}

export default ItemInput
