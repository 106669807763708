import React, { Fragment } from 'react'

const ConditionalElement = ({condition, alternative, ...props}) => {
    if (condition) {
        return <Fragment>{props.children}</Fragment>
    }
    if (alternative) {
        return alternative
    }
}

export default ConditionalElement
