import React, { useEffect, useCallback, useState, Fragment } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import ConditionalForm from '../ConditionalForm.jsx'
import ConditionalElement from '../ConditionalElement.jsx'
import useDirtyPageWarning from '../useDirtyPageWarning.jsx'

import * as c from './tourConstants.jsx'
import TourTables from './TourTables.jsx'
import TourTableHeader from './TourTableHeader.jsx'
import TourTableRow from './TourTableRow.jsx'
import TourReport from './TourReport.jsx'

const TourForm = ({
    userInfo, pageType, setError, updatePageType, setResultsText,
    dirty, setDirty, setSetlistText, searchResults, setSearchResults,
    tourDates, setTourDates, seen, setSeen, privacy, setPrivacy,
}) => {
    // this is so we can clear the tourtables while we load new
    // data so that old data isn't shown while new title is shown
    //
    // MUST DEFAULT TO TRUE
    //
    // while data is not in fact loaded in the beginning, we also
    // check for data, so that's fine. If we default it to false, navigation
    // buttons will cause this to reinitialize to false, but also we won't
    // need to load data so it'll never get set to true thus leaving things
    // in 'loading' state forever
    const [loaded, setLoaded] = useState(true)

    const displaySaveShowsError = useCallback(err => {
        setResultsText(
            `Failed to save your shows${err ? ` (${err})`: ''}. Please try again.`
        )
    }, [setResultsText])

    const displaySaveShowsResults = useCallback(results => {
        let msg = <p><strong>Save complete!</strong></p>
        let tables = []
        let actions = ['added', 'removed']
        actions.forEach(action => {
            console.debug(`Handling ${action}`)
            let obj = results[`${action}_shows`]
            let dates = Object.keys(obj)
            if (dates.length === 0) {
                console.debug(" -> none")
                return
            }
            const rows = dates.sort().map(date => {
                console.debug(` -> ${action}: ${date}`)
                const dateInfo = obj[date]
                return <TourTableRow
                    key={date} skipLinks={true} {...{userInfo, dateInfo}}
                />

            })
            tables.push(<p>
                <em>You {action}...</em>
                <table width="100%">
                    <TourTableHeader userInfo={{}} skipLinks={true} />
                    {rows}
                </table>
            </p>)
        })

        if (Object.keys(results.changed_prefs).length > 0) {
            console.debug("We have changed preferences...")
            let arr = Object.keys(results.changed_prefs).map((pref) => {
                console.debug(` -> ${pref}`)
                return <li key={`pref_status ${pref}`}>{pref.capitalize()}: {results.changed_prefs[pref]['old']} -&gt; {results.changed_prefs[pref]['new']}</li>
            })
            msg = (
                    <>
                        {msg}
                        <p>Your preferences have been updated as follows:</p>
                        <ul>{arr}</ul>
                    </>
            )
        }
        msg = (<>
            {msg}
            {tables}
        </>)
        setResultsText(msg)
    }, [setResultsText, userInfo])

    const getShowData = useCallback(({
        userInfo, tourDates, setTourDates, seen, setSeen
    }) => {
        let wantSeen =
            userInfo['logged_in'] === 1 && Object.keys(seen).length === 0

        if (
            !wantSeen &&
            pageType.type !== c.PageType.SEARCH &&
            pageType.data &&
            pageType.data in tourDates[pageType.type]
        ) {
            console.debug("getShowData: Already have data, returning")
            return
        }
        console.debug("getShowData: Acquiring data")
        console.debug(`getShowData: tourDates:`, tourDates)

        // if we're here, we have to load new data, so set unloaded
        console.debug("Setting loaded to false")
        setLoaded(false)

        let payload = {'want_seen': wantSeen}
        if (pageType.type === c.PageType.SEARCH) {
            if (typeof(pageType.data) === 'string') {
                payload['simple_search'] = pageType.data
            } else {
                Object.keys(pageType.data).forEach(k => {
                    payload[k] = pageType.data[k]
                })
            }
            console.debug('search payload', payload)
        } else if (pageType.type === c.PageType.REPORT) {
            if (!pageType.data) {
                payload['onlymyshows'] = true
            } else {
                if (pageType.data.includes('@')) {
                    payload['onlyusershows_email'] = pageType.data
                } else {
                    payload['onlyusershows_id'] = pageType.data
                }
            }
            payload['include_report_extras'] = true
        } else {
            let key = pageType.type === c.PageType.YEAR ? 'year' : 'tour'
            payload[key] = pageType['data']
        }

        fetch('/cgi-bin/tour_dates_values.pl', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(i => {
                if (i['error']) {
                    setError(i['error_message'])
                    return
                }
                if (wantSeen) {
                    setSeen(i['results']['seen'])
                    setPrivacy(i['results']['privacy'])
                }
                if (pageType.type === c.PageType.SEARCH) {
                    setSearchResults(i['results'])
                } else if (pageType.type === c.PageType.REPORT) {
                    let d = {...tourDates}
                    const key = pageType.data || 'SELF'
                    d[pageType.type][key] = {
                        'dates': i['results']['dates'],
                        'songsSeen': i['results']['songs_seen'],
                        'songsNotSeen': i['results']['songs_not_seen'],
                        'albumSongs': i['results']['album_songs'],
                        'medleyMap': i['results']['medley_map'],
                        'medleys': i['results']['medleys'],
                        'otherMemberDisplayName':
                            i['results']['other_member_displayname'],
                        'otherMemberNumBadges':
                            i['results']['other_member_num_badges'],
                    }
                    setTourDates(d)
                } else {
                    // copy
                    let d = {...tourDates}
                    d[pageType.type][pageType.data] = i['results']['dates']
                    setTourDates(d)
                }
                console.debug("Setting loaded to true")
                setLoaded(true)
            })
            .catch(err => {
                setError('Failed to get data')
                console.error(`Failed to get data`, err)
            })
    }, [pageType, setError, setSearchResults, setPrivacy, setLoaded])

    const handleSubmit = useCallback(e => {
        e.preventDefault()

        let payload = {
            seen: Object.keys(seen),
            privacy: privacy,
        }
        fetch('/cgi-bin/tour_save_seen.pl', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    displaySaveShowsError(response.error)
                } else {
                    console.log("save successfull, marking page clean")
                    setDirty(false)
                    let acount = Object.keys(response.results['added_shows']).length
                    let rcount = Object.keys(response.results['removed_shows']).length
                    if (acount > 0 || rcount > 0) {
                        console.log(
                            "we've modified seen shows, invalidating" +
                            "tour report cache"
                        )
                        setTourDates(prevDates => ({
                            ...prevDates,
                            [c.PageType.REPORT]: {}
                        }))
                        /*
                         * if we saved seen-shows from the tour-report
                         * page we must reload the tour-report data
                         */
                        if (pageType.type === c.PageType.REPORT) {
                            getShowData({
                                userInfo,
                                tourDates,
                                setTourDates,
                                seen,
                                setSeen
                            })
                        }
                    }
                    displaySaveShowsResults(response.results)
                }
            })
            .catch(err => {
                displaySaveShowsError()
                console.error('Failed to save seen', err)
            })
        },
        [
            seen, displaySaveShowsError, displaySaveShowsResults,
            setDirty, setTourDates, getShowData, pageType.type,
            setSeen, tourDates, userInfo, privacy
        ]
    )

    // sets useEffect for dirty listeners
    useDirtyPageWarning(dirty, setDirty)

    useEffect(
        () => {
            console.debug("Running effect to getShowData")
            if (pageType.type === null) {
                return
            }
            if (pageType.type === c.PageType.REPORT &&
                (!userInfo.logged_in && !pageType.data)
            ) {
                return
            }
            getShowData({...{userInfo, tourDates, setTourDates, seen, setSeen}})
        },
        /*
         * lint wants us to also pass in seen and tourDates, but
         * we really only want this to run when pageType or userInfo changes
         * and the useCallback won't cause extra calls, but makes lint happier
         */
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [pageType, getShowData, userInfo]
    )

    useHotkeys(['shift+s', 'ctrl+s'], (e) => {
        e.preventDefault()
        switch (e.key) {
            case 'S':
            case 's':
                if (seen) {
                    handleSubmit(e)
                }
                break
            default:
                console.debug("key pressed", e.key)
                return
        }
        // allItems needs to exist when the HotKey is defined
        // otherwise handleSubmit is called with no access to it
    }, [seen])

    const searchKeyMap = {
        'state': 'STATES/PROVINCES',
    }

    const plural = term => {
        if (term in searchKeyMap) {
            return searchKeyMap[term]
        }

        if (term.endsWith('y')) {
            return term.substr(0,term.length-1) + "ies"
        } else if (term.endsWith('s')) {
            return term
        }
        return term + 's'
    }

    const searchCriteriaDesc = key => {
        if (key === 'onlymyshows') {
            return <strong>only your shows</strong>
        }
        if (key === 'venue_phy') {
            return <><strong>venues</strong> that are physically the same
                as <strong>{searchResults.venue_name}</strong></>
        }
        if (key === 'setlist_exact') {
            return <><strong>setlists</strong> that include <strong>{pageType.data[key]}</strong></>
        }
        return <><strong>{plural(key)}</strong> like <strong>{pageType.data[key]}</strong></>
    }

    const genHeading = () => {
        console.log('genHeading:', pageType.type)

        if (pageType.type === c.PageType.REPORT) {
            return <h2 className="category">Personal Tour Report</h2>
        }

        if (pageType.type === c.PageType.SEARCH) {
            let inner
            if (typeof(pageType.data) === 'string') {
                inner = <>You searched for: <strong>{pageType.data}</strong></>
            } else {
                let arr = Object.keys(pageType.data)
                    .map(key => (searchCriteriaDesc(key)))
                inner = <>
                    You searched for:{' '}
                    {arr.map((item, index) => (
                        <Fragment key={index}>
                            {item}
                            {index < arr.length - 1 && ' AND '}
                        </Fragment>
                    ))}
                </>
            }
            return <><br/><div className="searchsummary">{inner}</div><br/></>
        }

        /* should be YEAR and TOUR, but also our general fallback */
        return <h2 className="category">{pageType.data}</h2>
    }

    const wantForm = userInfo['logged_in'] === 1
    return (
    <>
        <>{genHeading()}</>
        <ConditionalForm wantForm={wantForm} {...{handleSubmit}} >
            { pageType.type === c.PageType.REPORT ?
                <TourReport {...{
                    userInfo, pageType, tourDates, seen, setSeen,
                    updatePageType, dirty, setSetlistText, setResultsText,
                    setError, searchResults, privacy, setPrivacy, handleSubmit
                }} /> :
                <TourTables {...{
                    userInfo, pageType, tourDates, seen, setSeen,
                    updatePageType, dirty, setSetlistText, setResultsText,
                    setError, searchResults, loaded,
                }} />
            }
            <ConditionalElement condition={wantForm}>
                <input className='submit' type="submit" value="Save shows I've seen" />
            </ConditionalElement>
        </ConditionalForm>
    </>
    )
}

export default TourForm
