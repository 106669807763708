export const INCOMING_TOOLTIP_TMPL = (
    '"Incoming" is the number of {item}s ' +
    'I have on their way to me from a trade.'
)

export const PRIVACY_TOOLTIP_TMPL = (
    'We will never show your total number of extra {item}s. We will simply ' +
    'show that you have extra to trade.'
)

export const View = {
    SELF: '_self_',
    OTHER: '_other_',
}

export const TAGINFO = {
    'alsoinpicktin': {
        'guide': 'purchasable',
        'text': 'Also purchasable',
        'img': '/images/warning_icon.png',
    },
    'xxx': {
        'text': 'XXX picks',
        'img': '/images/info_icon.png',
    },
    'esp': {
        'text': 'ESP picks',
        'img': '/images/info_icon.png',
    },
    'papahettin': {
        'text': 'Papa Het tins',
        'img': '/images/info_icon.png',
    },
    'yellowjazziii': {
        'text': 'Yellow Jazz III',
        'img': '/images/info_icon.png',
    },
    'wrath': {
        'text': 'Wrath picks',
        'img': '/images/info_icon.png',
    },
}

export const ICON_HEIGHT = 20
export const ICON_WIDTH = 20
export const SCALE_HEIGHT = 10 // 14 when out-of-5

export const PageType = {
    OFFICIAL: '_official_',
    SEMIOFFICIAL:'_semiofficial_',
    UNOFFICIAL: '_unofficial_',
    TRADE: '_trade_',
    TRADE_SUGGESTIONS: '_trade_suggestions_',
    GUIDES: '_guides_',
    WANTLIST: '_wantlist_',
}

export const StorageKeys = {
    COLLECTION_TOUR_KEY: 'CollectionTourComplete',
    TRADE_SUGGEST_TOUR_KEY: 'TradeSuggestTourComplete',
    LEGACY_TOUR_KEY: 'PicksTourComplete',
}
