import React from 'react'

const PickThanks= () => {
    return (
        <blockquote>
            <strong>Thanks to our donors and lenders</strong>! Keeping this list and the scans up-to-date is a huge undertaking and wouldn&apos;t be possible without the generous help many fans have provided. Thanks to John Bidwell for lending us his incredible collection of ESP promo picks; Andreas Alker for lending more ESP picks; Michael Robinson for lending his rare XXX pick; Patrick Bolger for donating 21 picks - including the Gershwin Prize picks; Allen Buster Ryckman for donating 2 picks; St&eacute;phane Troy for donating 2 picks; Juli&aacute;n Garc&iacute;a for donating 2 picks; Dustin Stroll for donating a pick; Mike Walter for 1 pick; Frank Tochez for 2 picks; and to everyone else who&apos;s ever handed me a pick at a show! Find out how <a href="/about/contribute-items.shtml">you can contribute</a>!
        </blockquote>
    )
}

export default PickThanks
