import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const SM2Guide = ({itemType, updateGuide}) => {
    /*
     * Rather than use thumbs, we use the normal pick image,
     * and specify a size much larger than our normal thumbs
     */
    const imageList = (name, num) => {
        let arr = []
        for (let i = 1; i <= num; i++) {
            for (let side of ['front', 'back']) {
                let path = `/images/pickinfo/sm2/${name}_${side}_${i}`
                arr.push(
                    <SimpleItemImage
                        key={i + side}
                        full={`${path}.png`}
                        thumb={`${path}.png`}
                        style={{width: "675px", height: "395px"}}
                    />
                )
            }
        }
        return arr
    }

    return (
    <>
<h1>S&amp;M2 Pick Breakdown</h1>

<p>
What follows is a breakdown of the difference between the live S&amp;M2 picks and the ones that came in the deluxe box set. Some picks are easy to tell apart, others less so.
</p>

<p>
One thing that&lsquo;s true of all of these picks is that they are all the same thickness - 1.0mm. There is no difference between any of the picks - live or otherwise, based on my caliper measurements.
</p>

<p>
We looked at each of these picks up close, under a magnifying glass, and under these high resolution scans to determine if any patterns could be found. Read on for what we found for each pick.
</p>

<h2>September 6</h2>

<p>
These picks, as have been reported, have strong differences between the live and box set versions. The box set versions have a rougher texture, the background images are lower quality and far more red/orange, and the print is lower quality.</p>

<p>
I had 2 different live picks to compare here (the box set pick is the same in all photos). <strong>Click any image to zoom in</strong>.
</p>

<p className="center">
{imageList('sep6', 2)}
</p>

<h2>September 8</h2>

<p>
These, like their September 6th counterparts, are easy to tell apart for the exact same reasons.
</p>

<p>
I had 3 different live picks to compare here (the box set pick is the same in all photos). <strong>Click any image to zoom in</strong>.
</p>

<p className="center">
{imageList('sep8', 3)}
</p>

<h2>Silver S&amp;M2</h2>

<p>
These picks are incredibly difficult to tell apart. The ink quality is slightly worse compared to the live ones, but not by much and not in a way that would be easy to determine without a side-by-side comparison.
</p>

<p>
I had 5 different live picks to compare here (the box set pick is the same in all photos). <strong>Click any image to zoom in</strong>.
</p>

<p className="center">
{imageList('silver_sm2', 5)}
</p>

<h2>Silver Violin</h2>

<p>
Like the other silver picks, these are quite difficult to tell apart. The ink in general seems to be cleaner <em>on the box set version</em>, which is unusual. The exception is the S&amp;M2 logo itself which seems cleaner on the live picks. But the violin, the &quot;M&quot;, the Metallica logo and the date are all noticably cleaner and better quality on the box set picks.
</p>

<p>
I had 2 different live picks to compare here (the box set pick is the same in all photos). <strong>Click any image to zoom in</strong>.
</p>

<p className="center">
{imageList('silver_violin', 2)}
</p>

<h2>Tuxedo</h2>

<p>
This is by far the hardest pick to tell apart, if there&lsquo;s even any differences at all. The only consistent thing was that the box set pick seemed to be darker than the live picks, but that could be a function of the wear and tear of live picks. I could find no printing difference that was consistent across all the samples.
</p>

<p>
I had 4 different live picks to compare here (the box set pick is the same in all photos). <strong>Click any image to zoom in</strong>.
</p>

<p className="center">
{imageList('tux', 4)}
</p>

<p>
Hopefully that helps you all not get duped and collect the right picks!
</p>
    </>
    )
}

export default SM2Guide
