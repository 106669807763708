import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react'
import './StatusBox.css'

let innerTimeout
let outerTimeout

/*
 * A box for appearing status messages
 */
const StatusBox = ({
    content, setContent, timeout, type, closeButton, forceClose, setForceClose,
    zIndexOffset = 0,
}) => {
    const div = useRef(null)
    const [opacity, setOpacity] = useState(0)
    const [backdropOpacity, setBackdropOpacity] = useState(0)
    const [backdropDisplay, setBackdropDisplay] = useState('none')

    const Z_INDEX_BASE = 90

    console.info("Rendering StatusBox")

    /*
     * This should NOT preventDefault as it prevents things like 'submit'
     * working inside
     */
    const handleClick = e => {
        if (closeButton) {
            return null
        }
        clear()
    }

    const closeButtonClick = e => {
        e.preventDefault()
        clear()
    }

    const clear = useCallback(() => {
        setOpacity(0)
        if (closeButton) {
            setBackdropOpacity(0)
            setBackdropDisplay('none')
        }
        /*
         * If we remove the class now, it'll cut short the fade-out.
         * If we remove the text now, it'll disappear abruptly before the
         * fade-out.
         *
         * HOWEVER, if we DON'T clear the text, then the overlay is still
         * there, blocking the page (in the case of a bigger status), so
         * we need to clear it after the transition. So get the timing of
         * that, and add 50ms just to be safe. And while we're at it,
         * we can clear the classes...
         */
        let t = window.getComputedStyle(div.current).transitionDuration
        // that's something like 0.4s, we need to drop the 's', convert
        // to a number, and the turn into milliseconds. Trying to use a
        // string in a math equation causes JS to cast it for you
        t = t.replace('s', '') * 1000
        t += 50
        innerTimeout = window.setTimeout(() => {
            setContent(null)
        }, t)
    }, [setContent, closeButton])
    
    useEffect(() => {
        console.debug(
            `StatusBox: in useEffect, timeout ${timeout}`
        )
        // If we're setting content to null, we've already set the opacity
        // so we can return null to not re-render
        if (content === null) {
            return () => {}
        }

        // otherwise, we need to appear!
        setOpacity(1)
        if (closeButton) {
            setBackdropOpacity(0.8)
            setBackdropDisplay('flex')
        }

        // if we have no timeout, we're done
        if (timeout === null) {
            return () => {}
        }

        // otherwise, set the timeout
        // nuke any timeout already there
        if (innerTimeout) {
            window.clearTimeout(innerTimeout)
        }
        if (outerTimeout) {
            window.clearTimeout(outerTimeout)
        }
        outerTimeout = window.setTimeout(clear, timeout)
    }, [content, timeout, clear, closeButton])

    useEffect(() => {
        if (forceClose) {
            clear()
            setForceClose(false)
        }
    }, [forceClose, setForceClose, clear])

    return (
        <Fragment>
            {closeButton && <div className="statusbackdrop"
                style={{
                    opacity: backdropOpacity,
                    display: backdropDisplay,
                    zIndex: Z_INDEX_BASE + zIndexOffset,
                }} />}
            <div
                className={type}
                ref={div}
                id='status'
                style={{
                    opacity:opacity,
                    zIndex: Z_INDEX_BASE + zIndexOffset + 1,
                }}
                onClick={handleClick}
            >
                {content && closeButton &&
                    <header>
                    <button className="close-button" data-close aria-label="Close modal" type="button" style={{float: 'right', display: 'inline'}} onClick={closeButtonClick}>
                            <span aria-hidden="true">&times;</span>
                    </button>
                    </header>
                }
                {content}
            </div>
        </Fragment>
    )
}

export default StatusBox
