import React from 'react'

const TourSectionLink = ({type, updatePageType, ...props}) => {
    return <a
        href={`/app/tour?pagetype=${type}`}
        onClick={e => updatePageType(e, type)}
    >{props.children}</a>
}

export default TourSectionLink
