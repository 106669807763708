import React from 'react'

const Error = ({error}) => {
    console.error('ERROR:', error)

    return (
        <div id="error-page">
            <h1>Error</h1>
            <p>
                Sorry, an error occured, details are below. If you believe this is a bug, please reach out to us at webmaster at ipom dot com, or on our FB Page.
            </p>
            <p>
                <i>{error}</i>
            </p>
        </div>
    )
}

export default Error
