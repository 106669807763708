export const PageType = {
    ADVANCED_SEARCH: '_adv_search_',
    INDEX: '_index_',
    REPORT: '_report_',
    SEARCH: '_search_',
    SHOWINFO: '_show_info_',
    SHIRTINFO: '_shirt_info_',
    STATS: '_stats_',
    TOUR: '_tour_',
    USER_REVIEWS: '_user_reviews_',
    YEAR: '_year_',
}
