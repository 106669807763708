import React, { Fragment } from 'react'

import Member from '../Member.jsx'

import * as c from './collectionConstants.jsx'
import { userListUrl } from './collectionUtils.jsx'

// Component that encompases the page opening
const Opening = ({
    itemType, multiPageType, pageType, view, otherInfo, collectionPhrase,
    updatePageType, updateView, pageTypeStr, userInfo, otherId,
}) => {
    console.info("Rendering Opening")
    /* Only people's official and semi-official pages can be shared */
    let othertype = c.PageType[
        pageType === c.PageType.OFFICIAL ? 'SEMIOFFICIAL' : 'OFFICIAL'
    ]

    if (view === c.View.OTHER) {
        let extra = ''
        if (multiPageType) {
            extra = <span>You can also see their <a href={userListUrl(itemType, otherId, othertype)} onClick={(e) => updatePageType(e, othertype)}>{pageTypeStr(othertype)}</a> collection. </span>
        }
        return <p>This is <Member badges={otherInfo.numBadges} memberInfo={{id: otherId, displayName: otherInfo.displayName}} />&apos;s {collectionPhrase()}. {extra}See or build <a href={`/app/${itemType}s`} onClick={(e) => updateView(e, c.View.SELF)}>your own collection</a>!</p>
    } else if (userInfo['logged_in']) {
        let extra = ''
        if (itemType === 'coin') {
            extra = <p>The Coins section is <span className="beta">Beta</span> since it&lsquo;s still new. We may be missing coins here and if you know of any, please let us know!</p>
        }
        if (!multiPageType) {
            return <><p>{userInfo['display_name']}, this is your {itemType} collection!</p>{extra}</>
        } else if (pageType === c.PageType.UNOFFICIAL) {
            // unofficial is special since you can't store info on it
            return <><p>{userInfo['display_name']}, this is your <strong>unofficial</strong> {itemType} list.</p>{extra}</>
        } else {
            return <><p>{userInfo['display_name']}, this is your <strong>{pageTypeStr(pageType)}</strong> {itemType} collection! Be sure to use the links above to visit your semi-official and unofficial collections!</p>{extra}</>
        }
    } else {
        /* not logged in, not viewing someone else */
        let extra = ''
        if (pageType === c.PageType.OFFICIAL) {
            extra = <p>If you&lsquo;re looking for prototypes, misprints, Chapter {itemType}s or more, see the semi-official and unofficial pages!</p>
        }
        return <Fragment><p>Welcome to the IPOM {itemType.capitalize()} section! If you log in you can track your own {itemType} collection and share reports with others!</p>{extra}</Fragment>
    }
}

export default Opening
