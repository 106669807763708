import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import * as ipom from '../common.jsx'
import Error from '../Error.jsx'
import Loading from '../Loading.jsx'
import StatusBox from '../StatusBox.jsx'

import * as c from './tourConstants.jsx'
import TourForm from './TourForm.jsx'
import ShowInfo from './ShowInfo.jsx'
import TourSearchForm from './TourSearchForm.jsx'
import TourStats from './TourStats.jsx'
import TourMenu from './TourMenu.jsx'
import TourMemberReviews from './TourMemberReviews.jsx'
import TourSectionLink from './TourSectionLink.jsx'
import TourShirtInfo from './TourShirtInfo'

import './TourPage.css'

/*
 * TODO:
 *  - reviews
 *    - migrate reviews to be associated with members
 *      - this is partially done, but we have to follow up with non-matched
 *        ones
 *  - test on mobile
 */

const parseDataArg = data => {
    if (!data) return null
    if (data.startsWith('encoded:')) {
        let ret = {}
        // chop off 'encoded:'
        data = data.substring(8)
        data.split('&').forEach(val => {
            let x = val.split('=')
            ret[x[0]] = x[1]
        })
        return ret
    }
    return data
}

const TourPage = ({userInfo}) => {
    const location = useLocation()
    const navigate = useNavigate()
    const urlParams = ipom.getUrlSearchParams(location)
    const [error, setError] = useState(false)
    const [menuData, setMenuData] = useState()
    const [pageType, setPageType] = useState({
        'type': urlParams.get('pagetype') || c.PageType.INDEX,
        'data': parseDataArg(urlParams.get('data'))
    })
    const [resultsText, setResultsText] = useState(null)
    const [setlistText, setSetlistText] = useState(null)
    const [dirty, setDirty] = useState(false)
    const [searchResults, setSearchResults] = useState(null)
    const [forceCloseSetlist, setForceCloseSetlist] = useState(false)
    /*
     * tourDates and seen need to be here in TourPage otherwise
     * when we switch to things like AdvancedSearch, they get lost
     */
    const [tourDates, setTourDates] = useState({
        /*
         * square brackets don't mean array here, just to treat the keys
         * as variables instead of strings
         */
        [c.PageType.YEAR]: {},
        [c.PageType.TOUR]: {},
        [c.PageType.REPORT]: {},
    })
    const [seen, setSeen] = useState({})
    const [privacy, setPrivacy] = useState()
    console.info('Rendering TourPage: pageType', pageType)

    useEffect(() => {
        const action = location.state?.action || 'POP'
        console.debug(
            action !== 'POP'
                ? `HistoryListener: Ignoring nav not buttons used, location: ${JSON.stringify(location)}, action: ${action}`
                : `HistoryListener: Processing nav buttons used, location: ${JSON.stringify(location)}, action: ${action}`
        )

        if (action !== 'POP') return

        const urlParams = new URLSearchParams(location.search)
        const newType = urlParams.get('pagetype') || c.PageType.INDEX
        const newData = parseDataArg(urlParams.get('data'))
        setPageType({ type: newType, data: newData })
    }, [location, setPageType])

    const updatePageType = (e, type, data) => {
        if (e) {
            e.preventDefault()
        }
        /*
         * For the drop-down, we take the type from the event,
         * but for links, the type is passed in
         */
        if (!data) {
            data = e.target.value
        }
        if (type === c.PageType.INDEX) {
            data = null
        }
        console.debug("updatePageType: type is:", type)
        if (dirty) {
            console.log("updatePageType: page IS dirty")
            if (window.confirm("You have unsaved changes, are you sure you " +
                    "want to navigate away from this page?") === false) {
                return
            }
            setDirty(false)
        }
        console.debug("updatePageType: page is NOT dirty")

        setForceCloseSetlist(true)

        /*
         * If we're navigating away from search results, clear them
         * so that if we do another search (which can happen from all
         * sorts of "played X times" links etc.), the old results don't
         * briefly show.
         */
        let urlType = urlParams.get('pagetype')
        if (urlType === c.PageType.SEARCH && type !== c.PageType.SEARCH) {
            setSearchResults(null)
        }

        urlParams.set('pagetype', type)
        if (['number', 'string'].includes(typeof(data))) {
            urlParams.set('data', data)
        /* typeof(null) shows up as 'object', so test for null */
        } else if (data && typeof(data) === 'object') {
            let str = Object.keys(data).map(key => `${key}=${data[key]}`)
                .join('&')
            urlParams.set('data', 'encoded:' + str)
        } else {
            urlParams.delete('data')
        }
        console.debug(urlParams)
        ipom.urlPushSearch(urlParams, navigate)
        setPageType({'type': type, 'data': data})
    }

    if (!menuData) {
        fetch('/cgi-bin/tour_menu_values.pl', {method: 'GET'})
            .then(response => response.json())
            .then(i => {
                if (i['error']) {
                    setError(i['error_message'])
                    return
                }
                setMenuData(i['results'])
            })
            .catch(err => console.error('Failed to get tour menu data', err))
        return <Loading/>
    }

    console.debug('pageType is:', pageType)
    let body
    if (error) {
        body = <Error {...{error}} />
    } else if (pageType.type === c.PageType.SHIRTINFO) {
        body = <TourShirtInfo {...{userInfo, setError}} />
    } else if (pageType.type === c.PageType.STATS) {
        body = <TourStats {...{
                pageType, updatePageType, dirty, tourDates, setTourDates, setError,
                setSetlistText, userInfo, setResultsText,
            }} />
    } else if (pageType.type === c.PageType.SHOWINFO) {
        body = <><br/><ShowInfo showId={pageType.data} {...{
            userInfo, setError, updatePageType, setResultsText,
        }} /></>
    } else if (pageType.type === c.PageType.ADVANCED_SEARCH) {
        body = <TourSearchForm {...{userInfo, updatePageType}} />
    } else if (pageType.type === c.PageType.USER_REVIEWS) {
        body = <TourMemberReviews userId={pageType.data}
            {...{userInfo, setError, setResultsText, updatePageType}} />
    } else if (pageType.type !== c.PageType.INDEX) {
        body = <TourForm {...{
            userInfo, pageType, setError, updatePageType, setResultsText,
            dirty, setDirty, setSetlistText, searchResults,
            setSearchResults, tourDates, setTourDates, seen, setSeen,
            privacy, setPrivacy
        }} />
    } else {
        body = <>
<p>
<img src="/images/Jaymz4.jpg" alt="James Hetfield" border="0" width="237" height="360" align="right" />
    Welcome to the IPOM Tour Archive. Here you will find every tour date Metallica has ever performed, all scheduled tour dates, and one of the most complete archives of setlists on the web. And even better - it&lsquo;s both <em>browsable</em> and <em>searchable</em>. You can find shows by <strong>year</strong>, <strong>tour</strong>, or even do an <TourSectionLink type={c.PageType.ADVANCED_SEARCH} {...{updatePageType}}>advanced search</TourSectionLink>!
</p>

<p>
    But our most unique and popular feature is the <TourSectionLink type={c.PageType.REPORT} {...{updatePageType}}>Personal Tour Report</TourSectionLink> where you tell us what shows you&lsquo;ve been to and we generate a report with stats on what songs you&lsquo;ve seen, in what cities, and more! And of course we have stats for <TourSectionLink type={c.PageType.STATS} {...{updatePageType}}>band as a whole</TourSectionLink>?</p>

<p>
<span className="smallnote"><strong>SPECIAL THANKS</strong> to <a href="http://www.metclub.com/" target="_blank" rel="noreferrer">The Metallica Club</a>, and their magazine: <em>So What!</em>, <a href="http://www.chapterinc.com/" target="_blank" rel="noreferrer">Chapter Inc.</a>, <a href="mailto:MightyHet\@aol.com">Dan Walpole</a>, <a href="mailto:Battery12\@aol.com">John Spiers</a>, <a href="http://www.metontour.com/" target="_blank" rel="noreferrer">MetOnTour</a>, <a href="mailto:psilocybe\@thefragile.com">Abhishek Prasad</a>, and the IPOM Staff. Most of our pre-1995 setlists are courtesty of <a href="http://www.chapterinc.com/" target="_blank" rel="noreferrer">Chapter Inc.</a></span></p>
        </>
    }

    const includeIndex = pageType.type !== c.PageType.INDEX
    return <>
        <Helmet>
            <title>Insanity Palace of Metallica: Tour</title>
        </Helmet>
        <h1>Tour Archive</h1>
        <StatusBox
                content={resultsText}
                setContent={setResultsText}
                timeout={null}
                type='bigstatus'
                closeButton={true}
                zIndexOffset={5}
            />
        <StatusBox
                content={setlistText}
                setContent={setSetlistText}
                timeout={null}
                type='setlistbox'
                closeButton={true}
                forceClose={forceCloseSetlist}
                setForceClose={setForceCloseSetlist}
            />
        <TourMenu
            includeKbTt={true}
            {...{includeIndex, pageType, updatePageType, menuData}}
        />
        {body}
        {pageType.type !== c.PageType.INDEX
            ? <><br/><TourMenu
                    includeKbTt={false}
                    {...{includeIndex, pageType, updatePageType, menuData}}
                /></>
            : undefined}
    </>
}

export default TourPage
