import React, { Fragment } from 'react'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils.jsx'
import { useItemTotals } from './ItemTotalsContext'

import './CollectionStats.css'
import '../TableHScroll.css'

// Stats table
const CollectionStats = ({
    varieties: initVarieties, amounts: initAmounts, view,
    typeDisplay, userInfo, pageType, currentMemberKey, itemType
}) => {
    const { itemTotals } = useItemTotals()

    console.info("Rendering CollectionStats", itemTotals)
    const heading = <h3 id="top">Statistics</h3>

    const currentTotals = itemTotals[itemType]?.[pageType]?.[currentMemberKey]
    if (!currentTotals) {
        return <>
            {heading}
            Loading stats...
        </>
    }


    let varieties = ['total']
    if (initVarieties[pageType].length > 1) {
        varieties.push.apply(varieties, initVarieties[pageType])
    }
    let amounts = ['all', 'member'].concat(initAmounts)
    /*
     * If we're not logged in and we're not looking at someone
     * else's list, then hav/extra/complete/incomplete/missing are
     * empty anyway, so don't show them.
     */
    let minimal_table = (view === c.View.SELF && userInfo['logged_in'] === 0)

    if (minimal_table) {
        amounts = ['all']
    }

    function headers() {
        return [<th key='stats-header-blank'></th>].concat(
            varieties.map((variety, v) => {
                return <th key={`stats-header-${v}`}>{utils.varietyToHeading(variety).toUpperCase()}</th>
            })
        )
    }

    const visibility = (amount) => {
        let show
        switch(amount) {
            case 'extra':
                show = typeDisplay.extra
                break
            case 'complete':
                show = typeDisplay.complete
                break
            case 'incomplete':
                show = typeDisplay.incomplete
                break
            case 'missing':
                show = typeDisplay.missing
                break
            default:
                show = true
                break
        }
        return show ? "visible" : "collapse"
    }

    function body() {
        return amounts.map((amount) => {
            let name
            switch (amount) {
                case 'all':
                    name = 'exists'
                    break
                case 'member':
                    name = view === c.View.SELF ? "You have" : "They have"
                    break
                default:
                    name = `... ${amount}`
                    break
            }
            let cells = [<th key={`stats_l_${name}`}>{name.toUpperCase()}</th>]
            cells = cells.concat(varieties.map((variety) => {
                let key = `${amount}_${variety}`
                return <td key={`stats_cell_${key}`}>{currentTotals[key]}</td>
            }))
            return (
                <tr
                  style={{visibility: visibility(amount)}}
                  key={`stats_row_${amount}`}
                  className={amount}
                >{cells}</tr>
            )
        })
    }

    return (
        <Fragment>
            {heading}
            <div className="table-hscroll-container" id="item-stats">
                <table className='stats'>
                    <thead><tr>{headers()}</tr></thead>
                    <tbody>
                        {body()}
                    </tbody>
                </table>
            </div>
        </Fragment>
    )
}

export default CollectionStats
