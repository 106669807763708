import React, { useEffect, useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Helmet } from 'react-helmet-async'

import Loading from '../Loading.jsx'

import * as c from './tourConstants.jsx'
import * as util from './tourUtils.jsx'
import TourSearchLink from './TourSearchLink.jsx'
import ShowReviews from './ShowReviews.jsx'
import ShowSetlist from './ShowSetlist.jsx'

import './ShowInfo.css'
import 'react-tabs/style/react-tabs.css'

const ShowInfo = ({
    showId, setError, updatePageType, setSetlistText, userInfo, setResultsText,
}) => {
    const [setlistInfo, setSetlistInfo] = useState()
    /*
     * we separate data and metadata in the reviews object so we can
     * show the list of reviews here, but not waste the bandwidth and time
     * to get the full body of thre reviews, and the TourReviews component
     * will fill in the data of all reviews once the first one is selected
     */
    const [reviewData, setReviewData] = useState()
    const [reviewId, setReviewId] = useState()
    const [tabIndex, setTabIndex] = useState(0)
    console.info("Rendering TourSetlist,", showId)

    useEffect(() => {
        console.debug("Running useEffect to get setlist for", showId)
        if (!showId) {
            return
        }
        fetch(`/cgi-bin/tour_setlist_values.pl?id=${showId}`)
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    setError("Failed to get show info. Please try again")
                    console.error("Failed to get setlist", response.error_message)
                } else {
                    const showInfo = {
                        md: response.results.md,
                        songs: response.results.songs,
                        songStats: response.results['song_stats'],
                        cityStats: response.results['city_stats'],
                        venueStats: response.results['venue_stats'],
                    }
                    setSetlistInfo(showInfo)
                }
            })
            .catch(err => {
                console.error('Failed to get setlist', err)
                setError("Failed to get show info. Please try again")
            })
    }, [showId, setError])

    const genLocationString = (info, stats) => {
        let searchParams = {
            city: info['city'],
            country: info['country'],
        }
        if ('state' in info) {
            searchParams['state'] = info['state']
        }
        let link = <TourSearchLink
                {...{searchParams, updatePageType}}
            >{stats} shows here</TourSearchLink>
        let prefix = util.locationString(info)
        return <>{prefix} <small>[{link}]</small></>
    }

    const genVenueString = (info, stats) => {
        let searchParams = {
            venue_phy: info['venue_phy'],
        }
        let link = <TourSearchLink
                {...{searchParams, updatePageType}}
            >{stats} shows here</TourSearchLink>
        let prefix = info['venue']
        return <>{prefix} <small>[{link}]</small></>
    }

    const genInfoLine = (info) => {
        let comments = []
        if (info['canceled'] === 'y') {
            comments.push(<em key='canceled'>Canceled</em>)
        }
        if (info['comments']) {
            comments.push(<span key='comments'>{info['comments']}</span>)
        }
        if (comments.length > 0) {
            return <><strong>Info</strong>: {comments.reduce((prev, curr) => [prev, ' - ', curr])}<br/></>
        }
        return undefined
    }

    if (!setlistInfo) {
        return <Loading />
    }

    const { md, songs, songStats, cityStats, venueStats } = setlistInfo

    const renderSetlistAndReviews = () => {
        return <Tabs
                selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}
        >
            <TabList>
                <Tab>Setlist</Tab>
                <Tab>Reviews ({md['r']})</Tab>
            </TabList>

            <TabPanel>
                {md['s'] === 0 ?
                    <em>No setlist for this show.</em> :
                    <ShowSetlist {...{songs, songStats, md, updatePageType}} />}
            </TabPanel>
            <TabPanel>
                <ShowReviews {...{
                    md, showId, reviewData, setReviewData, reviewId, setReviewId,
                    updatePageType, userInfo, setResultsText,

                }} />
            </TabPanel>
        </Tabs>
    }

    const genReviewsLine = (md) => {
        return <>
            <strong>Reviews</strong>: {md['r']} - <button
                className='link-like-button'
                onClick={() => {
                    setReviewId(-1)
                    setTabIndex(1)
                }}>Submit yours!</button><br/>
        </>
    }

    const metaUrl = 'http://www.ipom.com/app/tour?'
        + `pagetype=${c.PageType.SHOWINFO}&date=${showId}`
    const title = 'Insanity Palace of Metallica:' +
        ` ${md.fdate} ${util.locationString(md)}`
    return <>
        <Helmet>
            <title>{title}</title>
            <meta property="og:url" content={metaUrl} />
            <meta property="og:type" content="ipomapp:metallica_show" />
        </Helmet>
        <strong>Date</strong>: {md.fdate}<br/>
        <strong>Location</strong>: {genLocationString(md, cityStats)}<br/>
        <strong>Venue</strong>: {genVenueString(md, venueStats)}<br/>
        {genInfoLine(md)}
        {genReviewsLine(md)}
        <br/>
        {renderSetlistAndReviews()}
    </>
}

export default ShowInfo
