import React from 'react'

const TourDateIcon = ({type}) => {
    let txt = `We have ${type === 'setlist' ? 'a setlist' : `${type}s`}`
    return (
        <img src={`/images/${type}-icon.png`} height="15" width="15"
            title={txt} alt={txt} />
    )
}

export default TourDateIcon
