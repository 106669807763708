import React from 'react'
import * as ReactDOM from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import IPOMPage from './IPOMPage'
import CollectionPage from './collections/CollectionPage'
import TourPage from './tour/TourPage'
import ProfilePage from './profile/ProfilePage'
import IndexPage from './index/IndexPage'
import AdminPage from './admin/AdminPage'
import RouterErrorPage from './RouterErrorPage'
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { IPOMReCaptchaSiteKey } from './constants'
//import reportWebVitals from './reportWebVitals';

const collectionSections = [
    { 'name': 'pick', 'multi': true, 'solicit': true },
    { 'name': 'coin', 'multi': false, 'solicit': true, },
]
let cols = collectionSections.map(entry => {
    return {
        path: `/app/${entry['name']}s`,
        element: <IPOMPage
            PageElement={CollectionPage}
            itemType={entry['name']}
            multiPageType={entry['multi']}
            solicitHelp={entry['solicit']}
        />,
        errorElement: <RouterErrorPage />,
    }
})
cols.push(
    {
        path: '/app/tour',
        element: <IPOMPage
          PageElement={TourPage}
        />,
        errorElement: <RouterErrorPage />,
    },
)
cols.push(
    {
        path: '/app/profile',
        element: <IPOMPage
          PageElement={ProfilePage}
        />,
        errorElement: <RouterErrorPage />,
    }
)
cols.push(
    {
        path: '/app',
        element: <IPOMPage
          PageElement={IndexPage}
        />,
        errorElement: <RouterErrorPage />,
    }
)
cols.push(
    {
        path: '/app/admin',
        element: <IPOMPage
          PageElement={AdminPage}
        />,
        errorElement: <RouterErrorPage />,
    }
)

const router = createBrowserRouter(cols)

ReactDOM.createRoot(document.getElementById("root")).render(
    <GoogleReCaptchaProvider reCaptchaKey={IPOMReCaptchaSiteKey}>
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    </GoogleReCaptchaProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
