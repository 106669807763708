import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

const YellowJazzIIIGuide = ({itemType, updateGuide}) => {

    return (
    <>

<h2>Jazz III Yellow picks</h2>

<p>
There&lsquo;s been lots of discussion on wheather there are sparkle and non-sparkle versions of the yellow Jazz III. Thanks to Frank Tochez for lending his yellow Jazz III picks to help us do this analysis.</p>

<p>
As you&lsquo;ll see below, there are definitely two versions of this pick: one that has no glitter in it, and one that has some. The left two are both &quot;sparkle&quot; and the right is &quot;non-sparkle.&quot;</p>

<p className="center">
<SimpleItemImage
  full="/images/pickinfo/jazziiiyellow/front_compare_1.png"
  thumb="/images/pickinfo/jazziiiyellow/front_compare_1--thumb.png" /><br />
<SimpleItemImage
  full="/images/pickinfo/jazziiiyellow/back_compare_1.png"
  thumb="/images/pickinfo/jazziiiyellow/back_compare_1--thumb.png" />
</p>

<p>
You can click to zoom in closer.</p>
    </>
    )
}

export default YellowJazzIIIGuide
