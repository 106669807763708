import React, { useMemo, useCallback } from 'react'
import Image from 'react-image-enlarger'

import './ItemColor.css'

// The table cell with the item image in it
//
// linkify - normally true, but if false, don't make them zoom-links,
//           used for 'results' overlays
const ItemImage = ({
    itemType, haveImage, variety, id, linkify, set, count, coverImages,
    solicitHelp,
}) => {
    const [zoomed, setZoomed] = React.useState(false)

    console.info("Rendering ItemImage")

    const imagePaths = useCallback(() => {
        let dir = `/images/${itemType}s`
        if (haveImage === 'no') {
            if (solicitHelp) {
                return {
                    'thumb': `${dir}/help-us--thumb.png`,
                    'full': "/about/contribute-items.shtml",
                }
            } else {
                return {
                    'thumb': `${dir}/unavailable--thumb.png`,
                    'full': `${dir}/unavailable.png`,
                }
            }
        }

        if (variety === 'picktin') {
            dir = `${dir}/tins`
        } else if (['unofficial', 'chapter'].includes(variety)) {
            dir = `${dir}/unofficial`
        }

        let base = `${dir}/${id}`
        return {
            'thumb': `${base}--thumb.png`,
            'full': `${base}.png`,
        }
    }, [itemType, id, haveImage, variety, solicitHelp])

    const paths = useMemo(
        () => imagePaths(),
        [imagePaths],
    )

    if (set) {
        return (
            <td className="tin center" rowSpan={count}>
                {set}<br/>
                <Image
                    border="0"
                    alt={set}
                    title={set}
                    src={coverImages['thumb']}
                    enlargedSrc={coverImages['images']}
                    zoomed={zoomed}
                    onClick={() => setZoomed(true)}
                    onRequestClose={() => setZoomed(false)}
                    loading="lazy"
                />
            </td>
        )
    }

    if (linkify) {
        if (haveImage === 'no' && solicitHelp) {
            return (
                <td className="colored center image">
                    <a href={ paths.full }>
                        <img src={ paths.thumb } alt="no scan available" />
                    </a>
                </td>
            )
        }
        return (
            <td className="colored center image">
                <Image
                    border="0"
                    alt={`${itemType}${id}`}
                    title={`${itemType}${id}` }
                    src={ paths.thumb }
                    enlargedSrc={paths.full}
                    zoomed={zoomed}
                    onClick={() => setZoomed(true)}
                    onRequestClose={() => setZoomed(false)}
                    /*
                     * prevents react-image-enlarger from causing
                     * the pick images to be super tiny
                     */
                    style={{maxWidth: "auto"}}
                    loading="lazy"
                />
            </td>
        )
    }

    return (
        <td className="colored center image">
            <img alt={`${itemType}${id}` } title={`${itemType}${id}` } src={ imagePaths().thumb } loading="lazy" />
        </td>
    )
}

export default ItemImage
