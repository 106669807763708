import React from 'react'

import * as c from './collectionConstants.jsx'
import QuickMenu from './QuickMenu.jsx'
import KbShortcuts from './KbShortcuts.jsx'

const CollectionPageHeader = ({
    itemType, multiPageType, pageType, updatePageType, view, setStartTour,
    wantTour
}) => {
    let wantKb = (
        [
            c.PageType.OFFICIAL,
            c.PageType.SEMI_OFFICIAL,
            c.PageType.UNOFFICIAL,
        ].includes(pageType) && view === c.View.SELF
    )
    return (<>
        <QuickMenu {...{itemType, multiPageType, pageType, updatePageType, view, setStartTour, wantTour}} />
        {wantKb &&
        <>
            <KbShortcuts />
        </>}
    </>)
}

export default CollectionPageHeader
