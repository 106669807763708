import React from 'react'

import Member from '../Member'

import * as c from './profileConstants'

const ProfilePreamble = ({userInfo, id, profileData, setId}) => {
    console.info("Rendering ProfilePreamble")

    if (id !== c.SELF) {
        return <>This is <Member badges={profileData.num_badges}
            memberInfo={{id: id, displayName: profileData.display_name}}
            />&apos;s profile. Depending on the user&apos;s privacy settings, various links and badges may not be displayed.</>
    }
    return <>Welcome to your profile, your one-stop-shop for all IPOM content specific to you! If you&apos;ve earned any badges, they will be on the right. Others can view your porfile, but it will be limited based on your privacy settings. You can see what others will see <a href={`/app/profile?id=${userInfo.id}`} onClick={() => setId(userInfo.id)}>here</a>.</>
}

export default ProfilePreamble
