import React from 'react'
import { Helmet } from 'react-helmet-async'

import Loading from '../Loading'
import Share from '../Share'
import Member from '../Member'
import * as ipom from '../common.jsx'

import * as c from './tourConstants.jsx'
import * as utils from './tourUtils.jsx'
import TourTables from './TourTables.jsx'
import TourSearchLink from './TourSearchLink.jsx'
import TourStatsSongList from './TourStatsSongList.jsx'
import TourMap from './TourMap.jsx'

import './TourReport.css'

const TourReport = ({
    userInfo, pageType, tourDates, seen, setSeen, updatePageType,
    dirty, setSetlistText, setResultsText, setError,
    searchResults, privacy, setPrivacy, handleSubmit
}) => {
    console.info('Rendering TourReport, privacy is', privacy)

    if (userInfo.logged_in !== 1 && !pageType.data) {
        return <>
            <p>
                You must be logged in to see your Personal Tour Report.
            </p>
        </>
    }

    const currentData = (userInfo['logged_in'] === 1 && !pageType.data) ?
        tourDates[c.PageType.REPORT]['SELF'] :
        tourDates[c.PageType.REPORT][pageType.data]

    if (!currentData) {
        return <Loading />
    }

    const currentDates = currentData['dates']

    const getStatsAndCounts = (currentDates) => {
        const today = new Date()
        let counts = {
            'future': 0,
            'canceled': 0,
            'media': 0,
            'total': 0,
        }
        let uniques = {
            'cities': {},
            'states': {},
            'countries': {},
        }
        Object.values(currentDates).forEach(e => {
            counts['total'] += 1
            const showDate = new Date(e.fdate)
            if (showDate > today) {
                counts.future += 1
            } else if (e.canceled === 'y') {
                counts.canceled += 1
            } else if (e.media_event === 'y') {
                counts.media += 1
            }
            const loc = utils.locationString(e)
            if (loc in uniques.cities) {
                uniques.cities[loc].count++
            } else {
                uniques.cities[loc] = {
                    city: e.city,
                    country: e.country,
                    count: 1
                }
            }
            if (e.state) {
                uniques.cities[loc].state = e.state
                if (e.state in uniques.states) {
                    uniques.states[e.state] += 1
                } else {
                    uniques.states[e.state] = 1
                }
            }
            if (e.country in uniques.countries) {
                uniques.countries[e.country] += 1
            } else {
                uniques.countries[e.country] = 1
            }
        })
        Object.keys(uniques).forEach(key => {
            counts[key] = Object.keys(uniques[key]).length
        })
        return [uniques, counts]
    }

    const who = userInfo['logged_in'] === 1 && !pageType.data ? "you've" : "they've"
    const cwho = who.capitalize()

    const shouldIncludeStates = () => {
        let have_state = false
        Object.values(currentDates).every(x => {
            if (x['state']) {
                have_state = true
                // break
                return false
            }
            return true
        })
        return have_state
    }

    const includeStates = shouldIncludeStates()

    const renderIntro = () => {
        if (userInfo.logged_in === 1 && !pageType.data) {
            const relUrl = `/app/tour?pagetype=${c.PageType.REPORT}&data=${userInfo['id']}`
            const fullUrl = `${ipom.API_ENDPOINT}${relUrl}`
            const title = 'Insanity Palace of Metallica:'
                + ` Tour Report for ${userInfo.display_name}`
            return <>
                {userInfo.display_name}, this is your tour report! If you&apos;ve enabled public viewing of your tour report you can share it using the following URL or buttons!
                <Helmet>
                    <meta property="og:title" content={title} />
                    <meta property="og:url" content={fullUrl} />
                    <title>{title}</title>
                </Helmet>
                <blockquote>
                    <Share {...{relUrl, fullUrl}} />
                </blockquote>
            </>
        } else if (pageType.data) {
            const relUrl = `/app/tour?pagetype=${c.PageType.REPORT}&data=${pageType.data}`
            const fullUrl = `${ipom.API_ENDPOINT}${relUrl}`
            const title = 'Insanity Palace of Metallica:'
                + ` Tour Report for ${currentData.otherMemberDisplayName}`
            return <>
                <Helmet>
                    <meta property="og:title" content={title} />
                    <meta property="og:url" content={fullUrl} />
                    <title>{title}</title>
                </Helmet>
                <p>This is the tour report for <Member badges={currentData.otherMemberNumBadges} memberInfo={{id: pageType.data, displayName: currentData.otherMemberDisplayName}} />. Make your own by simply saving shows you&apos;ve seen!</p>
            </>
        }
    }

    const handleUpdatePrefs = (e) => {
        setPrivacy(e.target.value)
    }

    const renderPrefs = () => {
        return <p className="preferences">
            <strong>Preferences</strong>: [ Privacy:&nbsp;
            <span className="tooltip">
                <img src="/images/info_icon.png" height="20" width="20" alt="info" />
                <span className="right">Should other people be able to find and see your report?</span>
            </span>
            <input type="radio" name="privacy" checked={privacy === 'public'}
                value='public' onChange={handleUpdatePrefs} /> Public
            <input type="radio" name="privacy" checked={privacy === 'private'}
                value='private' onChange={handleUpdatePrefs} /> Private ]&nbsp;
            <input type="submit" value="Save shows and preferences"
                onClick={handleSubmit} />
        </p>
    }

    const renderUserSearch = () => {
        const handleSubmit = (e) => {
            e.preventDefault()
            const email = e.target.value
            if (email === "") {
                return
            }
            updatePageType(e, c.PageType.REPORT, email)
        }

        return <p>Get tour report for <input
                type="text" size="15" maxLength="35" name="email"
                className='display_only'
                placeholder="friend's email"
                onKeyPress={e => {
                    e.key === 'Enter' && handleSubmit(e)
                }}
                onBlur={e => {handleSubmit(e)}}
            />
        </p>
    }

    const renderToc = () => {
        return <>
            <h3>Quick Links</h3>
            <ul>
                <li><a href="#stats">Statistics</a></li>
                <li><a href="#shows">Shows {who} seen</a></li>
                <li><a href="#cities">Cities {who} seen shows in</a></li>
                {includeStates &&
                    <li><a href="#states">States {who} seen</a></li>}
                <li><a href="#countries">Countries {who} seen shows in</a></li>
                <li><a href="#songs">Songs (seen/not seen)</a></li>
            </ul>
        </>
    }

    const [stats, counts] = getStatsAndCounts(currentDates)
    const renderStats = () => {
        let numShows = counts.total
        let extra = []
        if (counts.future > 0) {
            numShows -= counts.future
            extra.push(`${counts.future} future shows`)
        }
        if (counts.canceled > 0) {
            numShows -= counts.canceled
            extra.push(`${counts.canceled} canceled shows`)
        }
        if (counts.media > 0) {
            numShows -= counts.media
            extra.push(`${counts.media} non-qualifying appearances`)
        }

        const numSongsSeen = Object.keys(currentData['songsSeen']).length
        const numSongsNotSeen = currentData['songsNotSeen'].length
        return <>
            <h3 id="stats">Statistics</h3>
            <ul>
                <li>{cwho} seen {numShows} shows{extra.length > 0 ? ` (not including: ${extra.join(', and ')})` : undefined} (<a href="#shows">see them</a>)</li>
                <li>{cwho} seen shows in {counts.cities} cities (<a href="#cities">see them</a>)</li>
                {includeStates && <li>{cwho} seen shows in {counts.states} US states (<a href="#states">see them</a>)</li>}
                <li>{cwho} seen shows in {counts.countries} countries (<a href="#countries">see them</a>)</li>
                <li>{cwho} seen about {numSongsSeen} songs, and there are about {numSongsNotSeen} {who} not seen (<a href="#songs">see them</a>)</li>
            </ul>
        </>
    }

    const renderShows = () => {
        return <>
            <h3 id="shows">Shows {cwho} Seen ({counts.total})</h3>
            <TourTables {...{
                userInfo, pageType, tourDates, seen, setSeen, updatePageType,
                dirty, setSetlistText, setResultsText, setError, searchResults
            }} loaded={true} />
        </>
    }

    let baseSearchParams = {}
    if (userInfo['logged_in'] === 1 && !pageType.data) {
        baseSearchParams.onlymyshows = true
    }
    if (pageType.data) {
        baseSearchParams.onlyusershows_id = pageType.data
    }

    const renderSmallNote = (term) => {
        return <span className="smallnote">
            Click on the numbers to see the shows <em>{cwho}</em> seen in each {term}
        </span>
    }

    const renderCities = () => {
        const items = Object.keys(stats.cities).sort().map(loc => {
            const info = stats.cities[loc]

            let searchParams = {...baseSearchParams}
            searchParams.city = info['city']
            searchParams.country = info['country']
            if ('state' in info) {
                searchParams.state = info['state']
            }

            return (
                <li key={loc}>{loc} (<TourSearchLink
                    {...{searchParams, updatePageType}}
                >{info.count}</TourSearchLink>)</li>
            )
        })

        return <>
            <h3 id="cities">Cities {cwho} Seen Shows In ({counts.cities})</h3>
            {renderSmallNote('city')}
            <ul>{items}</ul>
        </>
    }

    const renderStates = () => {
        const items = Object.keys(stats.states).sort().map(state => {
            let count = stats.states[state]
            let searchParams = {...baseSearchParams}
            searchParams.state = state
            return (
                <li key={state}>{state} (<TourSearchLink
                    {...{searchParams, updatePageType}}
                >{count}</TourSearchLink>)</li>
            )
        })
        return <>
            <h3 id="states">States {cwho} Seen Shows In ({counts.states})</h3>
            {renderSmallNote('state')}
            <ul>{items}</ul>
        </>
    }

    const renderCountries = () => {
        const items = Object.keys(stats.countries).sort().map(country => {
            let count = stats.countries[country]
            let searchParams = {...baseSearchParams}
            searchParams.country = country
            return (
                <li key={country}>{country} (<TourSearchLink
                    {...{searchParams, updatePageType}}
                >{count}</TourSearchLink>)</li>
            )
        })
        return <>
            <h3 id="countries">Countries {cwho} Seen Shows In ({counts.countries})</h3>
            {renderSmallNote('country')}
            <ul>{items}</ul>
        </>
    }

    return <>
        <Helmet>
            <meta property="og:type" content="ipomapp:tour_report" />
        </Helmet>
        {renderIntro()}
        {renderUserSearch()}
        {pageType.data ? null : renderPrefs()}
        {renderToc()}
        {renderStats()}
        {renderShows()}

        {/* Location based lists will have the map next to them */}
        <div className="profile-container">
            <div className="location-lists" style={{flex: '1', minWidth: '0'}}>
                {renderCities()}
                {includeStates && renderStates()}
                {renderCountries()}
            </div>
            <div className="tour-map" style={{flex: '1', minWidth: '0'}}>
                <TourMap cities={stats.cities} />
            </div>
        </div>
        <TourStatsSongList {...{
            currentData, updatePageType, baseSearchParams, who, cwho, userInfo,
            setResultsText,
        }} />
    </>
}

export default TourReport
