import React, { useState } from 'react'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils.jsx'

/*
 * Shamlessly lifted from
 * https://www.smashingmagazine.com/2020/03/sortable-tables-react/
 *
 * Specific to collections, but will sort with all of the weirdness
 * required therein.
 */
export const useSortableData = (
    items, itemType, memberItems, desired, rareness, view, config = null
) => {
    const [sortConfig, setSortConfig] = useState(config)
    console.debug("rendering sortableData")

    const sortedItems = React.useMemo(() => {
        console.debug("sortableData: start: rebuilding sortedItems")
        let sortableItems = [...items]
        if (sortConfig !== null) {
            sortableItems.sort((a, b) => {
                let x, y
                if (sortConfig.key === 'have') {
                    let k1 = `${itemType}_${a['id']}`
                    let k2 = `${itemType}_${b['id']}`
                    /*
                     * We multiple 'incoming' by 0.9 so that 1 incoming sorts
                     * as less than 1 you already have, but still sorts as more
                     * than having 0
                     */
                    if (view === c.View.SELF) {
                        x = k1 in memberItems ?
                            memberItems[k1]['have'] + (memberItems[k1]['incoming'] * .9) : 0
                        y = k2 in memberItems ?
                            memberItems[k2]['have'] + (memberItems[k2]['incoming'] * .9) : 0
                    } else {
                        x = k1 in memberItems ? memberItems[k1]['have'] : 0
                        y = k2 in memberItems ? memberItems[k2]['have'] : 0
                    }
                    // since we might have 'Extra' as a value, stringify everything
                    // if we string-ify things to handle 'extra', then
                    // 7 sorts higher than 65. Instead, make 'extra' be a
                    // very high number
                    if (x === 'Extra') {
                        x = 999
                    }
                    if (y === 'Extra') {
                        y = 999
                    }
                } else if (sortConfig.key === 'want') {
                    let k1 = `${itemType}_${a['id']}`
                    let k2 = `${itemType}_${b['id']}`
                    let qty1 = k1 in memberItems ? memberItems[k1]['have'] : 0
                    let qty2 = k2 in memberItems ? memberItems[k2]['have'] : 0
                    x = utils.qtyToHaveAndNeed(a['id'], qty1, desired)[1]
                    y = utils.qtyToHaveAndNeed(b['id'], qty2, desired)[1]
                } else if (sortConfig.key === 'extra' || sortConfig.key === 'need') {
                    /* this is for TradeSuggestions */
                    x = memberItems[a['id']]
                    y = memberItems[b['id']]
                } else if (sortConfig.key === 'rare?') {
                    x = rareness[a['id']]
                    y = rareness[b['id']]
                } else {
                    x = (a[sortConfig.key] || "")
                    y = (b[sortConfig.key] || "")
                    if (['front', 'back'].includes(sortConfig.key)) {
                        // nuke quotes so that things sort sanely
                        x = x.replace('"', '')
                        y = y.replace('"', '')
                    }
                    // include tags in the stuff we sort for comments
                    if (sortConfig.key === 'comment') {
                        if (![null, ''].includes(a['tags'])) {
                            x += a['tags'].split(',').map(tag => c.TAGINFO[tag]['text']).join(' ')
                        }
                        if (![null, ''].includes(b['tags'])) {
                            y += b['tags'].split(',').map(tag => c.TAGINFO[tag]['text']).join(' ')
                        }
                    }
                    // downcase everything for sane sorting
                    x = x.toLowerCase()
                    y = y.toLowerCase()
                }

                if (x < y) {
                    return sortConfig.direction === 'ascending' ? -1 : 1
                }
                if (x > y) {
                    return sortConfig.direction === 'ascending' ? 1 : -1
                }
                return 0
            })
        }
        console.debug("sortableData: stop: rebuilding sortedItems")
        return sortableItems;
    }, [items, memberItems, sortConfig, rareness, desired, view, itemType]);

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        console.debug(`Setting sort to ${key}, ${direction}`)
        setSortConfig({ key, direction });
    }

    return [ sortedItems, requestSort, sortConfig ];
}
