import React, { useState, useCallback, useEffect, Fragment, useRef } from 'react'
import Image from 'react-image-enlarger'

import Loading from '../Loading'
import { locationString } from '../tour/tourUtils'
import { PageType } from './adminConstants'

import AdminLink from './AdminLink'

const TourPhotoReview = ({
    setError,
    setResultsText,
    updatePageType,
}) => {
    const [pending, setPending] = useState()
    const [toChange, setToChange] = useState()
    const [loaded, setLoaded] = useState(false)
    const [zoomed, setZoomed] = useState()
    const toChangeRef = useRef()
    console.info("Rendering TourPhotoReview")

    const getData = useCallback(() => {
        fetch('/admin-bin/tour_pending_photos_values.pl')
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setError(i.error_message)
                    return
                }
                setPending(i.results)
                setLoaded(true)
            })
            .catch(err => {
                setError('Failed to get data')
                console.error('Failed to get data:', err)
            })
    }, [setPending, setLoaded, setError])

    useEffect(() => {
        getData()
    }, [getData])

    const displayResults = (results) => {
        setResultsText(<>
            <h3>Success</h3>
            <p>
                <ul>
                    {Object.keys(results).map(i => (
                        <li key={i}>Number {i}: {results[i]}</li>
                    ))}
                </ul>
            </p>
        </>)
    }

    const handleSubmit = () => {
        const data = {'approved': [], 'rejected': []}
        Object.keys(toChangeRef.current).forEach(key => {
            const status = toChangeRef.current[key]
            data[status].push(key)
        })
        fetch('/admin-bin/tour_update_image_status.pl', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setError(i.error_message)
                    return
                }
                displayResults(i.results)
                getData()
            })
            .catch(err => {
                setError("Failed to save")
                console.log("Failed to save:", err)
            })
    }

    const handleUpdate = e => {
        const id = e.target.name
        const status = e.target.value
        if (id === 'all') {
            let newVal = {}
            Object.values(pending).forEach(show => {
                show.image_ids.forEach(id => {
                    newVal[id] = status
                })
            })
            setToChange(newVal)
            Object.values(
                document.getElementsByClassName('selection')
            ).forEach(el => {
                el.checked = (el.value === status)
            })
            return
        }
        setToChange(prev => ({
            ...prev,
            [id]: status,
        }))
    }

    useEffect(() => {
        toChangeRef.current = toChange
    }, [toChange])

    if (!loaded) {
        return <Loading />
    }

    let body
    if (Object.keys(pending).length === 0) {
        body = <p><em>No photos to review</em></p>
    } else {
        body = Object.keys(pending).map(show_id => {
            const data = pending[show_id]
            return <Fragment key={show_id}>
                <p>
                    <strong>{data.date}: {locationString(data)} - {data.handle}</strong>
                </p>
                {data.image_ids.map(id => {
                    const p = `/cgi-bin/tour_review_image.pl?id=${id}&force=1`
                    return <Fragment key={id}>
                        <Image
                            border="0"
                            src={`${p}&thumb=1`}
                            enlargedSrc={`${p}&thumb=0`}
                            zoomed={zoomed}
                            onClick={() => setZoomed(true)}
                            onRequestClose={() => setZoomed(false)}
                        />&nbsp;
                        <input
                            type="radio" name={id} value='approved'
                            className='selection'
                            onChange={handleUpdate}
                        />approve&nbsp;
                        <input
                            className='selection'
                            type="radio" name={id} value='rejected'
                            onChange={handleUpdate}
                        />reject<br/>
                    </Fragment>
                })}
            </Fragment>
        })
        body.push(<p key="buttons">
            <button
                className="link-like-button"
                name='all'
                value='approved'
                onClick={handleUpdate}
            >Set all to approve</button>&nbsp;&nbsp;
            <button
                className="link-like-button"
                name='all'
                value='rejected'
                onClick={handleUpdate}
            >Set all to reject</button>&nbsp;&nbsp;
            <button type="submit" onClick={handleSubmit}>Submit</button>
        </p>)
    }

    return <>
        <h2>Tour Photo Review</h2>
        <AdminLink s={PageType.INDEX} {...{updatePageType}}>Back to Admin Index</AdminLink>
        {body}
    </>
}

export default TourPhotoReview
