import React from 'react'
import Image from 'react-image-enlarger'

import './SimpleItemImage.css'

const SimpleItemImage = ({thumb, full, alt, style}) => {
    const [zoomed, setZoomed] = React.useState(false);
    if (!alt) {
        alt = full
    }
    let myStyle = { maxWidth: "auto" }
    if (style) {
        myStyle = {...myStyle, ...style}
    }
    return <Image
        border="0"
        alt={alt}
        title={alt}
        src={thumb}
        enlargedSrc={full}
        zoomed={zoomed}
        onClick={() => setZoomed(true)}
        onRequestClose={() => setZoomed(false)}
        style={myStyle}
    />
}

export default SimpleItemImage
