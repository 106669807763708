import React from 'react'

import GuideLink from '../GuideLink.jsx'

const TradingGuide = ({itemType, updateGuide}) => {
    return (
    <>
        <h2>Trading Guide</h2>

        <p>
        Welcome to the IPOM Pick Trading Guide. Here is where we try to walk you through everything you need to know to have a great trade. If we&&lsquo;ve missed something, please feel free to let us know.</p>

        <h3>Making A Trade</h3>
        <p>
        The first step is obviously to negotiate a trade. While this may seem easy and obvious, it can often be tricky. In the most common case, it&&lsquo;s simple: one pick you need for one pick they need.</p>

        <p>
        However, not all picks are equal. A city pick from a recent tour is not nearly as valuable, for example, as a white fang pick. This is just based on how rare the two picks are relative to each other. Therefore, some people may want a 2-for-1 or 3-for-1 (or even more) trade for particularly rare picks. It&&lsquo;s not unreasonable for someone to request this, but like with any negotiation in life, both people have to agree to the value.</p>

        <p>
        That said, most trades will be 1:1. If you are trading a city pick on the WorldWired tour for a different city pick on the WorldWired tour, for example, these are generally going to be 1:1 trades.</p>

        <p>
        It is important when discussing a trade to be explicit. A lot of picks can be very similar, and so saying, for example the &quot;white AWMH pick&quot; is dangerous as there are two variations on this, and if you get the wrong one, you&&lsquo;ll be upset. Therefore we recommend using the &quot;copy&quot; button on our <a href='/app/picks'>Pick List</a>. Clicking &quot;copy&quot; will put the full description of a pick into your clipboard for pasting into an email, chat, or post. For example: <tt>402 | 2011 | guitar | white | The Big 4 | 4 band logos England 2011 | 1.14mm</tt>.</p>

        <p>
            Once you&&lsquo;ve agreed on what the items to trade are, it is <strong>important to discuss the lineage of each pick</strong>. Lineage is it&&lsquo;s own separate topic and so we&lsquo;ve written <GuideLink guide='lineage' {...{updateGuide}}>a whole guide</GuideLink> just for that. Get the lineage for each pick, and note it down (see the section below on tracking trades). Some people may not have lineage on older picks. This is unavoidable - the idea of tracking lineage is new. If someone doesn&&lsquo;t know the lineage of a pick, it&&lsquo;s up to you to make a judgment call on if you want to do the trade. We provide some guidance on this in a later section.</p>

        <h3>Tracking Trades</h3>

        <p>
        It is important to keep close track of all of your trades. We recommend using a spreadsheet such as Google Sheets. We recommend making two tabs: <em>Trades</em> and <em>Completed Trades</em>. In this sheet you should have date, name, what you&&lsquo;re getting, what they&&lsquo;re getting, lineage, comments, incoming status, outgoing status. Once you&&lsquo;ve agreed on a trade, add a row to the spreadsheet like this:</p>

        <p className='center'>
        <img src='/images/pickinfo/trade_spreadsheet.png' alt="spreadsheet" /></p>

        <p>
        Comments can be used for whatever you want - like if the person tells you they&&lsquo;re out of town and won&&lsquo;t send the picks for 3 weeks, you might note that down.</p>

        <p>
        Next, head over to your <a href='/app/picks'>Pick List</a> and add all of the picks you will be getting to your &quot;incoming&quot; list so that they don&&lsquo;t show up as picks you need here in the Trading Center.</p>

        <h3>Packing Picks</h3>

        <p>
        Packing picks properly is important - if the picks don&&lsquo;t arrive in a great state, then people will not want to trade with you anymore. At the same time, you want to keep the packaging small as international shipping with tracking can be quite expensive. There are a few ways to pack picks that we&&lsquo;ve found to be successful.</p>

        <p>
        Below we&&lsquo;ve given our recommended way of packing, but here&&lsquo;s a few general guidelines.</p>

        <ul>
          <li>Never tape the picks to anything - it leaves sticky tape residue on the picks</li>
          <li>Don&&lsquo;t pack picks in a way that they&&lsquo;ll move around significantly as the surface and the ink can get scratched</li>
        </ul>

        <p>
        You want to put the picks into something to hold them still and protect them and then into another envelope for mailing. We recommend using <a href='https://www.amazon.com/Acko-Envelopes-Gummed-Garden-2-25x3-5/dp/B01LCLTS1M/ref=sr_1_4?crid=YX6PDMGUZ9Q9&keywords=small+paper+envelopes+for+coins&qid=1563751456&s=gateway&sprefix=small+paper+envelopes%2Caps%2C200&sr=8-4' target='_blank' rel='noreferrer'>coin envelopes</a> or <a href='https://www.amazon.com/Plymor-Heavy-Plastic-Reclosable-Zipper/dp/B003ZZY8F0/ref=sr_1_3?keywords=small+ziploc+bags&qid=1563751611&s=gateway&sr=8-3'>mini ziplock bags</a>. Both are incredibly cheap - less than 10 cents each.</p>

        <p>
        An alternative if you don&&lsquo;t want to buy those is to cut a piece of paper about 3 inches square, and make your own. See <GuideLink guide='wrapping' {...{updateGuide}}>our walkthru</GuideLink>.</p>

        <p>
        You can put 2-3 picks in one paper envelope or mini ziplock bag, but no more. However, you can put several of those envelopes into your letter envelope. You can use a standard letter envelope for sending now that the picks are protected. For extra protection a padded envelop can be used.</p>

        <p className='center'>
        <img src='/images/pickinfo/envelope.jpg' alt="envelope" /></p>

        <p>
        Don&&lsquo;t forget to update your <a href='/app/picks&'>Pick List</a> to remove the spares you just packed up!</p>

        <h3>Shipping Picks</h3>

        <p>
        Now you&&lsquo;ve packed up your picks and it&&lsquo;s time to ship them off. It is <strong>important to use tracking</strong>. It does cost more money, but <strong>the post office loses things all the time</strong>.</p>

        <p>
        The general rule of thumb on shipments getting lost are as follows. If tracking was used, the sender is not at fault and they don&&lsquo;t need to replace those picks (however, if there was insurance on the shipment and the sender received payment, that payment should be given to the receiver). If tracking was <strong>not</strong> used, then the sender is at fault and must remedy the situation. This can be done either by replacing the lost picks or be returning the picks they received in the trade.</p>

        <p>
        Put the tracking number into &quot;outgoing status&quot; column if your spreadsheet and send it to the other person. When they send you a tracking number, put it in &quot;incoming status.&quot; That way you can easily track your picks at all times. For even easier tracking, you may want to use something like the <a href='https://deliveries.orrs.de/' target='_blank' rel='noreferrer'>Deliveries Package Tracker</a> app (available on web, iPhone, and Android).</p>


        <h3>Receiving Picks</h3>

        <p>
        When you receive the picks there&&lsquo;s a few things to do. First, update your <a href='/app/picks'>Pick List</a> and change all the picks from &quot;incoming&quot; to &quot;have.&quot; Next be sure to tell the other person you received the picks so they know.</p>

        <p>
        <strong>Optionally</strong>, I like to mark the &quot;outgoing&quot; cell in my spreadsheet green, so I know it was received. Similarly, I&&lsquo;ll mark the &quot;incoming&quot; cell green. That way if it takes much longer for one of us to get the picks than the other, I know the status. This isn&&lsquo;t necessary, but if you want to, it looks like this:</p>

        <p className='center'>
        <img src='/images/pickinfo/trade_spreadsheet2.png' alt="spreadsheet" /></p>

        <p>
        Finally, if you are a member of <a href='https://www.facebook.com/groups/686248318122261/' target='_blank' rel='noreferrer'>Metallica Pick Trading Worldwide</a> or <a href='https://www.facebook.com/groups/235175486823259/' target='_blank' rel='noreferrer'>Metallica Pick Collectors</a>, then be sure to make a post in that group saying you had a successful trade, <strong>tag the person you traded with</strong> and add the hashtag <em>#tradereport</em>.</p>

        <h3>Finishing The Trade</h3>

        <p>
        Once both people have received their picks (and optionally posted in the relevant Facebook groups), select the row for this trade on your spreadsheet, choose &quot;cut&quot;, and then past it into the &quot;Completed Trades&quot; tab. <strong>Do not delete it</strong>. Keep a record of all trades - this will help you answer lineage questions and know who you&&lsquo;ve had successful trades with.</p>

        <h3>Risk and Avoiding Getting Screwed</h3>

        <p>
        There are <strong>a lot</strong> of fake picks out there. People make new ones all the time. Some of them are so good that they can make the real ones look fake. This is why we developed the <GuideLink guide='lineage' {...{updateGuide}}>lineage system</GuideLink> to help weed out fakes. However, it&&lsquo;s not perfect. Many picks just don&&lsquo;t have a known lineage. Some people lie about lineage.</p>

        <p>
        If a pick has no lineage there&&lsquo;s a few things to consider. First, how well known is the party you are trading with. If they are an admin of one of the trading Facebook groups, that&&lsquo;s a good sign, for example. Search for <a href='https://www.facebook.com/hashtag/tradereport?source=feed_text&epa=HASHTAG' target='_blank' rel='noreferrer'>trade reports</a> about them. If they have a lot of successful reports, that&&lsquo;s a good sign.</p>

        <p>
        Also consider how many people are currently offering that pick. If a rare pick no one has offered for years suddenly has 5 people offering it, chances are they all bought fakes on eBay.</p>

        <p>
        How long they&&lsquo;ve had it is another good indication. If someone traded for this pick in 2001, it&&lsquo;s more likely to be real - there weren&&lsquo;t many fakes back then. If someone got it yesterday, that&&lsquo;s more risky.</p>

        <p>
        Even if someone does not know the full lineage, they likely know where <em>they</em> got it. If they bought it from eBay, that&&lsquo;s more risky. If they got it from a trusted trader, that&&lsquo;s less risky. Sometimes you can track lineage yourself. If someone bought it on eBay you may be able to find out the eBay user and message them yourself.</p>

        <p>
        The older the pick the less likely it is to have a known lineage, so ultimately you will likely end up making trades without lineage. Any trade has risks, and lineage is just one tool to lower your risk. All of the factors mentioned here are additional tools to lower your risk.</p>

        <p>
        Once you&&lsquo;ve made a trade, it&&lsquo;s important to share the status with <em>#tradereport</em>s. That doesn&&lsquo;t just mean successful trades, that also includes unsuccessful ones.</p>
    </>
    )
}

export default TradingGuide
