import React from 'react'
import { Link } from 'react-router-dom'

import MetaPixel from '../MetaPixel'
import NewsSnippets from './NewsSnippets'

import './IndexPage.css'

const IndexPage = () => {
    return <>
        <MetaPixel />
        <div className="rightbar">
            <NewsSnippets />
        </div>

        <p>
            Welcome to the <a href="/about/what_is_ipom.shtml">Insanity Palace Of Metallica</a>, or as we call it, IPOM. IPOM is a website dedicated to bringing the most Metallica to as many people as we can. We attempt to be a creative website, and have things that other sites don&apos;t, such as a <a href="/cgi-bin/lt_index.pl">Lyric Theory</a> section, where you and other visitors can share your thoughts on what the songs&apos; lyrics mean.
        </p>

        <p>
            Our most popular section is our <Link to="/app/tour?pagetype=_report_">Personal Tour Report</Link> where you can find out what songs you have and have not yet seen live and get other stats! Or look through nearly every setlist in the band&apos;s history in our <Link to="/app/tour">Tour Archive</Link>.
        </p>

        <p>
            Are you a collector? We help you keep track of your collection of <Link to="/app/picks">Picks</Link> and <Link to="/app/coins">Coins</Link> too!
        </p>

        <p>
            So browse around and enjoy one of the best Metallica sites around!
        </p>
    </>
}
export default IndexPage
