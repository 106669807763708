export const API_ENDPOINT = window.location.origin

console.debug(`API_ENDPOINT is ${API_ENDPOINT}`)

/*
 * Common IPOM Components and methods
 */

export const getUrlSearchParams = location => {
    return new URLSearchParams(location.search)
}

export const urlPushSearch = (searchParams, navigate) => {
    // when switching anything with the search-string (view or pagetype)
    // we don't want to preserve any name-ref links (eg #123), so we pass
    // ' as hte hash
    navigate({search: '?' + searchParams.toString(), hash: ''})
}

export const urlReplaceSearch = (searchParams, navigate) => {
    // when switching anything with the search-string (view or pagetype)
    // we don't want to preserve any name-ref links (eg #123), so we pass
    // ' as hte hash
    navigate(
        {search: '?' + searchParams.toString(), hash: ''},
        {replace: true},
    )
}
