import React, { Fragment } from 'react'

import ConditionalElement from '../ConditionalElement.jsx'

import * as c from './collectionConstants.jsx'
import Opening from './Opening.jsx'
import UnofficialWarning from './UnofficialWarning.jsx'
import TradeSuggestionsLink from './TradeSuggestionsLink.jsx'
import GetUserCollection from './GetUserCollection.jsx'
import CollectionShare from './CollectionShare.jsx'

// Whole top half of the page after the quickmenu
const Preamble = ({
    itemType, userInfo, multiPageType, pageType, updatePageType, view,
    updateView, otherId, otherInfo, switchToSuggest,
}) => {
    console.info("Rendering Preamble")

    const pageTypeStr = (type) => {
        if (!type) {
            return ''
        }
        switch(type) {
            case c.PageType.SEMIOFFICIAL:
                return <Fragment>semi-official</Fragment>
            case c.PageType.UNOFFICIAL:
                return <Fragment>unofficial</Fragment>
            // official, but also, default
            default:
                return <Fragment>official</Fragment>
        }
    }

    function collectionPhrase() {
        if (pageType === c.PageType.SEMIOFFICIAL) {
            return <Fragment>collection of <strong>{pageTypeStr(pageType)}</strong> {itemType}s</Fragment>
        } else {
            return <Fragment>{itemType} collection</Fragment>
        }
    }

    return (
        <Fragment>
            <Opening {...{
                itemType, userInfo, multiPageType, pageType, updatePageType,
                pageTypeStr, collectionPhrase, view, updateView,
                otherInfo, otherId,
            }} />
            <UnofficialWarning {...{pageType}} />
            <TradeSuggestionsLink {...{itemType, view, userInfo, otherId,
                    otherInfo, switchToSuggest}} />
            <CollectionShare {...{itemType, userInfo, pageType, view}} />
            <ConditionalElement condition={pageType !== c.PageType.UNOFFICIAL}>
                <GetUserCollection {...{itemType, updateView}} />
            </ConditionalElement>
        </Fragment>
    )
}

export default Preamble
