import { useEffect } from 'react'

const useDirtyPageWarning = (dirty, setDirty) => {
    useEffect(() => {
        console.log("Adding dirty listeners")

        const markDirty = e => {
            if (e.target.tagName !== "INPUT") return
            if (e.target.className.includes('display_only')) return
            console.log("Marking page dirty")
            setDirty(true)
        }

        const onBeforeUnload = e => {
            if (dirty) {
                if (
                    window.confirm(
                        "You have unsaved changes, are you sure you want to navigate away from this page?"
                    ) === false
                ) {
                    e.preventDefault()
                    return
                }
            }
        }

        window.addEventListener("change", markDirty)
        window.addEventListener("beforeunload", onBeforeUnload)

        return () => {
            console.log("Removing dirty listeners")
            window.removeEventListener("change", markDirty)
            window.removeEventListener("beforeunload", onBeforeUnload)
        }
    }, [dirty, setDirty])
}

export default useDirtyPageWarning
