import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useContextMenu } from 'react-contexify'

import * as ipom from '../common.jsx'

import * as c from './collectionConstants.jsx'
import * as utils from './collectionUtils.jsx'
import { useSortableData } from './sortableData.jsx'
import ItemRow from './ItemRow.jsx'
import CollectionTableHeaderCell from './CollectionTableHeaderCell.jsx'
import CollectionContextMenu from './CollectionContextMenu.jsx'

import './CollectionTable.css'
import '../TableHScroll.css'

const MENU_ID = 'ItemContextMenu'

// Generates a whole table of items including all relevant headers based on
// props.view and props.variety
//
// Has special handling for picktins, specifically itemSetCounts and some special
// casing around variety=='picktin', but otherwise generic.
const CollectionTable = ({
    allItems, itemType, variety, memberItems, prefs, search, view, typeDisplay,
    updateCollectedVarieties, itemSetCounts, userInfo, setStatusText, rareness,
    pageType, itemSetImages, solicitHelp, sortedIds, switchToGuide,
    currentMemberKey,
}) => {
    console.info("Rendering CollectionTable. View is", view)
    const location = useLocation()

    const currentMemberItems = memberItems?.[currentMemberKey]

    const relevantItems = useMemo(() => {
        return sortedIds[pageType].map(id => allItems[pageType][id])
    }, [allItems, pageType, sortedIds])

    const [ items, requestSort, sortConfig ] = useSortableData(
        relevantItems,
        itemType,
        currentMemberItems,
        prefs[currentMemberKey][`${itemType}s_num_desired`],
        rareness[pageType],
        view
    );

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    let urlParams = ipom.getUrlSearchParams(location)
    const onlyMissingImages = urlParams.get('only_missing_images') === 'y'

    const header = () => {
        let picktin = variety === 'picktin'
        function conditionalHeaders() {
            switch(view) {
                case c.View.SELF:
                    if (userInfo['logged_in'] === 0)
                        return null
                    return <CollectionTableHeaderCell field="have" nosort={picktin} {...{requestSort, sortConfig}} />
                case c.View.OTHER:
                    return (
                        <>
                            <CollectionTableHeaderCell field="have" nosort={picktin} {...{requestSort, sortConfig}} />
                            <CollectionTableHeaderCell field="want" nosort={picktin} {...{requestSort, sortConfig}} />
                        </>
                    )
                default:
                    return null
            }
        }

        return (
            <thead>
              <tr>
              <CollectionTableHeaderCell field="year" nosort={picktin} {...{requestSort, sortConfig}} />
              { picktin && <CollectionTableHeaderCell field="tin/set" sortby="tin" {...{requestSort, sortConfig}} /> }
              <CollectionTableHeaderCell field="type" nosort={picktin} {...{requestSort, sortConfig}} />
              <CollectionTableHeaderCell field="color" nosort={picktin} {...{requestSort, sortConfig}} />
              <CollectionTableHeaderCell field="front" nosort={picktin} {...{requestSort, sortConfig}} />
              <CollectionTableHeaderCell field="back" nosort={picktin} {...{requestSort, sortConfig}} />
              <CollectionTableHeaderCell field="detail" sortby="note" nosort={picktin} {...{requestSort, sortConfig}} />
              <CollectionTableHeaderCell field="picture" nosort={true} {...{requestSort, sortConfig}} />
              {conditionalHeaders()}
              { pageType !== c.PageType.UNOFFICIAL &&
                  <CollectionTableHeaderCell field="rare?" {...{requestSort, sortConfig}} />
              }
              <CollectionTableHeaderCell field="comment" nosort={picktin} {...{requestSort, sortConfig}} />
            </tr>
            </thead>
        )
    }

    let inner = useMemo(() => {
        let last_set = null;
        return items.map((item) => {
            let new_set = false;
            let memberQty
            if (variety && item['variety'] !== variety) {
                return null
            }
            if (variety === 'picktin') {
                if (item['tin'] !== last_set) {
                    new_set = true
                    last_set = item['tin']
                }
            }
            if (onlyMissingImages && item['have_image'] === 'yes') {
                return null
            }

            const id = item['id']
            const key = `${itemType}_${id}`
            if (currentMemberItems && key in currentMemberItems) {
                memberQty = currentMemberItems[key]
            } else {
                memberQty = {have: 0, incoming: 0}
            }

            return <ItemRow
                {...{
                    prefs, id, item, itemType, typeDisplay, search, view, setStatusText,
                    userInfo, itemSetCounts, rareness, new_set, pageType, itemSetImages,
                    solicitHelp, switchToGuide, currentMemberKey,
                }}
                key={id}
                have={memberQty['have']}
                incoming={memberQty['incoming']}
            />
        }).filter(val => val !== null)
    }, [currentMemberItems, currentMemberKey, itemSetCounts, itemSetImages, itemType, items, onlyMissingImages, pageType, prefs, rareness, search, setStatusText, solicitHelp, switchToGuide, typeDisplay, userInfo, variety, view])

    const prefName = variety ? utils.varietyToPref(variety, itemType) : null
    const headingName = variety ? utils.varietyToHeading(variety) : null
    return (
        <div>
            {(variety && <>
                <h3 id={variety}>{headingName} {itemType}s</h3>
                <div style={{float: 'right'}}>[<a href="#top">Back to the top</a>]</div>
            </>) || <br/>}
            <div style={{float: 'left'}}>
                {variety && userInfo.logged_in &&
                <>
                    <input type="checkbox" name={prefName} defaultChecked={prefs[currentMemberKey][prefName]} onChange={updateCollectedVarieties} />
                    Collect {headingName} {itemType}s?
                </> }
            </div>
            {((!variety || prefs[currentMemberKey][prefName] || !userInfo.logged_in) &&
                <>
                <CollectionContextMenu id={MENU_ID} {...{itemType, setStatusText}} />
                    <div id={variety} className="table-hscroll-container">
                    <table
                        className="items"
                        id={variety}
                        onContextMenu={(e) => show({event: e})}
                    >
                    {header()}
                    <tbody>{inner}</tbody>
                    </table>
                </div>
                </>)
                ||
                <p className="center"><em>You do not collect {variety} {itemType}s. Update your preferences to chagne this.</em></p>
            }
            <br/>
        </div>
    )
}

export default CollectionTable
