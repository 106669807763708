import React from 'react'

import PipeLinks from '../PipeLinks.jsx'

import * as utils from './collectionUtils.jsx'

const VarietyLinks = ({varieties}) => {
    console.info("Rendering VarietyLinks")

    // callback for PipeLinks
    function display(variety, _index) {
        return <a key={variety} href={`#${variety}`}>{`${utils.varietyToHeading(variety)} Picks`}</a>
    }
    if (varieties.length === 1) {
        return
    }
    return (
        <p className="center">
            <strong>Jump to</strong>:&nbsp;
            <PipeLinks
                itemToJsx={display}
                items={varieties}
                edgeChar={["", ""]}
            />
        </p>
    )
}

export default VarietyLinks
