import * as c from './tourConstants.jsx'

export const locationString = info => {
    const { city, state, country } = info
    return `${city}${state ? `, ${state}` : ''} - ${country}`
}

// Given a pageType, gives a string captilized for a heading
export const pageTypeToHeading = type => {
    return type.slice(1, -1).capitalize()
}

export const hashToSearchUrlStr = search => {
    return Object.keys(search).map(key => {
        return `${key}=${search[key]}`
    }).join('&')
}

export const includeSeenCol = ({userInfo, skipLinks, pageType}) => {
    // If we're on save results, no seen-boxes
    if (skipLinks) {
        return false
    }

    // If we're looking at someone *else's* report, no seen boxes
    if (pageType.type === c.PageType.REPORT && pageType.data) {
        return false
    }

    // otherwise, seen boxes if we're logged in...
    return userInfo.logged_in === 1
}
