import React from 'react'

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
} from 'react-share'

const Share = ({relUrl, fullUrl}) => {
    console.info("Rendering Share")
    return <>
        <a href={relUrl}>{fullUrl}</a> &nbsp;
        <FacebookShareButton url={fullUrl}>
            <FacebookIcon size={20} />
        </FacebookShareButton> &nbsp;
        <TwitterShareButton url={fullUrl}>
            <TwitterIcon size={20} />
        </TwitterShareButton>
    </>
}

export default Share
