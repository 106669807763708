import React, { useEffect, useState, useMemo } from "react"
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    ZoomableGroup,
} from "react-simple-maps"
import { scaleLinear } from "d3-scale"
import sortBy from "lodash/sortBy"

import Loading from '../Loading.jsx'

const maxRadius = 10

const TourMap = ({cities, maxCities}) => {
    const [data, setData] = useState([])
    const [maxValue, setMaxValue] = useState(0)
    const [geos, setGeos] = useState({})
    const [loading, setLoading] = useState()

    console.info("Rendering TourMap")

    const baseMapUrl =
        "https://cdn.jsdelivr.net/npm/world-atlas@2/countries-50m.json"

    useEffect(() => {
        const cityArray = Object.keys(cities).map((loc) => ({
            name: loc,
            times: cities[loc]['count'],
        }))
        let sortedCities = sortBy(cityArray, (o) => -o.times)
        setMaxValue(sortedCities[0].times)
        setData(sortedCities)
    }, [cities])

    // Fetch missing geos in batches of 20
    useEffect(() => {
        const fetchGeos = async (missingCities) => {
            const batchSize = 25
            const results = { ...geos }
            setLoading(true)

            for (let i = 0; i < missingCities.length; i += batchSize) {
                const batch = missingCities.slice(i, i + batchSize)
                const payload = { cities: batch }

                try {
                    const response = await fetch('/cgi-bin/tour_geos.pl', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(payload),
                    })
                    const result = await response.json()
                    if (result.error) {
                        console.error(result.error_message)
                        continue
                    }
                    Object.assign(results, result.results.geos)
                } catch (err) {
                    console.error("Failed to get geos", err)
                }
            }

            setGeos(results)
            setLoading(false)
        }

        const currentCitySet = new Set(Object.keys(geos))
        const missingCities = Object.keys(cities).filter(
			city => !currentCitySet.has(city)
        )

        if (missingCities.length > 0) {
            fetchGeos(missingCities)
        }
    }, [data, geos, cities])

    const popScale = useMemo(
        () => scaleLinear().domain([1, maxValue]).range([2, maxRadius]),
        [maxValue]
    )

    /*
     * Downloaded the zip files for each of these from
     *   https://www.naturalearthdata.com/downloads/50m-cultural-vectors/
     * Then loaded them into
     *   https://mapshaper.org/
     * And exported EACH ONE as a topojson file (you can have it merge
     * them but they will overlap each other and not work right).
     */
    const topoJsonFiles = [
        'ne_50m_admin_0_sovereignty.json',
        'ne_50m_admin_0_countries.json',
        'ne_50m_admin_0_map_units.json',
        'ne_50m_admin_1_states_provinces.json',
    ]

    if (loading || Object.keys(geos) === 0) {
        return <Loading/>
    }

    return <>
        <h3>Tour Map</h3>
        {maxCities && <>Currently showing the top {maxCities} cities. You can adjust that number on the left.</>}
        <ComposableMap style={{border: '1px solid white'}}>
            <ZoomableGroup center={[0,0]} zoom={1}>

                {/* Base Map */}
                <Geographies geography={baseMapUrl} key="base">
                    {({ geographies }) =>
                        geographies.map((geo) => (
                            <Geography key={geo.rsmKey} geography={geo} fill="#DDD"/>
                        ))
                    }
                </Geographies>

                {/* Extra layers - countries, states, provinces */}
                {topoJsonFiles.map((file, idx) => (
                    <Geographies geography={`topojson/${file}`} key={idx}>
                        {({ geographies }) => {
                            return geographies.map((geo) => (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    style={{
                                        default: { fill: "#DDD", stroke: "black", strokeWidth: 0.5 },
                                        hover: { fill: "#F53", stroke: "black", strokeWidth: 1 },
                                        pressed: { fill: "#E42", stroke: "black", strokeWidth: 1 },
                                    }}
                                />
                            ))
                        }}
                    </Geographies>
                ))}

                <Geographies
                    geography={`topojson/ne_50m_populated_places.json`}
                    key="cities">
                    {({ geographies }) => {
                        return geographies.map((geo) => {
                            const [longitude, latitude] = geo.geometry.coordinates
                            const cityName = geo.properties.NAME ||
                                geo.properties.CITY || "Unknown City"
                            return (
                                <Marker key={geo.rsmKey} coordinates={[longitude, latitude]}>
                                    <g>
                                        <title>{cityName}</title>
                                        <circle
                                            fill="black"
                                            stroke="none"
                                            r={0.5} // Tiny black dot
                                        />
                                    </g>
                                </Marker>
                            )
                        })
                    }}
                </Geographies>


                {/* our shows */}
                {data.map(({ name, times }) => {
                    const geo = geos[name] || {'lon': 0, 'lat': 0}
                    //console.debug("Adding marker", name)
                    return (
                        <Marker key={name} coordinates={[geo.lon, geo.lat]}>
                            <g>
                                <title>{name} - {times}</title>
                                <circle fill="#F53" stroke="#FFF"
                                    r={popScale(times)} />
                            </g>
                        </Marker>
                    )
                })}
            </ZoomableGroup>
        </ComposableMap>
    </>
}

export default TourMap
