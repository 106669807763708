import React, { useCallback, useState, useEffect } from 'react'
import RUG from 'react-upload-gallery'
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "react-upload-gallery/dist/style.css";

import Loading from '../Loading.jsx'

const ShowReviewSubmit = ({
    showId, userInfo, reviews, getReviews, setResultsText,
}) => {
    const [loaded, setLoaded] = useState(false)
    // not the review ID from the parent that says which one we're viewing
    const [reviewId, setReviewId] = useState()
    const [reviewText, setReviewText] = useState('')

    const { executeRecaptcha } = useGoogleReCaptcha()

    const MIN_LENGTH = 1000
    console.info('Rendering ShowReviewSubmit, showId:', showId)

    const displaySubmitReviewError = useCallback(err => {
        setResultsText(
            `There was an error saving your review${err ? `: (${err})`: ''}. Please try again, or report this to us if it persists.`
        )
    }, [setResultsText])

    const displaySubmitReviewSuccess = useCallback(err => {
        setResultsText(<>
            <p><strong>Success!</strong></p>
            <p>Your review has been successfully saved!</p>

            <p>If you have pictures you&apos;d like to submit, you may do so on the
                right. All photos will need to be approved before they will
                appear in your review.</p>
        </>
        )
    }, [setResultsText])

    const handleReviewChange = useCallback(e => {
        setReviewText(e.target.value)
    }, [setReviewText])

    const handleImageDelete = useCallback((current, images) => {
        const id = current.source.split('id=')[1]

        const payload = {
            id: id
        }

        fetch('/cgi-bin/tour_review_image_delete.pl', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setResultsText("Failed to delete photo")
                    return false
                }
            })
            .catch(err => {
                setResultsText('Failed to delete photo')
                console.error(`Failed to get data`, err)
                return false
            })
        return true
    }, [setResultsText])

    const submitReview = useCallback(async (e) => {
        console.debug("Submitting review...")
        e.preventDefault()

        // fire off the captcha
        if (!executeRecaptcha) {
            console.error("Recaptcha not ready")
            displaySubmitReviewError("Recaptcha not ready")
            return
        }

        try {
            const recaptchaToken = await executeRecaptcha("submitReview")

            const payload = {
                review: document.getElementById('review').value,
                show_id: parseInt(showId, 10),
                recaptcha_token: recaptchaToken
            }

            if (reviewId) {
                payload.review_id = parseInt(reviewId, 10)
            }


            fetch('/cgi-bin/tour_review_submit.pl', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) {
                        displaySubmitReviewError(response.error_message)
                        console.log('save failed', response.error)
                    } else {
                        setReviewId(response.results.review_id)
                        getReviews()
                        displaySubmitReviewSuccess()
                        console.log("save successful,", response.results.review_id)
                    }
                })
                .catch(err => {
                    displaySubmitReviewError()
                    console.log('save failed', err)
                })
        } catch (err) {
            console.error("Recaptcha verification failed:", err)
            displaySubmitReviewError("Recaptcha verification failed")
        }
    }, [
        displaySubmitReviewError,
        displaySubmitReviewSuccess,
        executeRecaptcha,
        getReviews,
        reviewId,
        setReviewId,
        showId,
    ])

    /*
     * If one of these reviews is ours, set reviewId to that
     * and then set loaded
     */
    useEffect(() => {
        if (userInfo.logged_in !== 1) {
            return
        }
        setLoaded(false)
        Object.values(reviews).forEach(review => {
            if (review.member_id === userInfo.id) {
                setReviewId(review.review_id)
                setReviewText(review.body)
            }
        })
        setLoaded(true)
    }, [setLoaded, reviews, userInfo.id, userInfo.logged_in])

    if (userInfo.logged_in !== 1) {
        return <>
            You must be logged in to submit concert reviews.
        </>
    }

    if (!loaded) {
        return <Loading />
    }

    let initialRugState = []
    if (reviewId) {
        /*
         * after we submit a review, we have to wait until the getReviews()
         * call actually re-populates reviews, otherwise this will crash.
         */
        if (!(reviewId in reviews)) {
            return <Loading />
        }
        initialRugState = reviews[reviewId].images.map(id => ({
                'source': `/cgi-bin/tour_review_image.pl?id=${id}`
            }))
    }

    return <>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    width: "70%",
                    margin: "0 auto",
                    flexDirection: "row",
                    alignItems: "flex-start",
                }}
            >
                {/* Left: Textarea and Submit Button */}
                <div style={{ flex: "1" }}>
                    <p><strong>{reviewId ? 'Modify' : 'Submit'} your review here!</strong></p>
                    <form onSubmit={submitReview}>
                        <textarea
                            disabled={!loaded}
                            onChange={handleReviewChange}
                            name="review"
                            id="review"
                            style={{
                                width: "100%",
                                height: "200px",
                            }}
                            value={reviewText}
                        />
                        <div>
                            {reviewText.length < MIN_LENGTH ? (
                                <span style={{color: 'red'}}>Keep going! Your review must be at least {MIN_LENGTH} characters, almost there!<br/></span>) : ''}Current length: {reviewText.length}
                        </div>
                        <input
                            disabled={reviewText.length < MIN_LENGTH}
                            type="submit" value="Submit review"
                        />
                    </form>
                </div>

                {/* Right: RUG */}
                <div>
                    <p><strong>Submit your photos here!</strong></p>
                    <div
                        className={`rug-container ${reviewId ? '' : 'disabled'}`}
                        style={{
                            position: "relative",
                            pointerEvents: reviewId ? "auto" : "none",
                            opacity: reviewId ? 1 : 0.5,
                            flex: "1",
                        }}
                    >
                        <RUG
                            sorting={false}
                            action="/cgi-bin/tour_review_image_submit.pl"
                            send={{
                                review_id: reviewId,
                            }}
                            accept={['jpg', 'jpeg', 'png']}
                            rules={{
                                limit: 100,
                                size: 10 * 1024, // 10MB
                            }}
                            onChange={() => {getReviews()}}
                            onConfirmDelete={handleImageDelete}
                            initialState={initialRugState}
                        />
                        {!reviewId && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <p
                                    style={{
                                        color: "#FFFFFF",
                                        fontSize: "1.5rem",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    }}
                                >
                                    You need to submit a review before you can add photos to it.
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
       </>
}

export default ShowReviewSubmit
