import React from 'react'

import GuideLink from '../GuideLink.jsx'

const WrappingGuide = ({itemType, updateGuide}) => {
    return (
    <>
<h2>Wrapping Walkthru</h2>

<p>
As we mentioned in our <GuideLink guide='trading' {...{updateGuide}}>Trading Guide</GuideLink>, we recommend using <a href="https://www.amazon.com/Acko-Envelopes-Gummed-Garden-2-25x3-5/dp/B01LCLTS1M/ref=sr_1_4?crid=YX6PDMGUZ9Q9&keywords=small+paper+envelopes+for+coins&qid=1563751456&s=gateway&sprefix=small+paper+envelopes%2Caps%2C200&sr=8-4" target="_blank" rel="noreferrer">coin envelopes</a> or <a href="https://www.amazon.com/Plymor-Heavy-Plastic-Reclosable-Zipper/dp/B003ZZY8F0/ref=sr_1_3?keywords=small+ziploc+bags&qid=1563751611&s=gateway&sr=8-3" target="_blank" rel="noreferrer">mini ziplock bags</a> for inner packaging. However, if you want a manual solution, this is our suggested alternative.</p>

<p>
Start with a piece of printer paper...</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163049.jpg" alt="step 1" /></p>

<p>
Fold over a section that&lsquo;s a bit wider than the pick (about 1.25&quot; works).</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163113.jpg" alt="step 2" /></p>

<p>
Then fold it the other way, again a bit longer than the pick (about 1.5&quot; works).</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163140.jpg" alt="step 3" /></p>

<p>
Fold in the original direction once more, the same width so you have two folds like this.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163311.jpg" alt="step 4" /></p>

<p>
Now cut down the second long fold.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163334.jpg" alt="step 5" /></p>

<p>
And then cut about 5&quot; down so you have a separated piece</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163344.jpg" alt="step 6" /></p>

<p>
Now place the pick in the &quot;square&quot; at the top and fold over along the fold.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163352.jpg" alt="step 7" /></p>

<p>
And then fold down along the fold.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/IMG_20190721_163410.jpg" alt="step 8" /></p>

<p>
Now fold down along the other fold.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163432.jpg" alt="step 9" /></p>
<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163435.jpg" alt="step 9" /></p>

<p>
Leaving a little extra tab below, cut the excess off.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163450.jpg" alt="step 10" /></p>
<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163459.jpg" alt="step 10" /></p>

<p>
Now push the pick as far into the corner as it will go and fold the two open ends over so that the pick can not get out and when you put tape in this the pick can&lsquo;t possibly touch the tape.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163533.jpg" alt="step 11" /></p>

<p>
Place tape in both directions.</p>

<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163547.jpg" alt="step 12" /></p>
<p className="center">
<img src="/images/pickinfo/wrapping_walkthru/MVIMG_20190721_163601.jpg" alt="step 12" /></p>

<p>
And that&lsquo;s it. You can pack up 2-3 picks at a time this way (though for more than one you will need to leave extra room to still have tabs to fold over).</p>
    </>
    )
}

export default WrappingGuide
