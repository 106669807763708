import React, { useState, useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import Error from '../Error.jsx'
import Loading from '../Loading.jsx'

import ProfileBadges from './ProfileBadges'
import ProfileLinks from './ProfileLinks'
import ProfilePreamble from './ProfilePreamble'
import * as c from './profileConstants'

const ProfilePage = ({userInfo}) => {
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const [profileData, setProfileData] = useState()
    const [loaded, setLoaded] = useState(false)
    const [id, setId] = useState(urlParams.get('id') || c.SELF)
    const [error, setError] = useState()
    console.info("Rendering ProfilePage, id:", id)

    const getProfileData = useCallback(() => {
        setLoaded(false)
        let payload = {}
        if (id !== c.SELF) {
            payload['id'] = id
        }
        fetch('/cgi-bin/profile_values.pl', {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(i => {
                if (i.error) {
                    setError(i.error_message)
                    return
                }
                // as of yet all keys are one word so no translation
                // of snake case to camel case
                setProfileData(i.results)
                setLoaded(true)
            })
            .catch(err => {
                setError("Failed to get profile data")
                console.error("got error", err)
            })
    }, [id])

    useEffect(() => {
            getProfileData()
        },
        // we really want it to run when id changes, but getProfileData
        // will change when that happens, which will cause this to run
        [getProfileData],
    )

    useEffect(() => {
        const action = location.state?.action || 'POP'
        console.debug(
            action !== 'POP'
                ? `HistoryListener: Ignoring nav not buttons used, location: ${JSON.stringify(location)}, action: ${action}`
                : `HistoryListener: Processing nav buttons used, location: ${JSON.stringify(location)}, action: ${action}`
        )

        if (action !== 'POP') return

        const urlParams = new URLSearchParams(location.search)
        const urlId = urlParams.get('id')
        if (id && urlId && id !== urlId) {
            setLoaded(false)
            setId(urlId)
        }
    }, [location, id, setId, getProfileData])

    if (!loaded) {
        return <Loading />
    }

    if (userInfo.logged_in !== 1 && id === c.SELF) {
        return <>You must be logged in to view your profile</>
    }

    if (error) {
        return <>
            <h1>Profile</h1>
            <Error {...{error}} />
        </>
    }

    return <>
        <h1>{id === c.SELF ? '' : `${profileData.display_name}'s `}Profile</h1>
        <div style={{float: 'right', marginLeft: '5px', marginTop: '0px'}}>
            <ProfileBadges {...{userInfo, profileData}} />
        </div>
        <div>
            <ProfilePreamble {...{userInfo, id, profileData, setId}} />
            <ProfileLinks {...{userInfo, id, profileData}} />
        </div>
    </>
}

export default ProfilePage
