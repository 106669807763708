import React from 'react'

import SimpleItemImage from '../SimpleItemImage.jsx'

import './Guides.css'

const XXXGuide = ({itemType, updateGuide}) => {

    return (
    <>
<h2>XXX Pick Breakdown</h2>

<p>
There&lsquo;s many versions of Metallica&lsquo;s &quot;XXX&quot; picks out there, and collecting them was hard because there was no clear way to refer to the various XXX verions. We spent a ton of time comparing picks and this is the definitive guide to what there is and how to tell them apart.</p>

<p>
There are only two styles of &quot;XXX&quot; logo. However, they are flipped upside down for some variations.</p>

<p>
We call the two variations &quot;notch&quot; and &quot;tail.&quot; This is what we refer to as notch (click to zoom).</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/xxx/show_notch.jpg" thumb="/images/pickinfo/xxx/show_notch--thumb.jpg" /> &nbsp;
<SimpleItemImage full="/images/pickinfo/xxx/show_notch_ud.jpg" thumb="/images/pickinfo/xxx/show_notch_ud--thumb.jpg" /></p>

<p>
The <strong>red circle</strong> shows the thing I&lsquo;m referring to as the &quot;notch.&quot; The green circles are there to help you see that the two designs are the same - the second pick is the same as the first, but rotated 180 degrees.</p>

<p>
Now let&lsquo;s look at what we call &quot;tail&quot; (click to zoom).</p>

<p className="center">
<SimpleItemImage full="/images/pickinfo/xxx/show_tail.jpg" thumb="/images/pickinfo/xxx/show_tail--thumb.jpg" /> &nbsp;
<SimpleItemImage full="/images/pickinfo/xxx/show_tail_ud.jpg" thumb="/images/pickinfo/xxx/show_tail_ud--thumb.jpg" /></p>

<p>
Again, the <strong>red circle</strong> is what we call the &quot;tail&quot; and the green/blue circles are just there to help you see that the second design is the same as the first one, but upside down.</p>

<p>
Again use the circled points to notice that the logo has just been rotated 180 degrees. With all that said, what are the versions of XXX picks out there? The following table lists them all. We made this by looking at a combination of what <a href="http://metacik.edguy-sk-cz.com/picks.php?cid=7" target="_blank" rel="noreferrer">Peter</a> has, what we have, and what <a href="http://mmtradingsite.webs.com/guitarbasspicks.htm" target="_blank" rel="noreferrer">Metal Militia</a> has. Click any picture to zoom.</p>


<table className="guide">
<tr>
  <th></th>
  <th>Load Guitar</th>
  <th>Load Bass</th>
  <th>Barb Guitar</th>
  <th>Barb Bass</th>
</tr>
<tr>
  <th>Notch Down</th>
  <td><SimpleItemImage full="/images/picks/22.png" thumb="/images/picks/22--thumb.png" /></td>
  <td><SimpleItemImage full="/images/picks/23.png" thumb="/images/picks/23--thumb.png" /></td>
  <td className="na">Does not exist.</td>
  <td className="na">Does not exist.</td>
</tr>
<tr>
  <th>Notch Up</th>
  <td>
    <SimpleItemImage full="/images/pickinfo/xxx/metallica_load_logo_xxx_green_guitar_notch_up-MM.png" thumb="/images/pickinfo/xxx/metallica_load_logo_xxx_green_guitar_notch_up-MM--thumb.png" />
  </td>
  <td><SimpleItemImage full="/images/picks/25.png" thumb="/images/picks/25--thumb.png" /></td>
  <td className="na">Does not exist.</td>
  <td className="na">Does not exist.</td>
</tr>
<tr>
  <th>Tail Up</th>
  <td>
    <SimpleItemImage full="/images/pickinfo/xxx/tail_up_guitar_load_front.jpg" thumb="/images/pickinfo/xxx/tail_up_guitar_load_front--thumb.jpg" /><SimpleItemImage full="/images/pickinfo/xxx/tail_up_guitar_load_xxx.jpg" thumb="/images/pickinfo/xxx/tail_up_guitar_load_xxx--thumb.jpg" />
  </td>
  <td><SimpleItemImage full="/images/picks/27.png" thumb="/images/picks/27--thumb.png" /></td>
  <td><SimpleItemImage full="/images/picks/30.png" thumb="/images/picks/30--thumb.png" /></td>
  <td><SimpleItemImage full="/images/picks/31.png" thumb="/images/picks/31--thumb.png" /></td>
</tr>
<tr>
  <th>Tail Down</th>
  <td><SimpleItemImage full="/images/picks/28.png" thumb="/images/picks/28--thumb.png" /></td>
  <td>
    <SimpleItemImage full="/images/pickinfo/xxx/tail_down_bass_load_front.jpg" thumb="/images/pickinfo/xxx/tail_down_bass_load_front--thumb.jpg" /><SimpleItemImage full="/images/pickinfo/xxx/tail_down_bass_load_xxx.jpg" thumb="/images/pickinfo/xxx/tail_down_bass_load_xxx--thumb.jpg" />
  </td>
  <td><SimpleItemImage full="/images/picks/32.png" thumb="/images/picks/32--thumb.png" /></td>
  <td><SimpleItemImage full="/images/picks/33.png" thumb="/images/picks/33--thumb.png" /></td>
</tr>
</table>
    </>
    )
}

export default XXXGuide
