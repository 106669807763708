import React, { useState, useCallback, useEffect } from 'react'

import * as ipom from '../common.jsx'
import Loading from '../Loading.jsx'
import Error from '../Error.jsx'

import TradeCenterTable from './TradeCenterTable.jsx'

/*
 * A wrapper around TradeCenterTable to gather the data
 */
const TradeCenter = ({
    itemType, userInfo, multiPageType, loaded, setLoaded, pageType,
    updatePageType, updateView, setOtherId, id2, setId2, switchToList,
    switchToSuggest,
}) => {
    const [error, setError] = useState(false)
    const [memberInfo, setMemberInfo] = useState()

    console.info("Rendering TradeCenter")

    const getTradesList = useCallback(({itemType, userInfo}) => {
        console.debug('getTradesList: entered')
        if (!userInfo) {
            console.debug("bailing out, no user info yet")
            return
        }
        let url = '/cgi-bin/collections_tradecenter_values.pl'
        let url_args = `item_type=${itemType}`
        fetch(`${ipom.API_ENDPOINT}${url}?${url_args}`)
            .then(response => response.json())
            .then(i => {
                if (i['error']) {
                    setError(i['error_message'])
                    return
                }
                setMemberInfo(i['results']['member_info'])
                setLoaded(true)
            })
            .catch(err => console.error(`Failed to get ${itemType} trades:`, err))
    }, [setLoaded])

    useEffect(() => {
        console.debug("Running effect to get trade info")
        getTradesList({...{itemType, userInfo}})
    }, [itemType, userInfo, pageType, getTradesList])

    let inner = <Loading />
    if (error) {
        inner = <Error {...{error}} />
    } else if (loaded) {
        inner = <TradeCenterTable
            {...{itemType, userInfo, memberInfo, updatePageType, updateView,
                setOtherId, setId2, switchToList, switchToSuggest}} />
    }

    return (
        <div>
            <p>Welcome to the IPOM Pick Trading Center! If you&lsquo;re a trader, this is the place for you! Below is a list of all IPOM members who trade picks and how many possible trades you have with them. Click the number of possible trades to get a list, or click their handle to see their full collection!</p>

            <p>We want to make it as easy as possible for people to trade picks. In that spirit we&lsquo;ve put together this Trade Center! But don&lsquo;t forget to check our <a href={`/cgi-bin/${itemType}s_index.pl?form=guides`}>Guides</a> to ensure you get a fair trade!</p>
            {inner}
        </div>
    )
}

export default TradeCenter
