import React from 'react'

import * as utils from './tourUtils.jsx'
import * as c from './tourConstants.jsx'

const baseurl = `/app/tour?pagetype=${c.PageType.SEARCH}`

const TourSearchLink = ({searchParams, updatePageType, ...props}) => {
    return <a
        href={`${baseurl}&data=encoded:${utils.hashToSearchUrlStr(searchParams)}`}
        onClick={e => updatePageType(e, c.PageType.SEARCH, searchParams)}
    >{props.children}</a>
}

export default TourSearchLink
